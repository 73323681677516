import React from "react";
import { Spinner } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { getApiUrl } from "../../function/common";

import Serviceterms from '../UserMain/components/ServiceTerms';
import Userterms from '../UserMain/components/Userterms';
import Userterms2 from '../UserMain/components/Userterms2';
import ConsignmentTerms from '../UserMain/components/ConsignmentTerms';

import "../NotFound.css";

export default function Demologin(){

    const { name } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


    useEffect (() => {
        localStorage.setItem('oauthType','KAKAO');
        // localStorage.setItem('accessToken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VySWQiLCJpc0FjY2Vzc1Rva2VuIjp0cnVlLCJpc3MiOiJpdm91Y2hlci5rciIsImlkIjoiMjA1NCIsInVzZXJUeXBlIjoiVVNFUiIsImV4cCI6MTY5Mzk2ODk0OCwiaWF0IjoxNjc4NDE2OTQ4fQ.GSrfITfbxKswM3gUyYujMQIg776uX-I2ufa0KfojQ5s');
        localStorage.setItem('accessToken', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VySWQiLCJpc0FjY2Vzc1Rva2VuIjp0cnVlLCJpc3MiOiJpdm91Y2hlci5rciIsImlkIjoiNDIiLCJ1c2VyVHlwZSI6IlVTRVIiLCJleHAiOjE2OTY5OTIyMzQsImlhdCI6MTY4MTQ0MDIzNH0.Em6txtDrnFfQOHSdYA63J7j37MfLxe4UjeGMsVmhAK0');
        localStorage.setItem('defaultStore', 'inje');
        // inje, paik, none

        setLoading(false);
    }, []);

    // signup();

    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

    return (
        <>
        <div className="demo">
            <div className="T404Page">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">심사용 데모 로그인</p>
                    </nav>

                    <div className="FailureBox Inner">
                        <div className="FailureBox-title my-5 text-center">
                            <img className="img-fluid mb-4"
                                src="https://www.flexdaycdn.net/public/images/static/store-register/cry-emoji.png" alt="" />
                            <p className="PageTit2 mb-4">
                                앱 심사용 페이지 입니다.
                            </p>
                            <p className="PageSub">
                                데모 테스트를 할 수 있는 토큰만 제공됩니다.
                                로그인 화면 이후 메인 페이지의 구성입니다.
                            </p>
                        </div>
                    </div>

                    <div className="fixed-bottom px-4-5 pt-3 pb-4">
                        <div className="CancelConfirm d-flex justify-content-between flex-wrap">
                            <p className="PageSub text-center mb-4 w-100">
                                아래 버튼을 눌러 데모 테스트를 시작해주세요.
                            </p>
                            <button onClick={()=>{navigate('/storemain')}} className="btn btn-default">
                                메인페이지로 가기
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        </>
    )
}