import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Header from "./components/Header";
import "./OrderStatus.css";
import axios from "axios";
import { AlertError, getApiUrl } from "../../function/common";

export default function OrderStatus() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { number } = useParams();

  const [orderStatus, setOrderStatus] = useState();
  function getOrderStatus() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        smartOrderId: number,
      };

      const response = await axios
        .get(
          getApiUrl("v1/coupons-sorder/" + number),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setOrderStatus(response.data);
            // console.log(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  const orderSuss = async (e) => {
    let obj = {
      smartOrderId: number,
    };
    await axios
      .post(
        getApiUrl("v1/coupons-sorder/" + number),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          window.location.reload(false);
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TOrderStatusPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">주문 현황</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            {/* 주문 상태 4단계 */}
            <div
              className={
                orderStatus && orderStatus.smartOrderStatus.code === 5
                  ? "OrderStatusBox px-4-5 d-none"
                  : "OrderStatusBox px-4-5"
              }
            >
              <div className="title_box text-center">
                <img
                  className="img-fluid mb-4"
                  src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_smile.png"
                  alt=""
                />
                <p className="tit">주문이 접수되었습니다</p>
                <p className="sub">
                  주문번호 : {orderStatus && orderStatus.orderNumber}
                </p>
              </div>

              <div className="status_box">
                <p className="sub_tit">주문 현황</p>
                {/* 상태별 class name
                                    state1 : 수락대기
                                    state2 : 조리중
                                    state3 : 픽업요청
                                    state4 : 픽업완료
                                    cancel : 주문 취소 / 페이지 별도
                                */}

                {orderStatus && orderStatus.smartOrderStatus.code === 1 ? (
                  <div className="status_list state1">
                    <div className="status_items">
                      <div className="status_item step1 ">
                        <div className="status_circle"></div>
                        <div className="status_tit">수락 대기</div>
                        <div className="status_desc">
                          주방에서 주문을 확인 중입니다
                        </div>
                      </div>
                      <div className="status_item step2">
                        <div className="status_circle"></div>
                        <div className="status_tit">조리중</div>
                        <div className="status_desc">
                          주방에서 주문을 조리 중입니다
                        </div>
                      </div>
                      <div className="status_item step3">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 요청</div>
                        <div className="status_desc">
                          메뉴 준비가 완료되었습니다 <br />
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          메뉴를 찾아가세요
                        </div>
                      </div>
                      <div className="status_item step4">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 완료</div>
                        <div className="status_desc">
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          픽업을 완료하셨습니다 <br />
                          맛있게 드세요!
                        </div>
                      </div>
                    </div>
                    <div className="pickup_btn">
                      <button type="type" className="btn btn-light btn-pickup">
                        픽업 완료
                      </button>
                      <div className="txt">
                        메뉴 픽업을 완료하셨다면 픽업 완료 버튼을 눌러주세요!
                      </div>
                    </div>
                  </div>
                ) : orderStatus && orderStatus.smartOrderStatus.code === 2 ? (
                  <div className="status_list state2">
                    <div className="status_items">
                      <div className="status_item step1 ">
                        <div className="status_circle"></div>
                        <div className="status_tit">수락 대기</div>
                        <div className="status_desc">
                          주방에서 주문을 확인 중입니다
                        </div>
                      </div>
                      <div className="status_item step2">
                        <div className="status_circle"></div>
                        <div className="status_tit">조리중</div>
                        <div className="status_desc">
                          주방에서 주문을 조리 중입니다
                        </div>
                      </div>
                      <div className="status_item step3">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 요청</div>
                        <div className="status_desc">
                          메뉴 준비가 완료되었습니다 <br />
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          메뉴를 찾아가세요
                        </div>
                      </div>
                      <div className="status_item step4">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 완료</div>
                        <div className="status_desc">
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          픽업을 완료하셨습니다 <br />
                          맛있게 드세요!
                        </div>
                      </div>
                    </div>
                    <div className="pickup_btn">
                      <button type="type" className="btn btn-light btn-pickup">
                        픽업 완료
                      </button>
                      <div className="txt">
                        메뉴 픽업을 완료하셨다면 픽업 완료 버튼을 눌러주세요!
                      </div>
                    </div>
                  </div>
                ) : orderStatus && orderStatus.smartOrderStatus.code === 3 ? (
                  <div className="status_list state3">
                    <div className="status_items">
                      <div className="status_item step1 ">
                        <div className="status_circle"></div>
                        <div className="status_tit">수락 대기</div>
                        <div className="status_desc">
                          주방에서 주문을 확인 중입니다
                        </div>
                      </div>
                      <div className="status_item step2">
                        <div className="status_circle"></div>
                        <div className="status_tit">조리중</div>
                        <div className="status_desc">
                          주방에서 주문을 조리 중입니다
                        </div>
                      </div>
                      <div className="status_item step3">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 요청</div>
                        <div className="status_desc">
                          메뉴 준비가 완료되었습니다 <br />
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          메뉴를 찾아가세요
                        </div>
                      </div>
                      <div className="status_item step4">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 완료</div>
                        <div className="status_desc">
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          픽업을 완료하셨습니다 <br />
                          맛있게 드세요!
                        </div>
                      </div>
                    </div>
                    <div className="pickup_btn">
                      <button
                        type="type"
                        className="btn btn-light btn-pickup"
                        onClick={() => orderSuss()}
                      >
                        픽업 완료
                      </button>
                      <div className="txt">
                        메뉴 픽업을 완료하셨다면 픽업 완료 버튼을 눌러주세요!
                      </div>
                    </div>
                  </div>
                ) : orderStatus && orderStatus.smartOrderStatus.code === 4 ? (
                  <div className="status_list state4">
                    <div className="status_items">
                      <div className="status_item step1 ">
                        <div className="status_circle"></div>
                        <div className="status_tit">수락 대기</div>
                        <div className="status_desc">
                          주방에서 주문을 확인 중입니다
                        </div>
                      </div>
                      <div className="status_item step2">
                        <div className="status_circle"></div>
                        <div className="status_tit">조리중</div>
                        <div className="status_desc">
                          주방에서 주문을 조리 중입니다
                        </div>
                      </div>
                      <div className="status_item step3">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 요청</div>
                        <div className="status_desc">
                          메뉴 준비가 완료되었습니다 <br />
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          메뉴를 찾아가세요
                        </div>
                      </div>
                      <div className="status_item step4">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 완료</div>
                        <div className="status_desc">
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          픽업을 완료하셨습니다 <br />
                          맛있게 드세요!
                        </div>
                      </div>
                    </div>
                    <div className="pickup_btn">
                      <button type="type" className="btn btn-light btn-pickup">
                        픽업 완료
                      </button>
                      <div className="txt">
                        메뉴 픽업을 완료하셨다면 픽업 완료 버튼을 눌러주세요!
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="status_list cancel">
                    <div className="status_items">
                      <div className="status_item step1 ">
                        <div className="status_circle"></div>
                        <div className="status_tit">수락 대기</div>
                        <div className="status_desc">
                          주방에서 주문을 확인 중입니다
                        </div>
                      </div>
                      <div className="status_item step2">
                        <div className="status_circle"></div>
                        <div className="status_tit">조리중</div>
                        <div className="status_desc">
                          주방에서 주문을 조리 중입니다
                        </div>
                      </div>
                      <div className="status_item step3">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 요청</div>
                        <div className="status_desc">
                          메뉴 준비가 완료되었습니다 <br />
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          메뉴를 찾아가세요
                        </div>
                      </div>
                      <div className="status_item step4">
                        <div className="status_circle"></div>
                        <div className="status_tit">픽업 완료</div>
                        <div className="status_desc">
                          {orderStatus && orderStatus.diningTable} 배식구에서
                          픽업을 완료하셨습니다 <br />
                          맛있게 드세요!
                        </div>
                      </div>
                    </div>
                    <div className="pickup_btn">
                      <button type="type" className="btn btn-light btn-pickup">
                        픽업 완료
                      </button>
                      <div className="txt">
                        메뉴 픽업을 완료하셨다면 픽업 완료 버튼을 눌러주세요!
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="my_order">
                <div className="sub_tit">내 주문</div>
                <div className="order_list">
                  <div className="order_item">
                    <p className="prd_name">
                      {orderStatus && orderStatus.productName} / 1개
                    </p>
                    <p className="store_name mb-0">
                      {orderStatus && orderStatus.storeName}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* 주문 취소 (임시 숨김처리)*/}
            <div
              className={
                orderStatus && orderStatus.smartOrderStatus.code === 5
                  ? "OrderStatusBox px-4-5"
                  : "OrderStatusBox px-4-5 d-none"
              }
            >
              <div className="title_box text-center">
                <img
                  className="img-fluid mb-4"
                  src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_sad.png"
                  alt=""
                />
                <p className="tit">주문이 취소되었습니다</p>
                <p className="sub">
                  주문번호 : {orderStatus && orderStatus.orderNumber}
                </p>
              </div>

              <div className="status_box">
                <p className="sub_tit">주문 현황</p>
                {/* 
                                    상태별 class name 
                                    cancel : 주문 취소 / 페이지 별도
                                */}
                <div className="status_list cancel">
                  <div className="status_items">
                    <div className="status_item step1">
                      <div className="status_circle"></div>
                      <div className="status_tit">수락 대기</div>
                      <div className="status_desc">
                        주방에서 주문을 확인 중입니다
                      </div>
                    </div>
                    <div className="status_item step5">
                      <div className="status_circle"></div>
                      <div className="status_tit">주문 취소</div>
                      <div className="status_desc">
                        주방에서 주문을 취소하였습니다 <br />
                        취소 사유 : {orderStatus && orderStatus.cancelReason}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my_order">
                <div className="sub_tit">내 주문</div>
                <div className="order_list">
                  <div className="order_item">
                    <p className="prd_name">
                      {orderStatus && orderStatus.productName} / 1개
                    </p>
                    <p className="store_name mb-0">
                      {orderStatus && orderStatus.storeName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
