import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import { openQRRead } from '../../../api/ApiWebView';

export default function AdminHeader( props ) {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const activeClass = (route) => { return window.location.pathname === route ? "active nav-link d-flex align-items-center pb-4" : "nav-link d-flex align-items-center pb-4" }

  return (
    <>
      <button className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4" type="button" onClick={handleShow}>
        <img className="img-fluid" alt="메뉴" src="https://www.flexdaycdn.net/public/images/ticket/menu3.png" />
      </button>
                            
      <Offcanvas className="demo" id="offcanvasNavbar" show={show} onHide={handleClose} placement={'end'}>
        <div className="offcanvas-btnbox pt-4 px-4 text-end">
          <button type="button" className="btn-close opacity-100 shadow-none" onClick={handleClose}>
            <img className="img-fluid close" alt="" src="https://www.flexdaycdn.net/public/images/ticket/close4.png" />
          </button>
        </div>
        <Offcanvas.Header className="p-4">
          <img className="img-fluid logo" alt="" src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
          <Offcanvas.Title id="offcanvasNavbarLabel">
            인제대학교 늘빛관(다인)
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="position-relative py-4-5">
          <div className="navbar-nav justify-content-end">
            <div className="nav-item">
              <Link to={"/adminmain"} className={activeClass("/adminmain")}>
                <span>관리자 메인</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link to={"/basicinfo"} className={activeClass("/basicinfo")}>
                <span>기본 정보 관리</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link to={"/saleprd"}  className={activeClass("/saleprd")}>
                <span>판매 메뉴 관리</span>
              </Link>
            </div>
            <div className="nav-item">
              <Link to={"/storecalculate"}  className={activeClass("/storecalculate")}>
                <span>결제/매출/정산</span>
              </Link>
            </div>                                       
            <div className="nav-item">
              <Link to={"/surveyresult"}  className={activeClass("/surveyresult")}>
                <span>설문조사 집계표</span>
              </Link>
            </div>                                       
            <div className="nav-item">
              <Link to={"/eventmanagement"}  className={activeClass("/eventmanagement")}>
                <span>이벤트 관리</span>
              </Link>
            </div>                                       
            <div className="nav-item last">
              <Link to="#/" className={activeClass("/qrpay")} onClick={()=>{openQRRead()}}>
                <span>QR 결제</span>
              </Link>
            </div>                                       
           </div>
          <div className="btnbox">
            <button type="button" className="btn btn-default" onClick={ ()=> { localStorage.clear(); navigate("/login") }}>로그아웃</button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
