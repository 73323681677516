
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';


import "./PaymentCancel.css";

export default function PaymentCancel(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);


    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        // try{
        //     setNav3();
        // }catch(e){}
        setLoading(false);
    },[])

    if (loading) return <></>

  
    
    return (
        <>
        <div className='demo'>

             <div className="TPaymentCancelPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제 취소</p>
                        <button type="button" className="btn-clear btn-close" onClick={()=>{navigate('/paymentlist')}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                        </button>
                    </nav>

                    <div className="CancelBox Inner">
                        <div className="CancelBox-title my-5 text-center">
                            <img className="img-fluid mb-4" src="https://www.flexdaycdn.net/public/images/ticket/check_circle.png"
                                alt="" />
                            <p className="PageTit2 mb-5">
                                결제 취소가 <br />
                                완료되었습니다.
                            </p>
                            <p className="PageSub">
                                취소일로부터 영업일 기준 7일 이내에 <br />
                                결제수단으로 승인 취소 확인이 가능하며 <br />
                                카드 결제일에 따라 카드사를 통해 환불됩니다.
                            </p>
                        </div>

                    </div>


                    <div className="fixed-bottom px-4-5 pt-3 pb-4">
                        <p className="PageSub2 text-center mb-3">
                            더 궁금하신 사항은 고객센터로 문의주세요.
                        </p>
                        <div className="CancelConfirm d-flex justify-content-between">
                            <Link to={"/storemain"} className="btn btn-default">
                                홈으로
                            </Link>
                            <a href="tel:0516210203" className="btn btn-light">
                                고객센터 연결
                            </a>
                        </div>
                    </div>


                </div>
            </div>

        </div>

        </>
    )
}