export default function RewordStamp( props ) {
    // console.log(props.eventStampRules);

    let EventStampCount = 0;
    let EventStampRewardType = "";
    let EventReward = 20;

    for( let i = 0 ; i < props.eventStampRules.length; i++){
        if( (props.idx + 1) === props.eventStampRules[i].stampCount ){
            EventStampCount = props.eventStampRules[i].stampCount;
            EventStampRewardType = props.eventStampRules[i].rewardType;
            EventReward = props.eventStampRules[i].reward;
            // console.log(EventStampCount + ' ' + EventStampRewardType + ' ' + EventReward);
        }
    }

    if (EventStampCount !== 0 && EventStampRewardType !== "" && EventReward !== 20){
        if((props.idx + 1) <= props.countOfStamp){
            return (
                <>
                    <div className="stamp-step finish on" key={props.idx}>
                        <div className="before">
                            { props.idx + 1 }
                        </div>
                        <div className="after get">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp-finish.png" alt="" />
                        </div>
                        <div className="step-txt gMarket get">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/stamp-check2.png"
                                    alt="" />
                        { EventReward } 증정!
                        </div>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="stamp-step" key={props.idx}>
                        <div className="before">
                            { props.idx + 1 }
                        </div>
                        <div className="after">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png" alt="" />
                        </div>
                        <div className="step-txt gMarket get">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/stamp-check2.png"
                                    alt="" />
                        { EventReward } 증정!
                        </div>
                    </div>
                </>
            )
        }
        
    }else{
        if((props.idx + 1) <= props.countOfStamp){
            return (
                <>
                    <div className="stamp-step on" key={props.idx}>
                        <div className="before">
                            { props.idx + 1 }
                        </div>
                        <div className="after">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png" alt="" />
                        </div>
                        <div className="step-txt gMarket get">
                        </div>
                    </div>
                </>
            )
        }else{
            return (
                <>
                    <div className="stamp-step" key={props.idx}>
                        <div className="before">
                            { props.idx + 1 }
                        </div>
                        <div className="after">
                            <img src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png" alt="" />
                        </div>
                        <div className="step-txt gMarket get">
                        </div>
                    </div>
                </>
            )
        }
    }
}