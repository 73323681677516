import {useNavigate} from "react-router-dom";
import OrderState from "./OrderStatusCard";
import OrderStatusCard from "./OrderStatusCard";
import {useEffect} from "react";

export default function ReceivedCouponOrder({row, handleShow}) {
    const navigate = useNavigate();

    function isPassed30Min(time){
        if(!time){
            return false;
        }
        const before30min = new Date();
        before30min.setMinutes(before30min.getMinutes()-30);
        if(new Date(time)< before30min){
            return true;
        }else{
            return false;
        }
    }


    if(row.status.code === 6){
        return (
            <div className="PayListItem unuse">
                <div className="infobox">
                    <div className="InfoboxTxt">
                        <div className="txtbox">
                            <p className="prd-date">
                                from. {row.buyerName?row.buyerName:'-'}
                            </p>
                            <p className="prd-name">
                                {row.productName}
                            </p>
                            <p className="store-name">
                                {row.storeName}
                            </p>
                            <img className="img-fluid prd-stamp" alt=""
                                 src="https://www.flexdaycdn.net/public/images/ticket_ver3/QR_ticket_stamp_unuse.png" />
                        </div>
                        <div className="btnbox" onClick={()=> {navigate('/orderunuse/'+row.lastSmartOrder.smartOrderId)}}>
                            <button type="type" className="btn btn-light btn-info w-100">
                                안내사항
                            </button>
                        </div>
                        <div className="orderbox">
                            <div className="state">
                                <div className="dot"></div>
                                {row.lastSmartOrder.smartOrderStatus.description}
                            </div>
                            <div className="link">
                                <button type="type" className="btn btn-clear btn-ordercheck" onClick={()=> {navigate('/orderstatus')}}>
                                    주문현황
                                    <img className="img-fluid" alt=""
                                         src="https://www.flexdaycdn.net/public/images/ticket_ver3/order_arrow.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else if(row.lastSmartOrder === null || (row.lastSmartOrder != null &&isPassed30Min(row.lastSmartOrder.cancelledAt))){
        return(
            <div className="PayListItem">
                <div className=" infobox">
                    <div className="InfoboxTxt">
                        <div className="txtbox">
                            <p className="prd-date">
                                from. {row.buyerName?row.buyerName:'-'}
                            </p>
                            <p className="prd-name">
                                { row.productName }
                            </p>
                            <p className="store-name">
                                { row.storeName }
                            </p>
                        </div>
                        <div className="btnbox">
                            {/*<button type="type" className="btn btn-light btn-gift" onClick={()=> (navigate('/gift/'+row.qrcode))}>*/}
                            {/*    <img className="img-fluid" alt=""*/}
                            {/*        src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png" />*/}
                            {/*    선물하기*/}
                            {/*</button>*/}
                            <button type="type" className="btn btn-default btn-order w-100" onClick={() => (handleShow(row.qrcode, row.productName) )}>
                                주문하기
                            </button>
                        </div>
                        <div className="orderbox ">
                            <div className="state">
                                <div className="dot"></div>
                                {/*{row.lastSmartOrder.smartOrderStatus.description}*/}
                            </div>
                            <div className="link">
                                <button type="type" className="btn btn-clear btn-ordercheck">
                                    주문현황
                                    <img className="img-fluid" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket_ver3/order_arrow.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    } else{
        return(
            <div className="PayListItem cooking">
                <div className="infobox">
                    <div className="InfoboxTxt">
                        <div className="txtbox">
                            <p className="prd-date">
                                from. {row.buyerName?row.buyerName:'-'}
                            </p>
                            <p className="prd-name">
                                {row.productName}
                            </p>
                            <p className="store-name">
                                {row.storeName}
                            </p>
                            {/*<img className="img-fluid prd-stamp" alt=""*/}
                            {/*src="https://www.flexdaycdn.net/public/images/ticket_ver3/QR_ticket_stamp_unuse.png" />*/}
                        </div>
                        <div className="btnbox" onClick={()=> {navigate('/orderunuse/'+row.lastSmartOrder.smartOrderId)}}>
                            <button type="type" className="btn btn-light btn-info w-100">
                                안내사항
                            </button>
                        </div>
                        <div className="orderbox">
                            {/*<div className="state on">*/}
                            {/*    <div className="dot"></div>*/}
                            {/*    {row.lastSmartOrder.smartOrderStatus.description}*/}
                            {/*</div>*/}
                            <OrderStatusCard smartOrderStatus={row.lastSmartOrder.smartOrderStatus}/>
                            <div className="link">
                                <button type="type" className="btn btn-clear btn-ordercheck" onClick={()=> {navigate('/orderstatus/'+row.lastSmartOrder.smartOrderId)}}>
                                    주문현황
                                    <img className="img-fluid" alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket_ver3/order_arrow.png" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }



}