import { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";

import {
  AlertError,
  getApiUrl,
  PrintConsole,
  setCommas,
  sleep,
} from "../../function/common";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./PaymentList.css";

export default function PaymentList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // 모달
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const tmpWhere = useRef("3");
  function SetWhere(val) {
    tmpWhere.current = val;
    getPaymentList();
  }

  const [paymentList, setPaymentList] = useState();
  function getPaymentList() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        isUsed: tmpWhere.current,
      };
      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          isUsed: tmpWhere.current,
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }
      const response = await axios
        .get(
          getApiUrl("v1/payments"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setPaymentList(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          AlertError(error);
        });
    });
  }

  useEffect(() => {
    getPaymentList();
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <>
      <div className="demo">
        <div className="TPaymentList2Page">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">결제 내역</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="PaymentFilter px-4-5 pt-4-5">
              <div className="sort" onClick={handleShow}>
                <span className="filter-txt">
                  {tmpWhere.current !== "ALL"
                    ? "최근 " + tmpWhere.current + " 개월"
                    : "전체"}
                </span>
                <img
                  className="img-fluid arrow"
                  src="https://www.flexdaycdn.net/public/images/ticket/arrow-down.png"
                  alt=""
                />
              </div>
            </div>

            <div className="PrdList">
              {paymentList &&
                paymentList.data.map((row) =>
                  row.paymentStatus.code === 1 ? (
                    <div className="Listitem px-4-5 my-4">
                      <div className="align-items-center d-flex justify-content-between">
                        <img
                          className="img-fluid logo"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                        />
                        <div className="txtbox">
                          <p className="date mb-0">{row.completedAt}</p>
                          <p className="price">
                            <span className="num">
                              {setCommas(row.paymentPrice)}원
                            </span>
                          </p>
                        </div>
                        <div className="moreDatil">
                          <Link to={"/paymentdetail/" + row.orderNumber}>
                            <button
                              className="btn btn-clear w-100"
                              type="button"
                            >
                              상세내역
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                alt=""
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="Listitem cancel px-4-5 my-4">
                      {/* 확인 : 취소된 내역 / class 'cancel' 추가 */}
                      <div className="align-items-center d-flex justify-content-between">
                        <img
                          className="img-fluid logo"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                        />
                        <div className="txtbox">
                          <p className="date mb-0">{row.completedAt}</p>
                          <p className="price">
                            <span className="num">
                              {setCommas(row.paymentPrice)}원
                            </span>
                          </p>
                        </div>
                        <div className="moreDatil">
                          <Link to={"/paymentdetail/" + row.orderNumber}>
                            <button
                              className="btn btn-clear w-100"
                              type="button"
                            >
                              상세내역
                              <img
                                className="img-fluid"
                                src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                alt=""
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                )}

              {/* <div className="Listitem px-4-5 my-4">                        
                            <div className="align-items-center d-flex justify-content-between">
                                <img className="img-fluid logo" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png" />
                                <div className="txtbox">
                                    <p className="date mb-0">
                                        2022-12-28 11:43
                                    </p>
                                    <p className="price">
                                        <span className="num">4,000원</span>
                                    </p>
                                </div>
                                <div className="moreDatil">
                                    <Link to={"/qrdetail/1"}>
                                        <button className="btn btn-clear w-100" type="button">
                                        상세내역
                                        <img className="img-fluid"
                                            src="https://www.flexdaycdn.net/public/images/ticket/more_info.png" alt="" />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>

          {/* 결제내역 Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            size="sm"
            className="demoModal TPaymentList-modal"
            dialogClassName="modal-prdfilter"
          >
            <div className="modal-bar py-3">
              <div className="bar"></div>
            </div>
            <Modal.Header className="pb-4">
              <Modal.Title className="fw-bold">조회기간 선택</Modal.Title>
              <button
                type="button"
                className="btn btn-clear"
                onClick={handleClose}
              >
                <img
                  className="img-fluid"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/close.png"
                />
              </button>
            </Modal.Header>
            <Modal.Body className="pb-4">
              <div className="prd-sortbox">
                <div className="form-sort">
                  {tmpWhere.current === "3" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-1"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("3");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-1"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("3");
                      }}
                    />
                  )}
                  <label className="form-radio-label" htmlFor="survey-radio-1">
                    최근 3개월
                    <img
                      className="img-fluid check"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png"
                    />
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "6" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-2"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("6");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-2"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("6");
                      }}
                    />
                  )}
                  <label className="form-radio-label" htmlFor="survey-radio-2">
                    최근 6개월
                    <img
                      className="img-fluid check"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png"
                    />
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "9" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-3"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("9");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-3"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("9");
                      }}
                    />
                  )}
                  <label className="form-radio-label" htmlFor="survey-radio-3">
                    최근 9개월
                    <img
                      className="img-fluid check"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png"
                    />
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "ALL" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-4"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("ALL");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-4"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("ALL");
                      }}
                    />
                  )}
                  <label className="form-radio-label" htmlFor="survey-radio-4">
                    전체
                    <img
                      className="img-fluid check"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/check-icon.png"
                    />
                  </label>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
