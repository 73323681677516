import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Image, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AlertError, getApiUrl } from "../../function/common";

import axios from "axios";

import Header from "./components/Header";
import "./Survey.css";

export default function Survey() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { number } = useParams();

  // 모달
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // 모달
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [qr, setQR] = useState();
  function getQRCode() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        isUsed: true,
      };
      const response = await axios
        .get(
          getApiUrl("v1/coupons/" + number),

          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setQR(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("error");
        });
    });
  }

  const submit = async () => {
    let TmpSelect = "";
    if (document.getElementById("survey-radio-1").checked) {
      TmpSelect = document.getElementById("survey-radio-1").value;
    } else if (document.getElementById("survey-radio-2").checked) {
      TmpSelect = document.getElementById("survey-radio-2").value;
    } else if (document.getElementById("survey-radio-3").checked) {
      TmpSelect = document.getElementById("survey-radio-3").value;
    } else {
      TmpSelect = "";
      // console.log('non select');
    }

    if (TmpSelect !== "") {
      document.getElementById("SubmitBtn").setAttribute("disabled", "true");

      let obj = {
        grade: TmpSelect,
      };
      const response = await axios
        .post(
          getApiUrl("v1/coupons/" + number + "/survey"),
          obj,
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            // navigate('/qrwallet');
            handleShow2();
          }
        })
        .catch(function (error) {
          AlertError(error);
        });

      document.getElementById("SubmitBtn").removeAttribute("disabled");
    } else {
      handleShow();
    }
  };

  useEffect(() => {
    // getOrderItem();

    getQRCode();
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <>
      <div className="demo">
        <div className="TSurveyPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">만족도 조사</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="SurveyBox text-center my-4-5 pb-4-5 border-bottom">
              <p className="tit">식사는 맛있게 하셨나요?</p>
              <p className="desc mb-0">
                구내식당 개선을 위한 <br />
                <span className="fw-bold">만족도 조사</span>에 참여
                부탁드립니다. <br />
                항상 만족스러운 식사와 <br />
                서비스 제공을 위해 노력하겠습니다.
              </p>
            </div>

            <div className="SurveyBox text-center my-4-5">
              <p className="tit mb-1">
                {qr && qr.productName} <span className="small">X</span> 1개
              </p>
              <p className="desc mb-0">
                만족도에 따라 아래 버튼을 선택해주세요.
              </p>
              <div className="SurveySelect form-radio-group d-flex justify-content-center my-4-5">
                <div className="form-survey">
                  <input
                    className="form-radio-input"
                    id="survey-radio-1"
                    type="radio"
                    name="flexRadioDefault"
                    value="VERY_GOOD"
                  />
                  <label className="form-radio-label" htmlFor="survey-radio-1">
                    <img
                      className="img-fluid emoji"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/emoji_happy.png"
                    />
                    만족해요!
                  </label>
                </div>
                <div className="form-survey">
                  <input
                    className="form-radio-input"
                    id="survey-radio-2"
                    type="radio"
                    name="flexRadioDefault"
                    value="GOOD"
                  />
                  <label className="form-radio-label" htmlFor="survey-radio-2">
                    <img
                      className="img-fluid emoji"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/emoji_smile.png"
                    />
                    보통이에요!
                  </label>
                </div>
                <div className="form-survey">
                  <input
                    className="form-radio-input"
                    id="survey-radio-3"
                    type="radio"
                    name="flexRadioDefault"
                    value="BAD"
                  />
                  <label className="form-radio-label" htmlFor="survey-radio-3">
                    <img
                      className="img-fluid emoji"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/emoji_sad.png"
                    />
                    부족해요!
                  </label>
                </div>
              </div>
            </div>

            <div className="Survey-Confirm px-4-5 mb-4">
              <button
                type="button"
                id="SubmitBtn"
                className="btn btn-default"
                onClick={() => {
                  submit();
                }}
              >
                제출하기
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={show2}
          onHide={handleClose2}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>식사는 맛있게 하셨나요?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <p className="modal-title" style={{"color":"#666666"}}>
                        055-320-3054
                    </p> */}
            만족도 조사에
            <br />
            참여해주셔서 감사합니다.
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="modal-secondary" className='btn-clear' onClick={handleClose2}>
                        닫기
                    </Button>
                    <a  href="tel:0516210203" className='btn btn-clear btn-modal-primary'>
                        담당자 연결
                    </a> */}
            <button
              type="button"
              className="btn btn-clear btn-modal-primary w-100"
              data-bs-dismiss="modal"
              onClick={() => navigate("/qrwallet")}
            >
              확인
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>식사는 맛있게 하셨나요?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <p className="modal-title" style={{"color":"#666666"}}>
                        055-320-3054
                    </p> */}
            만족도 아이콘을 선택해주세요.
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="modal-secondary" className='btn-clear' onClick={handleClose2}>
                        닫기
                    </Button>
                    <a  href="tel:0516210203" className='btn btn-clear btn-modal-primary'>
                        담당자 연결
                    </a> */}
            <button
              type="button"
              className="btn btn-clear btn-modal-primary w-100"
              data-bs-dismiss="modal"
              onClick={() => handleClose()}
            >
              확인
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
