import Modal from "react-bootstrap/Modal";
import {useContext} from "react";
import {ModalContext} from "./ModalProvider";


export const CommonModal = () =>{
    const { isOpen, modalData, closeModal } = useContext(ModalContext);
    if (!isOpen) {
        return <></>;
    }

    const { title,text, onCancel } = modalData;

    const onCancelInternal = () => {
        onCancel?.();
        closeModal();
    };


    return (<>
        <Modal
            show={isOpen}
            onHide={onCancelInternal}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center custom-modal"
            backdropClassName='custom-backdrop'
        >
            <Modal.Header>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            <Modal.Footer>

                <button type="button" className="btn btn-clear btn-modal-primary w-100" data-bs-dismiss="modal" onClick={onCancelInternal}>확인</button>
            </Modal.Footer>
        </Modal>
    </>
    )

}