import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Naver from "./pages/sns/Naver";
import Kakao from "./pages/sns/Kakao";
import demoLogin from "./pages/sns/Login";

import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Apple from "./pages/sns/Apple";
import Scroll from "./pages/testcode/Scroll";

import Login from "./pages/UserMain/Login";
import UserLogin from "./pages/UserMain/UserLogin";
import NonUserLogin from "./pages/UserMain/NonUserLogin";
import StoreMain from "./pages/UserMain/StoreMain";
import BasicInfo from "./pages/UserMain/BasicInfo";
import Datepicker from "./pages/UserMain/Datepicker";
import Gift from "./pages/UserMain/Gift";
import ItemCreate from "./pages/UserMain/ItemCreate";
import NonUserTicket from "./pages/UserMain/NonUserTicket";
import Payment from "./pages/UserMain/Payment";
import PaymentCancel from "./pages/UserMain/PaymentCancel";
import PaymentList from "./pages/UserMain/PaymentList";
import QR from "./pages/UserMain/QR";
import QRDetail from "./pages/UserMain/QRDetail";
import QRwallet from "./pages/UserMain/QRwallet";
import Register from "./pages/UserMain/Register";
import SalePrd from "./pages/UserMain/SalePrd";
import SettleDetail from "./pages/UserMain/SettleDetail";
import SettleHistory from "./pages/UserMain/SettleHistory";
import SettleHistorySub from "./pages/UserMain/SettleHistorySub";
import StampReward from "./pages/UserMain/StampReward";
import StoreCalculate from "./pages/UserMain/StoreCalculate";
import Success from "./pages/UserMain/Success";
import Survey from "./pages/UserMain/Survey";
import UserStore from "./pages/UserMain/UserStore";
import Failure from "./pages/UserMain/Failure";
import Logininfo from "./pages/UserMain/Logininfo";
import Demologin from "./pages/sns/Demologin";
import Popup from "./pages/UserMain/Popup";
import Event from "./pages/UserMain/Event";
import EventDetail from "./pages/UserMain/EventDetail";
import Intro from "./pages/UserMain/Intro";
import WeekMenu from "./pages/UserMain/WeekMenu";
import GiftList from "./pages/UserMain/GiftList";
import GiftSuccess from "./pages/UserMain/GiftSuccess";
import NotFound2 from "./pages/NotFound2";
import Twallet from "./pages/UserMain/Twallet";
import OrderStatus from "./pages/UserMain/OrderStatus";
import OrderUnuse from "./pages/UserMain/OrderUnuse";
import NonMemOrder from "./pages/UserMain/NonMemOrder";
import { CommonModal } from "./pages/UserMain/components/CommonModal";

function App() {
  if (process.env.NODE_ENV !== "production") {
    console.log("domainType : " + localStorage.getItem("domainType"));
  }

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            {/* 데모 로그인 */}
            <Route path="/demologin" element={<Demologin />}></Route>

            <Route path="/event" element={<Event />}></Route>
            <Route
              path="/eventdetail/:number"
              element={<EventDetail />}
            ></Route>

            {/* 간편 로그인 */}
            <Route path="/sns/login" element={<demoLogin />}></Route>
            <Route path="/sns/naver" element={<Naver />}></Route>
            <Route path="/sns/kakao" element={<Kakao />}></Route>
            <Route path="/sns/apple" element={<Apple />}></Route>

            <Route path="/intro" element={<Intro />}></Route>
            <Route path="/weekmenu" element={<WeekMenu />}></Route>
            <Route path="/giftlist" element={<GiftList />}></Route>

            <Route path="/popup" element={<Popup />}></Route>

            {/* 샘플 코드 */}
            <Route path="/testcode/scroll" element={<Scroll />}></Route>
            <Route path="/datepicker" element={<Datepicker />}></Route>

            {/* 인제대 사용자 */}
            <Route path="/login" element={<UserLogin />}></Route>
            {/* 사용자 메인 */}
            <Route path="/storemain" element={<UserStore />}></Route>

            {/* 인제대 비회원 */}
            <Route path="/nonuserlogin" element={<NonUserLogin />}></Route>
            <Route
              path="/alimtalk/qrcode/:number"
              element={<NonUserTicket />}
            ></Route>
            <Route
              path="/alimtalk/sorder/:number"
              element={<NonMemOrder />}
            ></Route>

            {/* 스토어 메인 */}
            <Route path="/adminmain" element={<StoreMain />}></Route>

            {/* 스토어 메인 */}
            {/* <Route path="/storemain" element={<StoreMain />}></Route> */}

            {/* 스토어 기본정보 */}
            <Route path="/basicinfo" element={<BasicInfo />}></Route>

            {/* 교환권 생성 */}
            <Route path="/itemcreate" element={<ItemCreate />}></Route>

            {/* 선물하기 */}
            <Route path="/gift/:number" element={<Gift />}></Route>
            <Route path="/gift" element={<Gift />}></Route>

            <Route
              path="/giftsuccess/:couponName/:expiredAt/:oauthType/:storeName/:toName/:toPhoneNumber"
              element={<GiftSuccess />}
            ></Route>

            {/* 결제 */}
            <Route path="/payment" element={<Payment />}></Route>
            <Route
              path="/paymentcancel/:number"
              element={<PaymentCancel />}
            ></Route>
            <Route path="/paymentlist" element={<PaymentList />}></Route>
            {/* 결제 상세 */}
            <Route path="/paymentdetail/:number" element={<QRDetail />}></Route>

            {/* QR 확인, 비회원시 */}
            <Route path="/qr/:number" element={<QR />}></Route>
            <Route path="/qr" element={<QR />}></Route>

            {/* QR 지갑 */}
            <Route path="/qrwallet" element={<QRwallet />}></Route>

            {/* QR 지갑2 */}
            <Route path="/twallet" element={<Twallet />}></Route>

            {/* 주문현황 */}
            <Route
              path="/orderstatus/:number"
              element={<OrderStatus />}
            ></Route>
            {/* 주문현황 사용불가 */}
            <Route path="/orderunuse/:number" element={<OrderUnuse />}></Route>

            {/* 회원가입 */}
            <Route path="/register" element={<Register />}></Route>

            {/* 스템프 리워드 */}
            <Route path="/saleprd" element={<SalePrd />}></Route>

            {/* 정산 상세 */}
            <Route path="/settledetail" element={<SettleDetail />}></Route>
            <Route path="/settlehistory" element={<SettleHistory />}></Route>
            <Route
              path="/settlehistorysub"
              element={<SettleHistorySub />}
            ></Route>

            <Route path="/stampreward" element={<StampReward />}></Route>
            <Route path="/storecalculate" element={<StoreCalculate />}></Route>

            {/* 결제완료 */}
            <Route path="/success" element={<Success />}></Route>
            {/* 결제실패 */}
            <Route path="/failure" element={<Failure />}></Route>

            {/* 만족도 조사 */}
            <Route path="/survey/:number" element={<Survey />}></Route>
            <Route path="/survey" element={<Survey />}></Route>

            <Route path="/logininfo" element={<Logininfo />}></Route>

            <Route path="/appleregister" element={<Logininfo />}></Route>

            {/* <Route path="/demo/userlogin" element={<DemoUserLogin />}></Route>
              <Route path="/demo/userstore/:storeDomain" element={<DemoUserMain />}></Route>
              <Route path="/demo/qr/:number" element={<DemoQR />}></Route>
              <Route path="/demo/qrdetail/:number" element={<DemoQRDetail />}></Route>

              <Route path="/demo/payment" element={<DemoPayment />}></Route>
              <Route path="/demo/paymentlist" element={<DemoPaymentList />}></Route>

              <Route path="/demo/success" element={<DemoSuccess />}></Route>
              <Route path="/demo/paymentcancel" element={<DemoPaymentCancel />}></Route>
              
              <Route path="/demo/store/:storeDomain" element={<DemoStoreMain />}></Route>
              <Route path="/demo/itemcreate" element={<DemoItemCreate />}></Route>
              <Route path="/demo/storecalculate" element={<DemoStoreCalculate />}></Route>
              <Route path="/demo/settlehistory" element={<DemoSettleHistory />}></Route>
              <Route path="/demo/settlehistorysub" element={<DemoSettleHistorySub />}></Route>
              <Route path="/demo/settledetail/:Mode" element={<DemoSettleDetail />}></Route> */}

            <Route path="/" element={<UserLogin />}></Route>

            <Route path="/system" element={<NotFound2 />}></Route>

            <Route path="/error" element={<Error />}></Route>
            <Route path="/404" element={<NotFound />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </header>
      <CommonModal />
    </div>
  );
}

export default App;
