import {createContext, useState} from "react";
import {CommonModal} from "./CommonModal";

export const ModalContext = createContext('');

export const ModalProvider = ({children}) =>{
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState();
    const openModal = ({title,text,onCancel})=>{
        setIsOpen(true);
        setModalData({title,text,onCancel})
    }
    const closeModal = () =>{
        setIsOpen(false);
        setModalData({});
    };
    const contextValue = {
        isOpen, // isOpen 속성 추가
        openModal, // openModal 속성 추가
        closeModal, // closeModal 속성 추가
        modalData, // modalData 속성 추가
    };

    return (

        <ModalContext.Provider value={contextValue} >
            {children}
        </ModalContext.Provider>

    );
};
