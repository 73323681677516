import React from 'react';
import { useNavigate } from 'react-router-dom';

export function getRedirctUrl( url = '' ){
    return process.env.REACT_APP_BASEURL + url;
}

export function getApiUrl( api = '' ){
    return process.env.REACT_APP_API + api;
    // return "https://api.flexday.kr/" + api;
    // return 'https://dev-api.flexday.kr/' + api;
}

export function getImgUrl(url){
    return process.env.REACT_APP_IMGURL + url
    // return "https://dev.flexdaycdn.net/public/" + url;
    // return "https://www.flexdaycdn.net/public/" + url;
}
export function LocationUrl(url){
    const navigate = useNavigate();
    navigate(url);
}

//await timeout(1000);
export function LoginCheck(){
    if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
        return 'LOGIN';
    }else{
        return 'VIEWER';
    }
}

export function PrintConsole(log){
    if(process.env.NODE_ENV !== 'production'){
        console.log(log);
    }
}

export function getSubDomain(){
    if (window.location.host.split('.')[0] === 'admin'){
        return 'admin';
    }else if (window.location.host.split('.')[0] === 'store'){
        return 'store';
    }else if (window.location.host.split('.')[0] === 'user'){
        return 'user';
    }else{
        return 'localhost';
    }
}

export function sleep(ms) {
    return new Promise(
        resolve => setTimeout(resolve, ms)
    );
}

export function setCommas(x) {
    if(isNaN(x)){
        return ''
    }else{
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}

export function setPhone(x){
    let formattedValue = x.toString();
    
    return formattedValue.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
}

export function getWeekDay(date){
    let TmpDate = date.toString().split(" ");

    const inputDate = new Date(TmpDate[0]); // YYYY-MM-DD 형식으로 입력
    // 요일 이름 배열 생성
    const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
    // 입력한 날짜의 요일 이름 가져오기
    const dayOfWeek = weekdays[inputDate.getDay()];

    return TmpDate[0] + "(" +dayOfWeek +") " +TmpDate[1] ;
}

export function getWeekDay2(date){
    let TmpDate = date.toString().split(" ");

    const inputDate = new Date(TmpDate[0]); // YYYY-MM-DD 형식으로 입력
    // 요일 이름 배열 생성
    const weekdays = ["일", "월", "화", "수", "목", "금", "토"];
    // 입력한 날짜의 요일 이름 가져오기
    const dayOfWeek = weekdays[inputDate.getDay()];

    const dateArr = TmpDate[0].split('-'); // '-'를 구분자로 사용하여 문자열을 배열로 분리합니다.
    const month = dateArr[1];
    const day = dateArr[2];

    return month + "/" + day + "(" +dayOfWeek +")";
}

export function getNewlines(html) {
    if (!html) return null

    const lines = html.split('\\n')

    if (lines.length === 0) {
        return html
    }

    return lines.map((line, index) => (
        React.createElement('span', {
            key: index,
        }, line, React.createElement('br'))
    ))
}

export function includeStr(str, check){
    if(str.includes(check)){
        return true
    }else{
        return false;
    }
}

export function makeAddress(item) {
    if (!item) {
        return;
    }

    var name = item.name,
        region = item.region,
        land = item.land,
        isRoadAddress = name === 'roadaddr';

    var sido = '', sigugun = '', dongmyun = '', ri = '', rest = '';

    if (hasArea(region.area1)) {
        sido = region.area1.name;
    }

    if (hasArea(region.area2)) {
        sigugun = region.area2.name;
    }

    if (hasArea(region.area3)) {
        dongmyun = region.area3.name;
    }

    if (hasArea(region.area4)) {
        ri = region.area4.name;
    }

    if (land) {
        if (hasData(land.number1)) {
            if (hasData(land.type) && land.type === '2') {
                rest += '산';
            }

            rest += land.number1;

            if (hasData(land.number2)) {
                rest += ('-' + land.number2);
            }
        }

        if (isRoadAddress === true) {
            if (checkLastString(dongmyun, '면')) {
                ri = land.name;
            } else {
                dongmyun = land.name;
                ri = '';
            }

            if (hasAddition(land.addition0)) {
                rest += ' ' + land.addition0.value;
            }
        }
    }

    return [sido, sigugun, dongmyun, ri, rest].join(' ');
}

export function hasArea(area) {
    return !!(area && area.name && area.name !== '');
}

export function hasData(data) {
    return !!(data && data !== '');
}

export function checkLastString (word, lastString) {
    return new RegExp(lastString + '$').test(word);
}

export function hasAddition (addition) {
    return !!(addition && addition.value);
}

export function maxLengthCheck(object) {  
    if (object.value.Length > object.max.Length)
        object.value = object.value.slice(0, object.max.Length)
}

export function EmailCheck(text){
    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    
    if (text.match(regExp) != null) {
        return true;
    } else {
        return false;
    }
}
export function EngNumCheck(text){
    console.log(text);
    var regExp = /^[A-Za-z0-9]+$/;
    if (text.match(regExp) != null) {
        return false;
    } else {
        return true;
    }
    
}

export function encodeFileToBase64(fileBlob){
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
        reader.onload = () => {
            console.log(reader.result);
            return reader.result
        };
    })
}

export function timer(id, time){
    var timer = time
    var min = '';
    var sec = '';

    var x = setInterval(function(){
        min = parseInt(timer/60);
        sec = timer%60;

        document.getElementById(id).innerHTML = min + '분' + sec + '초';
        timer--;

        if(timer < 0){
            clearInterval(x);
        }
    }, 1000);
}

export function AlertError(error){
    let detailMessage;
    let errorDetails = error.response.data.errorDetails;
    if(errorDetails && errorDetails.length > 0){
        detailMessage = errorDetails[0].reason;
    }else{
        detailMessage = error.response.data.message
    }
    alert(detailMessage);
}

export function getErrorMessage(error){
    let detailMessage;
    let errorDetails = error.response.data.errorDetails;
    if(errorDetails && errorDetails.length > 0){
        detailMessage = errorDetails[0].reason;
    }else{
        detailMessage = error.response.data.message
    }
    return detailMessage;
}

export function getTextLength(str) {
    var len = 0;
    for (var i = 0; i < str.length; i++) {
        if (escape(str.charAt(i)).length == 6) {
            len++;
        }
        len++;
    }
    return len;
}

export function getWeekDayz(Date){
    // 오늘 날짜 생성
    // console.log(Date);
    const today = Date;
    // 오늘의 요일 구하기 (0:일요일 ~ 6:토요일)
    const dayOfWeek = today.getDay();

    // 평일 날짜 구하기
    let weekday = null;
    if (dayOfWeek === 0) { // 일요일
        weekday = new Date(today.getTime() + 86400000); // 1일 (86400000밀리초) 더하기
    } else if (dayOfWeek === 6) { // 토요일
        weekday = new Date(today.getTime() + 172800000); // 2일 (172800000밀리초) 더하기
    } else { // 월요일 ~ 금요일
        weekday = today;
    }

    // 평일 날짜의 년, 월, 일 구하기
    const year = weekday.getFullYear();
    const month = weekday.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1을 해줍니다.
    const day = weekday.getDate();

    // // 평일 날짜의 요일 구하기
    const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
    const weekdayName = weekdays[weekday.getDay()];

    console.log(`${year}년 ${month}월 ${day}일 (${weekdayName})`);

    let TmpToDayName = weekdays.indexOf(weekdayName);

    console.log(TmpToDayName)

    return weekday;
}

export function getNomalDayz() {
    var currentDay = new Date();  
    var theYear = currentDay.getFullYear();
    var theMonth = currentDay.getMonth();
    var theDate  = currentDay.getDate();
    var theDayOfWeek = currentDay.getDay();
    
    var thisWeek = [];
    
    for(var i=1; i<6; i++) {
        var resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
        var yyyy = resultDay.getFullYear();
        var mm = Number(resultDay.getMonth()) + 1;
        var dd = resultDay.getDate();
        
        mm = String(mm).length === 1 ? '0' + mm : mm;
        dd = String(dd).length === 1 ? '0' + dd : dd;
        
        thisWeek[i-1] = yyyy + '-' + mm + '-' + dd;
    }
    return thisWeek;
}

export function compareDatesWithTime(date, time) {
    const dateHours = date.getHours();
    const dateMinutes = date.getMinutes();
    const dateSeconds = date.getSeconds();

    const timeParts = time.split(':');
    const timeHours = parseInt(timeParts[0], 10);
    const timeMinutes = parseInt(timeParts[1], 10);
    const timeSeconds = parseInt(timeParts[2], 10);

    if (dateHours < timeHours) {
        return -1;
    } else if (dateHours > timeHours) {
        return 1;
    } else {
        if (dateMinutes < timeMinutes) {
            return -1;
        } else if (dateMinutes > timeMinutes) {
            return 1;
        } else {
            if (dateSeconds < timeSeconds) {
                return -1;
            } else if (dateSeconds > timeSeconds) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}
export function getWalletTitle(){
    const storeDomain= localStorage.getItem('defaultStore')
    if(storeDomain == 'paik'){
        return '의과대학(다인) QR 지갑';
    }else if(storeDomain == 'dasom'){
        return '다솜 식권 지갑';
    }else{
        return '늘빛관(다인) QR 지갑';
    }
}