import { Spinner } from "react-bootstrap";

import { useState } from "react";

import {
  useNavigate,
  Link,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useEffect } from "react";

import {
  AlertError,
  getApiUrl,
  PrintConsole,
  setCommas,
  sleep,
} from "../../function/common";

import axios from "axios";

import Header from "./components/Header";
import "./QRDetail.css";
import { useRef } from "react";

export default function QRDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { number } = useParams();

  const [payment, setPayment] = useState();
  const [paymentYN, setPaymentYN] = useState("FALSE");

  function getPaymentDetail() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        isUsed: true,
      };
      const response = await axios
        .get(
          getApiUrl("v1/payments/" + number),

          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setPayment(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("error");
        });
      if (response) {
        return resolve(response);
      }
    });
  }

  const PaymentCancel = async () => {
    let obj = {
      orderNumber: number,
    };
    const response = await axios
      .delete(
        getApiUrl("v1/payments/" + number),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;"),
        (axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken"))
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          navigate("/paymentcancel/" + number);
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  };

  useEffect(() => {
    if (loading === false) {
      let today = new Date();
      let year = today.getFullYear(); // 년도
      let month = today.getMonth() + 1; // 월
      if (month < 10) {
        month = "0" + month;
      }
      let date = today.getDate(); // 날짜
      if (date < 10) {
        date = "0" + date;
      }

      // payment.coupons.map( row => (
      //     console.log(row.status.code)
      // ))
      let TmpPaymentYN = "";
      payment.coupons.map((row) =>
        TmpPaymentYN !== "FALSE"
          ? row.status.code === 2
            ? (TmpPaymentYN = "FALSE")
            : (TmpPaymentYN = "TRUE")
          : console.log(" PaymentYN " + TmpPaymentYN)
      );

      // console.log(TmpPaymentYN);

      let TmpDate = payment.completedAt;

      console.log(TmpDate.split(" ")[0]);
      console.log(year + "-" + month + "-" + date);

      if (TmpDate.split(" ")[0] !== year + "-" + month + "-" + date) {
        TmpPaymentYN = "FALSE";
      }

      if (payment.paymentStatus.code === 3) {
        TmpPaymentYN = "FALSE";
      }

      setPaymentYN(TmpPaymentYN);
      // console.log(PaymentYN.current);
    }
  }, [payment]);

  useEffect(() => {
    getPaymentDetail();
    // console.log(payment);
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
  // getVersion();
  // setAccessToken();

  return (
    <>
      <div className="demo">
        <div className="TPayDetail2Page">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">결제 상세</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>
            <div className="PayDetailBox px-4-5 py-4-5 ">
              {/* 확인 : 결제취소 / class 'cancel' 추가 */}
              <div className="PayDetail mt-4">
                <img
                  className="img-fluid clip_icon"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/clip_icon.png"
                />

                <div className="PayDetail-Tit">식권 상품</div>
                {payment.coupons.map((row) => (
                  <div className="PayDetail-Item d-flex mb-2">
                    <div className="prd">
                      {row.productName} <span className="small">X</span> 1개
                    </div>
                    <div className="used">{row.status.description}</div>
                  </div>
                ))}
                <div className="PayDetail-bar"></div>
                <div className="PayDetail-Tit">결제 정보</div>
                <div className="PayDetail-Item d-flex mb-2">
                  <div className="tit">결제 방법</div>
                  <div className="price text-end">
                    {payment && payment.payType.description}
                  </div>
                </div>
                <div className="PayDetail-Item d-flex mb-2">
                  <div className="tit">결제 금액</div>
                  <div className="price text-end">
                    <span>{payment && setCommas(payment.paymentPrice)}</span>원
                  </div>
                </div>
                <div className="PayDetail-Item d-flex mb-2">
                  <div className="tit">결제 일자</div>
                  <div className="price text-end">
                    {payment && payment.completedAt}
                  </div>
                </div>
                <div className="PayDetail-Item d-flex">
                  <div className="tit">결제 상태</div>
                  <div className="price state text-end">
                    {payment && payment.paymentStatus.description}
                  </div>
                </div>
              </div>

              <p className="PayInfotxt mb-4">
                • 구매한 날짜에 사용하지 못하셨다면 <br />
                유효기간 내 같은 금액의 식단으로 사용이 가능합니다.
              </p>

              <p className="PayInfotxt">
                • 구매일 당일(~23:59)까지 취소가 가능합니다. <br />
                (단, 구매 건에 이미 사용된 교환권이 있는 경우 취소가
                불가능합니다.)
              </p>

              {paymentYN === "TRUE" ? (
                <div className="PayDetail-Confirm py-4" id="PaymentCancel">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      PaymentCancel();
                    }}
                  >
                    결제 취소
                  </button>
                </div>
              ) : (
                <div className="PayDetail-Confirm py-4" id="PaymentCancel">
                  <button type="button" className="btn btn-secondary" disabled>
                    결제 취소
                  </button>
                </div>
              )}

              {/* <Link to="/demo/paymentcancel" className="btn btn-default mb-4">
                            결제 취소
                        </Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
