import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
// ver3 Button 추가
import { Spinner, Button } from "react-bootstrap";

import axios from "axios";
import {
  AlertError,
  getApiUrl,
  getErrorMessage,
  getWalletTitle,
} from "../../function/common";

// ver3 모달 추가
import Modal from "react-bootstrap/Modal";

import Header from "./components/Header";
import "./Twallet.css";
import ReactSwipeButton from "react-swipe-button";
import SwipeButton from "./components/twallet/SwipeButton";
import { useRef } from "react";
import QrCode from "./../../components/common/QrCode";
import CouponOrder from "./components/twallet/CouponOrder";
import ReceivedCouponOrder from "./components/twallet/ReceivedCouponOrder";
import { ModalContext } from "./components/ModalProvider";
import ReactPullToRefresh from "react-pull-to-refresh/dist";
import PullToRefresh from "react-simple-pull-to-refresh";

export default function Twallet() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const storeType = localStorage.getItem("storeType");
  const scrollRef = useRef();
  const params = useLocation();

  // ver3 주문하기 모달
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const selectQRCode = useRef();
  const selectProduct = useRef();
  function handleShow(TmpQRCode, TmpProduct) {
    selectQRCode.current = TmpQRCode;
    selectProduct.current = TmpProduct;
    setShow(true);
  }

  const smartOrder = async (e) => {
    let obj = {
      qrcode: selectQRCode.current,
    };
    await axios
      .post(
        getApiUrl("v1/coupons-sorder/"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          window.location.reload(false);
        }
      })
      .catch(function (error) {
        handleClose();
        modalContext.openModal({ title: "", text: getErrorMessage(error) });
        // AlertError(error);
        // AlertError(error);
      });
  };

  const [useProduct, setUseProduct] = useState();
  function getUseWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "USED",
      };

      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "USED",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }

      const response = await axios
        .get(
          getApiUrl("v1/coupons-sorder"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setUseProduct(response.data);
            // console.log(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  const [notUseProduct, setNotUseProduct] = useState();
  function getNotUseWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "NOT_USED",
      };

      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "NOT_USED",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }

      const response = await axios
        .get(
          getApiUrl("v1/coupons-sorder"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setNotUseProduct(response.data);
            // console.log(response.data);
            resolve();
          }
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  const [giftProduct, setGiftProduct] = useState();

  function getGiftWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "NOT_USED",
        isGift: "true",
      };
      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "NOT_USED",
          isGift: "true",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }
      const response = await axios
        .get(
          getApiUrl("v1/coupons-sorder"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setGiftProduct(response.data);
            // console.log(response.data);
            resolve();
          }
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  function refresh() {
    return new Promise(async function (resolve, reject) {
      getNotUseWallet();
      getGiftWallet();
      resolve();
    });
  }

  const modalContext = useContext(ModalContext);

  useEffect(() => {
    //데이터 로딩
    const fetchData = async () => {
      await getNotUseWallet();
      await getGiftWallet();
      await getUseWallet();
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const scrollToDom = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    return;
  };
  useEffect(() => {
    console.log(params.hash);
    if (!loading && params?.hash === "#gift") {
      scrollToDom(scrollRef);
    }
  }, [scrollRef, params, loading]);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TwalletPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">{getWalletTitle()}</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  if (storeType === "SMART_ORDER") {
                    if (params?.hash === "#gift") {
                      navigate(-1);
                    } else {
                      navigate(-2);
                    }
                  } else {
                    navigate(-1);
                  }
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>
            <div className="PayCateTab">
              <div
                className="nav nav-pills justify-content-around mb-4-5"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link active PayCateBtn"
                    id="pills-content1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content1"
                    type="button"
                    role="tab"
                    aria-controls="pills-content1"
                    aria-selected="true"
                  >
                    <span>
                      사용 가능 (
                      {notUseProduct &&
                        giftProduct &&
                        notUseProduct.totalCount + giftProduct.totalCount}
                      )
                    </span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link PayCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content2"
                    type="button"
                    role="tab"
                    aria-controls="pills-content2"
                    aria-selected="false"
                  >
                    <span>
                      사용 완료 (
                      {useProduct && useProduct.totalCount <= 99
                        ? useProduct.totalCount
                        : "99+"}
                      )
                    </span>
                  </button>
                </div>
              </div>
              <div className="tab-content pb-5" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-content1"
                  role="tabpanel"
                  aria-labelledby="pills-content1-tab"
                >
                  <PullToRefresh
                    onRefresh={() => refresh()}
                    refreshingContent={<div className="loader"></div>}
                    pullingContent={<div className="loader"></div>}
                  >
                    {/*<PullToRefresh onRefresh={(resolve) => {getNotUseWallet();getGiftWallet();resolve()}}>*/}
                    <div className="PayPrdList px-4-5">
                      {notUseProduct &&
                        notUseProduct.data.map((row, index) => (
                          <CouponOrder
                            key={index}
                            row={row}
                            handleShow={handleShow}
                          />
                        ))}
                    </div>
                  </PullToRefresh>
                  <div className="PayPrdList gift px-4-5">
                    <div
                      className="giftTxt"
                      ref={scrollRef}
                      style={{
                        display:
                          giftProduct?.data?.length > 0 ? "block" : "none",
                      }}
                    >
                      받은 식권
                    </div>
                    {giftProduct &&
                      giftProduct.data.map((row, index) => (
                        <ReceivedCouponOrder
                          key={index}
                          row={row}
                          handleShow={handleShow}
                        />
                      ))}
                  </div>
                </div>

                <div className="tab-pane" id="pills-content2" role="tabpanel">
                  <div className="PayPrdList px-4-5">
                    {useProduct &&
                      useProduct.data.map((row, index) =>
                        row.useServey === true ? (
                          <div key={index} className="PayListItem used">
                            <div className=" infobox">
                              <div className="InfoboxTxt">
                                <div className="txtbox">
                                  <p className="prd-date">
                                    사용일시 {row.usedAt}
                                  </p>
                                  <p className="prd-name">{row.productName}</p>
                                  <p className="store-name">{row.storeName}</p>
                                  <img
                                    className="img-fluid prd-stamp"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver3/QR_ticket_stamp_done.png"
                                  />
                                </div>
                                <div className="btnbox">
                                  <button
                                    type="type"
                                    className="btn btn-light btn-state"
                                    onClick={() => {
                                      navigate(
                                        "/orderstatus/" +
                                          row.lastSmartOrder.smartOrderId
                                      );
                                    }}
                                  >
                                    주문 현황
                                  </button>
                                  <button
                                    type="type"
                                    className="btn btn-default btn-survey"
                                    onClick={() => {
                                      navigate("/survey/" + row.qrcode);
                                    }}
                                  >
                                    만족도 조사
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="PayListItem survey-done">
                            <div className=" infobox">
                              <div className="InfoboxTxt">
                                <div className="txtbox">
                                  <p className="prd-date">
                                    사용일시 {row.usedAt}
                                  </p>
                                  <p className="prd-name">{row.productName}</p>
                                  <p className="store-name">{row.storeName}</p>
                                  <img
                                    className="img-fluid prd-stamp"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver3/QR_ticket_stamp_done.png"
                                  />
                                </div>
                                <div className="btnbox">
                                  <button
                                    type="type"
                                    className="btn btn-light btn-state"
                                    onClick={() => {
                                      navigate(
                                        "/orderstatus/" +
                                          row.lastSmartOrder.smartOrderId
                                      );
                                    }}
                                  >
                                    주문 현황
                                  </button>
                                  <button
                                    type="type"
                                    className="btn btn-default btn-survey"
                                    onClick={() => {
                                      navigate("/survey/" + row.qrcode);
                                    }}
                                  >
                                    만족도 조사
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}

                    {/* 임시로 숨김처리 / 만족도 조사 완료되었을경우 class "survey-done" 추가 */}
                    <div className="d-none PayPrdList px-4-5">{}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 주문하기 Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            size="sm"
            className="demoModal modal-prdorder"
          >
            <div className="modal-bar py-3">
              <div className="bar"></div>
            </div>
            <Modal.Header>
              <Modal.Title>
                <span>{selectProduct.current}</span> <br />
                메뉴를 주문합니다
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="desc_list">
                <p className="list">
                  주문 시 매장에서 바로 조리가 들어가므로 <br />
                  주문 취소가 불가능 합니다
                </p>
                <p className="list">
                  메뉴가 준비되면 카카오 알림톡으로 안내 해드립니다
                </p>
                <p className="list">
                  픽업 알림을 받으시면 , 메뉴는 다솜식당에서 픽업 해주세요
                </p>
              </div>
              <div className="slide_btnbox">
                {/* <div className="slide_circle">
                                <img className="img-fluid" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver3/order_arrow.png" />
                            </div> */}

                {/* <div className="txt">
                                밀어서 주문하기
                            </div> */}

                {/* <ReactSwipeButton 
                                text='밀어서 주문하기'
                                text_unlocked={'주문 완료'}
                                onSuccess={() => {smartOrder()}}
                            /> */}

                <SwipeButton
                  onSuccess={() => {
                    smartOrder();
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
