import Modal from "react-bootstrap/Modal";
import { useState } from "react";


export default function Serviceterms() {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    setFullscreen("lg-down");
    setShow(true);
  }

  function getIssuer(){
    if(localStorage.getItem('defaultStore') === 'inje'){
      return(
          <>
            인제대학교 늘빛관 다인
          </>
      )
    }else if(localStorage.getItem('defaultStore') === 'paik'){
      return(
          <>
            인제대학교 백병원 다인
          </>
      )
    }else if(localStorage.getItem('defaultStore') === 'dasom'){
      return(
          <>
            인제대학교 다솜 식당
          </>
      )
    }
  }

  return (
    <>
    <span onClick={() => handleShow()}>보기</span>
      <Modal className="demo-modal" show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-start lh-sm">
              인제대학교 구매약관
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="title">상품고시정보</p>
            <div className="txtbox">
              <p className="contents">
                • 발행자: { getIssuer() }
                <br/>
                • 유효기간: 2022년 5월 31일 이전 발급 : 발급일 포함 90일/ 2022년 6월 1일 이후 발급: 발급일 포함 180일
                <br/>
                • 교환권 공급자: ㈜플렉스데이
                <br/>
                • 이용조건: 상품상세 설명 참조
                <br/>
                • 이용가능매장: { getIssuer()}
                <br/>
                • 환불조건, 방법: 구매자취소(청약철회) 가능 기간까지는 구매자가 취소를 요청할 수 있으며 실제 결제 금액의 100%가 구매자에게 결제 취소/환불 됩니다. 이후에는 수신자가 환불을 요청할 수 있으며 실제 결제 금액의 90%가 수신자에게 환불됩니다.
                <br/>
                • 소비자상담 관련 전화번호: 1811-8331 (통화료 발생/평일 10시~18시)
                <br/>
                • 지급보증: 본 모바일 교환권은 가맹점과의 계약에 따라 ㈜플렉스데이가 지급보증 합니다.
              </p>   
            </div>

            <p className="title">취소/환불 정책 및 방법</p>
            <div className="txtbox">
              <p className="contents">
                • 구매자 취소 (청약철회) 가능 기간 이내에는 구매자가 결제 금액의 100%에 대해 결제 취소/환불을 요청할 수 있습니다.
                <br/>
                • 구매자 취소 가능 기간 이후에는 수신자가 결제 금액의 90%에 대해 환불 요청을 할 수 있습니다. 단, 환불 신청시 간단한 본인인증 및 본인계좌 확인이 필요할 수 있습니다.
                <br/>
                • 환불은 실제 결제한 금액으로 진행되며, 이로 인해 결제 취소/환불 신청시 안내된 금액과 다를 수 있습니다.
                <br/>
                • 결제취소는 미사용 교환권에 대해서만 가능하며, 사용한 교환권에 대해서는 불가능합니다.
                <br/>
                • 미성년자의 회원이 서비스를 이용하여 상품 등을 구매 시 법정대리인이 해당 계약에 대하여 동의를 하여야 정상적인 상품 등의 구매계약이 체결될 수 있습니다. 만약, 미성년자인 회원이 법정대리인의 동의 없이 상품 등을 구매하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.
                <br/>
                • 취소가능 기간은 구매일로 부터 2주까지 가능합니다.
                <br/>
                • 휴대폰 결제 환불 처리 방침: 휴대폰 결제의 경우 결제취소는 당월만 가능하며, 익월 이후 취소요청 시 휴대폰 요금 납부 확인 후 결제자 본인계좌로만 환불 가능합니다.
              </p>   
            </div>

            <p className="title">거래조건에 관한 정보</p>
            <div className="txtbox">
              <p className="sub-title"></p>
              <p className="contents">
              • 유효기간 정책 및 연장 방법
              <br/>
              1. 2022.5.31 까지의 주문된 물품 교환권 및 금액권은 3개월, 잔액형 교환권은 3개월의 유효기간을 가지며, 2022.6.1 이후 주문된 교환권의 유효기간은 3개월입니다. 유효기간은 최종 유효기간 내에서 연장 신청이 가능하고, 특별한 사유가 없는 한 3개월 단위로 1회 연장이 가능합니다. 
              <br />
              2. 한시적으로 제공되는 무료쿠폰 및 프로모션 연계상품의 경우 유효기간 연장 및 연장 정책이 일반상품과 상이할 수 있습니다.
              <br />
              <br />
              • 교환권 영수증 발행 안내
              <br />
              1. 교환권은 구매하실 때 현금 영수증이 발행되지 않으며, 선물 받으신 분께서 물품 또는 서비스로 교환하실 때 각 사용처에 매장에서 발행됩니다.
              <br />
              2. 거래명세서(또는 거래확인서)의 발급은 결제수단에 따라 아래의 방법으로 진행하실 수 있습니다.
              <br />
              <br />
              • 휴대폰 결제
              <br />
              토스 홈페이지(https://pay.toss.im/pay/)
              <br />
              <br />
              • 일반 신용카드 결제
              <br />
              토스 홈페이지(https://pay.toss.im/pay/)
              <br />
              <br />
              • 물품제공 불가시 정책 물품교환형 교환권에 대해 해당 물품의 제공이 불가능하거나 제공에 필요한 통상적인 기간보다 현저히 제공이 지체되는 경우에는 해당 교환권과 동일한 금전적 가치의 교환권으로 변경될 수 있으며, 다른 교환권으로 교환이 불가능한 경우에는 구매액 전액을 취소/환불할 수 있습니다.


              </p>              
            </div>

            <p className="title">구매 주의사항</p>

            <div className="txtbox">
              <p className="contents">
              • 결제대금예치에 관한 사항: (주)플렉스데이는 고객께서 현금성 결제수단(가상계좌 등)으로 결제한 거래에 대하여 안전거래를 보장하고 있습니다.
              </p>              
            </div>
         
        </Modal.Body>
      </Modal>
    </>
  );
}
