import { Link, useSearchParams, useNavigate } from "react-router-dom";

import {
  AlertError,
  getApiUrl,
  getRedirctUrl,
  includeStr,
  setCommas,
} from "../../function/common";

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";

import { useParams } from "react-router-dom";

import AdminHeader from "./components/AdminHeader";
import "./StoreMain.css";

import { getImgUrl, PrintConsole } from "../../function/common";
import { openQRRead, setAccessToken, setNav1 } from "../../api/ApiWebView";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Image } from "react-bootstrap";

export default function DemoStoreMain() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  console.log(Parameters);

  const NonUserLogin = async (e) => {
    let obj = {
      token_version_id: Parameters[0]["token_version_id"],
      enc_data: Parameters[1]["enc_data"],
      integrity_value: Parameters[2]["integrity_value"],
    };
    await axios
      .post(
        getApiUrl("v1/auth/self/result"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        // console.log(response);
        if (response.status === 200) {
          // console.log(response.data.loginToken.accessToken);
          localStorage.setItem(
            "NonUserToken",
            response.data.loginToken.accessToken
          );
          console.log();
        } else {
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  };

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    if (Parameters.length > 0) {
      NonUserLogin();
    }

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <>
      <div className="demo">
        <div className="TicketPage admin">
          <div className="container-fluid pt-70px g-0">
            <nav className="navbar bg-none fixed-top">
              <div className="container-fluid">
                <a className="navbar-brand hide" href="#">
                  기본 텍스트
                </a>

                <AdminHeader />
              </div>
            </nav>

            <div className="TicketPhoto">
              <div className="TicketPhoto-Slider">
                <Swiper
                  loop={true}
                  pagination={{ el: ".TS-pag", type: "fraction" }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper d-flex"
                >
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main02.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main03.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100 StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main04.png"
                    />
                  </SwiperSlide>
                  <SwiperSlide>
                    <Image
                      fluid
                      className="w-100StoreBg"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket/store_main05.png"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="TicketPhoto-Content d-flex">
                <button type="button" className="btn btn-clear btn-camera">
                  <img
                    className="img-fluid"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png"
                  />
                </button>

                <div className="PhotoCount swiper-pagination TS-pag"></div>
              </div>
            </div>

            <div className="StoreDetail pt-4-5">
              <div className="StoreTitle align-items-center d-flex px-4-5">
                <img
                  className="img-fluid logo"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                />
                <p className="mb-0 tit">인제대학교 늘빛관(다인)</p>
              </div>
              <div className="StoreInfo  px-4-5">
                <div className="g-0 row mb-2">
                  <div className="col-2 tit">운영시간</div>
                  <div className="col-10 desc">
                    주중 - 11:00~18:00
                    <br />
                    (Breack time 14:30~15:30)
                    <br />
                    주말 - 휴무
                  </div>
                </div>
                <div className="g-0 row">
                  <div className="col-2 tit">위치안내</div>
                  <div className="col-10 desc">늘빛관 1층</div>
                </div>
              </div>

              <div className="StoreTab-wrap b-top">
                <nav className="StoreTab px-4-5">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-01"
                      role="tab"
                    >
                      <span>판매 상품</span>
                    </button>
                    <button
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-02"
                      role="tab"
                    >
                      <span>판매 중지</span>
                    </button>
                  </div>
                </nav>

                <div
                  className="StoreTab-content tab-content px-4-5"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-01"
                    role="tabpanel"
                    aria-labelledby="nav-01-tab"
                    tabIndex="0"
                  >
                    <div className="ProductList">
                      <div className="prd-item">
                        <p className="prd-tit">메뉴 주문 후</p>
                        <p className="prd-sub">
                          뚝배기 갈비탕 + 당면사리 / 무우 잔파 생채 / 양파
                          마늘쫑 장아찌
                        </p>
                        <p className="prd-price">
                          <span className="price">4,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          100개 남음
                        </div>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">B코너</p>
                        <p className="prd-sub">
                          훈제오리 마늘 볶음밥 + 계란후라이 / 샐러드 /
                          해쉬브라운 + 케찹 / 우동장국
                        </p>
                        <p className="prd-price">
                          <span className="price">6,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          90개 남음
                        </div>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">C코너</p>
                        <p className="prd-sub">
                          수제 등심돈까스 / 샐러드 + 드레싱 / 크림스프
                        </p>
                        <p className="prd-price">
                          <span className="price">6,000</span>원
                        </p>
                        <div className="quantity-txt">
                          <img
                            className="img-fluid icon"
                            alt=""
                            src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                          />
                          15개 남음
                        </div>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">공기밥</p>
                        <p className="prd-sub">면요리에 추가해서 드세요.</p>
                        <p className="prd-price">
                          <span className="price">5000</span>원
                        </p>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">도시락 김</p>
                        <p className="prd-sub">추가 반찬</p>
                        <p className="prd-price">
                          <span className="price">300</span>원
                        </p>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                      <div className="prd-item">
                        <p className="prd-tit">스파클링 탄산음료</p>
                        <p className="prd-sub">사과, 포도, 오렌지 중 택1</p>
                        <p className="prd-price">
                          <span className="price">900</span>원
                        </p>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type13 btn-default"
                          >
                            판매 중지하기
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="nav-02"
                    role="tabpanel"
                    aria-labelledby="nav-02-tab"
                    tabIndex="0"
                  >
                    <div className="ProductList">
                      <div className="prd-item">
                        <p className="prd-tit">복날 특선</p>
                        <p className="prd-sub">
                          무더위에 지친 학생분들을 위해 특별식을 준비했습니다.
                          삼계탕 한 그릇과 함께 원기회복 하세요!
                        </p>
                        <p className="prd-price">
                          <span className="price">4,000</span>원
                        </p>
                        <div className="prd-btnbox d-flex">
                          <button
                            type="button"
                            className="btn type14 btn-default"
                          >
                            판매 재시작
                          </button>
                          <button
                            type="button"
                            className="btn type13 btn-light"
                          >
                            영구 삭제
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="StoreQR px-4-5 text-end">
              <img
                className="img-fluid QR"
                alt=""
                src="https://www.flexdaycdn.net/public/images/ticket/scan3.png"
                onClick={() => {
                  openQRRead();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
