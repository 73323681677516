import { Image, Button, Row, Col } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { AlertError, getApiUrl, getRedirctUrl, includeStr, setCommas } from '../../function/common';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Header from './components/Header';
import './StoreMain.css';
import './Event.css';

import { getImgUrl, PrintConsole } from '../../function/common';

import { openQRRead, setAccessToken, setNav1 } from '../../api/ApiWebView';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Footer from './components/Footer';


export default function EventDetail(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const { number } = useParams();

    const [banner, setBanner] = useState();

    function getBanner() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('v1/advertisements/'+number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setBanner(response.data);
                    }
                    setLoading(false);
                }
            ).catch(function(error){
                console.log('error');
            })
            if (response) {
                return resolve(response);
            }
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        //함수 호출
        getBanner();

        
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

    return (
        <>
        <div className='demo'>
            <div className="TicketPage">
                <div className="container-fluid pt-70px g-0">

                    <nav className="navbar bg-none fixed-top">
                        <div className="container-fluid">
                            <a className="navbar-brand hide" href="#">기본 텍스트</a>
                            <Header/>

                        </div>
                    </nav>

                    
                    <Image fluid className="w-100" alt="" src={banner && banner.detailBannerUrl} />
                    

                
            </div>

            <Footer/>

        </div>
    </div>
        </>
    )
}