
import Serviceterms from './ServiceTerms';
import Userterms2 from './Userterms2';

export default function Footer() {


  return (
    <>
      <div className="Footer">      
        <div className="footer-infobox">
          <div className="accordion ver2">
            <div className="accordion-item bg-black">
              <p className="accordion-headerd mb-0 bg-black">
                <button className="accordion-button bg-black p-0 rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                  <img src="https://www.flexdaycdn.net/public/images/ticket/footer_logo.png" className="footer-img"/>
                </button>
              </p>
              <div id="collapseOne" className="accordion-collapse collapse show bg-black border-0 rounded-0">
                <div className="accordion-body px-0 pb-0 border-0">
                  <div className="infobox1">
                    <div className="infobox1-item">
                      <p className="tit">대표이사</p>
                      <p className="desc">오명재</p>
                    </div>
                    <div className="infobox1-item">
                      <p className="tit">주소</p>
                      <p className="desc">
                      (48548) 부산광역시 남구 전포대로 133 Wework <br />
                      BIFC 15층 106호 (주)플렉스데이
                      </p>
                    </div>
                    <div className="infobox1-item">
                      <p className="tit">사업자등록번호</p>
                      <p className="desc">654-86-02546</p>
                    </div>
                    <div className="infobox1-item">
                      <p className="tit">통신판매업번호</p>
                      <p className="desc">제 2022-부산남구-0756호</p>
                    </div>                     
                  </div>
                  <div className="infobox2">
                    <p className="call-tit">고객센터</p>
                    <p className="call-num">051-621-0203</p>
                    <p className="call-desc">
                    (평일 10:00-18:00, 점심시간 12:30-13:30)
                    </p>
                    <p className="call-mail mb-0">
                      support@flex.day
                    </p>
                  </div>
                                     
                </div>
              </div>
            </div>
          </div>
          <div className="infobox3">
            <ul className="infobox3-nav">
              <li>
                서비스 이용약관
                <Serviceterms />
              </li>
              <li>
                개인정보처리방침
                <Userterms2 />
              </li>
            </ul>
            {/* <p className="infobox3-txt">
              (주)플렉스데이는 통신판매중개자로서 통신판매 당사자가 아니며,
              <br className='Mob320' /> 
              상품 구매와 이용 및 환불 등과 관련한 의무와 책임은 발행자에게 있습니다.
            </p> */}
          </div>   
        </div>
      </div>
    </>
  );
}
