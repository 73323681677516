import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import {
  AlertError,
  getApiUrl,
  getNomalDayz,
  getWeekDay2,
} from "../../function/common";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./WeekMenu.css";
import { Spinner } from "react-bootstrap";

export default function WeekMenu() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [weekday, setWeekday] = useState();

  const [weekmenu1, setWeekmenu1] = useState();
  const [weekmenu2, setWeekmenu2] = useState();
  const [weekmenu3, setWeekmenu3] = useState();
  const [weekmenu4, setWeekmenu4] = useState();
  const [weekmenu5, setWeekmenu5] = useState();

  function getWeekMenu(idx, day) {
    return new Promise(async function (resolve, reject) {
      var obj = {
        domain: localStorage.getItem("defaultStore"),
        date: day,
      };
      console.log(obj);
      const response = await axios
        .get(
          getApiUrl(
            "v1/stores/" +
              localStorage.getItem("defaultStore") +
              "/products-diet"
          ),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            if (idx === 1) {
              setWeekmenu1(response.data);
            } else if (idx === 2) {
              setWeekmenu2(response.data);
            } else if (idx === 3) {
              setWeekmenu3(response.data);
            } else if (idx === 4) {
              setWeekmenu4(response.data);
            } else if (idx === 5) {
              setWeekmenu5(response.data);
              setLoading(false);
            }
          }
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  useEffect(() => {
    // localStorage.removeItem('accessToken');
    //데이터 로딩
    // setLoading(true);

    let TmpNomalDayz = getNomalDayz();
    setWeekday(TmpNomalDayz);

    getWeekMenu(1, TmpNomalDayz[0]);
    getWeekMenu(2, TmpNomalDayz[1]);
    getWeekMenu(3, TmpNomalDayz[2]);
    getWeekMenu(4, TmpNomalDayz[3]);
    getWeekMenu(5, TmpNomalDayz[4]);

    // setLoading(false);
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TWeekMenuPage typeB">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">이번주 식단</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="WeekCateTab">
              <div
                className="nav nav-pills justify-content-around"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link active WeekCateBtn"
                    id="pills-content1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content1"
                    type="button"
                    role="tab"
                  >
                    <span>{weekday && getWeekDay2(weekday[0])}</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link WeekCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content2"
                    type="button"
                    role="tab"
                  >
                    <span>{weekday && getWeekDay2(weekday[1])}</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link WeekCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content3"
                    type="button"
                    role="tab"
                  >
                    <span>{weekday && getWeekDay2(weekday[2])}</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link WeekCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content4"
                    type="button"
                    role="tab"
                  >
                    <span>{weekday && getWeekDay2(weekday[3])}</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link WeekCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content5"
                    type="button"
                    role="tab"
                  >
                    <span>{weekday && getWeekDay2(weekday[4])}</span>
                  </button>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-content1"
                  role="tabpanel"
                >
                  <div className="WeekMenuBox">
                    {weekmenu1 && weekmenu1.length > 0 ? (
                      weekmenu1 &&
                      weekmenu1.map((row) => (
                        <div className="WeekMenu-Item d-flex">
                          <div className="prd-infobox">
                            <p className="prd-tit">{row.productName}</p>
                            <p
                              className="prd-desc"
                              style={{ "white-space": "pre-line" }}
                            >
                              {row.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="WeekMenu-Blank">
                        <img
                          className="img-fluid no_itemImg"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                        />
                        <p className="no_txt mb-0">등록된 식단이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane" id="pills-content2" role="tabpanel">
                  <div className="WeekMenuBox">
                    {weekmenu2 && weekmenu2.length > 0 ? (
                      weekmenu2 &&
                      weekmenu2.map((row) => (
                        <div className="WeekMenu-Item d-flex">
                          <div className="prd-infobox">
                            <p className="prd-tit">{row.productName}</p>
                            <p
                              className="prd-desc"
                              style={{ "white-space": "pre-line" }}
                            >
                              {row.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="WeekMenu-Blank">
                        <img
                          className="img-fluid no_itemImg"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                        />
                        <p className="no_txt mb-0">등록된 식단이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane" id="pills-content3" role="tabpanel">
                  <div className="WeekMenuBox">
                    {weekmenu3 && weekmenu3.length > 0 ? (
                      weekmenu3 &&
                      weekmenu3.map((row) => (
                        <div className="WeekMenu-Item d-flex">
                          <div className="prd-infobox">
                            <p className="prd-tit">{row.productName}</p>
                            <p
                              className="prd-desc"
                              style={{ "white-space": "pre-line" }}
                            >
                              {row.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="WeekMenu-Blank">
                        <img
                          className="img-fluid no_itemImg"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                        />
                        <p className="no_txt mb-0">등록된 식단이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane" id="pills-content4" role="tabpanel">
                  <div className="WeekMenuBox">
                    {weekmenu4 && weekmenu4.length > 0 ? (
                      weekmenu4 &&
                      weekmenu4.map((row) => (
                        <div className="WeekMenu-Item d-flex">
                          <div className="prd-infobox">
                            <p className="prd-tit">{row.productName}</p>
                            <p
                              className="prd-desc"
                              style={{ "white-space": "pre-line" }}
                            >
                              {row.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="WeekMenu-Blank">
                        <img
                          className="img-fluid no_itemImg"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                        />
                        <p className="no_txt mb-0">등록된 식단이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tab-pane" id="pills-content5" role="tabpanel">
                  <div className="WeekMenuBox">
                    {weekmenu5 && weekmenu5.length > 0 ? (
                      weekmenu5 &&
                      weekmenu5.map((row) => (
                        <div className="WeekMenu-Item d-flex">
                          <div className="prd-infobox">
                            <p className="prd-tit">{row.productName}</p>
                            <p
                              className="prd-desc"
                              style={{ "white-space": "pre-line" }}
                            >
                              {row.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="WeekMenu-Blank">
                        <img
                          className="img-fluid no_itemImg"
                          alt=""
                          src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                        />
                        <p className="no_txt mb-0">등록된 식단이 없습니다.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
