
import { useState, useEffect } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';

import "./Failure.css";
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';

export default function Failure(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    const [searchParams, setSearchParams]=useSearchParams();

    function PgResult() {
        return new Promise( async function(resolve, reject) {
            let obj = { 
                "paymentKey"            : searchParams.get('paymentKey'),
                "amount"                : searchParams.get('amount'),
                "orderNumber"           : localStorage.getItem('orderId')
            }
            console.log(obj);
            await axios.post( 
                getApiUrl('v1/orders/success'),
                obj, 
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json;"
            ).then( 
                function(response){
                    if(process.env.NODE_ENV !== 'production'){
                        console.log(response.data);
                    }

                    if(response.status === 200){
                        setData(response.data);

                        localStorage.removeItem('storeDomain');
                        localStorage.removeItem('products');
                        localStorage.removeItem('orderId');
                        
                        //데이터 로딩 완료
                        setLoading(false);
                    }else{
                        setLoading(false);
                    }
                }
            ).catch(function(error){
                AlertError(error);
                setLoading(false);
            })
        });
    };

    useEffect( () => {
        //데이터 로딩
        setLoading(true);
        
        // PgResult();

        setLoading(false);
    },[])

    if (loading) return <></>

  
    
    return (
        <>
        <div className='demo'>

             <div className="TPaymentFailurePage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">결제 실패</p>
                        <button type="button" className="btn-clear btn-close" onClick={()=>{navigate(-1)}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                        </button>
                    </nav>

                    <div className="FailureBox Inner">
                        <div className="FailureBox-title my-5 text-center">
                            <img className="img-fluid mb-4" src="https://www.flexdaycdn.net/public/images/ticket/failure_img.png"   alt="" />
                            <p className="PageTit2 mb-5">
                                주문 결제가 <br/>
                                정상처리 되지 않았습니다.
                            </p>
                            <div className="PageSubBox">
                                <p className="info-txt">
                                    • 주문내역과 결제수단을 확인 후, <br/>
                                    재시도 해보시기 바랍니다.
                                </p>
                                <p className="info-txt mb-0">
                                    • 지속적으로 결제가 이루어지지않는 경우,
                                    <br />
                                    고객센터(051-621-0203)로 문의 주시기 바랍니다.
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className="fixed-bottom px-4-5 pt-3 pb-4">
                        <div className="CancelConfirm d-flex justify-content-between">
                            <Link to={"/storemain"} className="btn btn-default">
                                홈으로 이동
                            </Link>
                        </div>
                    </div>


                </div>
            </div>

        </div>

        </>
    )
}