import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { getApiUrl, PrintConsole, sleep } from '../../function/common';

import axios from 'axios';


import "./QR.css";
import { useSearchParams } from 'react-router-dom';

export default function QR(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [searchParams, setSearchParams]=useSearchParams();

    const { number } = useParams();
    
    const [qr, setQR] = useState();

    function getQRCode() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                isUsed : true
            }
            const response = await axios.get( 
                getApiUrl('v1/coupons/'+ number),
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setQR(response.data);
                        // getQRImg();
                    }
                    setLoading(false);
                }
            ).catch(function(error){
                console.log('error');
            })
        });
    };

    const [qrImg, setQRImg] = useState();
    // let image = null;
    function getQRImg(){
        return new Promise( async function(resolve, reject) {
            const response = await axios.get( 
                "https://dev-api.flexday.kr/common/qrcode?data="+(qr && qr.qrcode)+"&size=380",
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        
                        // try {

                        //     image = ('data:image/jpeg;base64,' + btoa(
                        //        new Uint8Array(response.data).reduce((data, byte) => data + 
                        //        String.fromCharCode(byte), '')
                        //     ));

                        //     console.log(image);
                    
                        // } catch (err) {
                    
                        //     console.log(err)
                    
                        // }
                        // setQRImg(response.data);
                        // console.log(response.data);
                    }
                    setLoading(false);
                }
            ).catch(function(error){
                console.log('error');
            })
        });
        
    }

    

    useEffect( () => {
        getQRCode();
    },[])

    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

    //개발자 콘솔에서 반응형또는 기기 선택시 해당 os 반환
    // getVersion();
    // setAccessToken();

    // console.log(qr && qr.status.code);
    if(qr && qr.status.code === 6){
        return (
            <>
                <div className='demo'>

                    {/* class "unuse" 추가 */}
                    <div className="TUseQRPage unuse">
                        <div className="container-fluid g-0">

                            <nav className="sub-navbar">
                                <p className="navbar-title">QR 사용하기</p>
                                <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                                    <img className="img-fluid" alt="뒤로가기"
                                        src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                                </button>
                            </nav>

                            <div className="QRBox px-4-5 pb-4">
                                <div className="QRBox-inner">
                                    <div className="QRInfotxt2 text-center">
                                        <img className="img-fluid" src="https://www.flexdaycdn.net/public/images/ticket/info-icon.png" />
                                        가격이 인상됨에 따라 해당 식권은 사용이 불가능합니다.
                                        사용하지 못하신 구매 식권은 일괄적으로 취소 처리됩니다.
                                        추가 문의사항은 고객센터를 이용 바랍니다.

                                        <div className="call">
                                            고객센터 : 051-621-0203 <br/>
                                            (평일 10:00 ~ 18:00, 
                                            <br className="mob"/>점심시간 12:30 ~ 13:30)
                                        </div>
                                    </div> 
                                    <div className="infobox px-4-5">
                                        <div className="infoitem-date text-end">
                                            유효기간 2023-01-12
                                            {/* 유효기간 {item && item.expireDate} */}
                                        </div>
                                        <div className="infoitem">
                                            <div className="tit">
                                                상품
                                            </div>
                                            <div className="desc">
                                                C코너 / 1개
                                                {/* {item && item.productName} */}
                                            </div>
                                        </div>
                                        <div className="infoitem">
                                            <div className="tit">
                                                매장
                                            </div>
                                            <div className="desc">
                                                인제대학교 늘빛관(다인)
                                                {/* {item && item.storeName} */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="imgbox text-center unuse">
                                        {/* <img className="img-fluid m-auto qr-img"
                                            src="https://www.flexdaycdn.net/public/images/ticket/qr_img.png" /> */}
                                        <img className="img-fluid m-auto qr-img"
                                            src='https://www.flexdaycdn.net/public/images/ticket/QR_code.png' />
                                            <div className="unuse-txt">사용<br/>불가</div>
                                    </div>

                                    
                                    <div className="QRBox-bg">
                                        <img className="img-fluid w-100"
                                            src="https://www.flexdaycdn.net/public/images/ticket/QR_use_bg4.png" />
                                    </div>

                                </div>                    
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }else{
        return (
            <>
            <div className='demo'>

                <div className="TUseQRPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">QR 사용하기</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => {navigate(-1)}}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                                    
                            </button>
                        </nav>

                        <div className="QRBox px-4-5 pb-4">
                            <div className="QRBox-inner">
                                <div className="infobox px-4-5">
                                    <div className="infoitem-date text-end">
                                        유효기간 {qr && qr.expireDate}
                                    </div>
                                    <div className="infoitem">
                                        <div className="tit">
                                            상품
                                        </div>
                                        <div className="desc">
                                            {qr && qr.productName}
                                        </div>
                                    </div>
                                    <div className="infoitem">
                                        <div className="tit">
                                            매장
                                        </div>
                                        <div className="desc">
                                            {qr && qr.storeName}
                                        </div>
                                    </div>
                                </div>

                                <div className="imgbox text-center">
                                    <img className="img-fluid m-auto qr-img"
                                        src={process.env.REACT_APP_API+"common/qrcode?data="+(qr && qr.qrcode)+"&size=380"} />
                                </div>

                                <div className="QRInfotxt text-center">
                                    구매한 날짜에 사용하지 못하셨다면 <br />
                                    유효기간 내 같은 금액의 식단으로 <br className="mob" />
                                    사용이 가능합니다.
                                </div>  
                                
                                <div className="QRBox-bg">
                                    <img className="img-fluid w-100"
                                        src="https://www.flexdaycdn.net/public/images/ticket/QR_use_bg4.png" />
                                </div>

                            </div>                    
                        </div>
                    </div>
                </div>

            </div>

            </>
        )
    }
}