
import { Button, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';



import Modal from 'react-bootstrap/Modal';
import "./NonMemOrder.css";
import "./OrderStatus.css";

import axios from 'axios';
import { AlertError, getApiUrl } from '../../function/common';
import { useRef } from 'react';

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function NonMemOrder(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    // 모달
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [step , setStep] = useState('STEP1');
    
    const tel = useRef();
    const { number } = useParams();
    const [qr, setQR] = useState();

    function isPassed30Min(time){
        if(!time){
            return false;
        }
        const before30min = new Date();
        before30min.setMinutes(before30min.getMinutes()-30);
        if(new Date(time)< before30min){
            return true;
        }else{
            return false;
        }
    }

    const smartOrder = async (e) => {
        let obj = {
            'qrcode'    : number
        };
        await axios.post(
            getApiUrl('v1/coupons-sorder/non-user'),
            obj,
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                if(response.status === 200){
                    // window.location.reload(false);
                    // setStep('STEP3');
                    getQRCode();
                }
            }
        ).catch(function(error){
            AlertError(error);
        })
    }
    function loadQRCode(){
        tel.current = document.getElementById('Tel').value;
        getQRCode();
    }
    function getQRCode() {
        return new Promise( async function(resolve, reject) {
            var obj = {
                phoneNumber : tel.current
            }
            
            const response = await axios.post( 
                getApiUrl('v1/coupons-sorder/'+ number + '/non-user'),
                obj,
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setQR(response.data);
                        if(show === true){
                            handleClose();
                        }

                        if(response.data.smartOrderStatus !== null){
                            if(response.data.smartOrderStatus.code ==5 && isPassed30Min(response.data.cancelledAt)){
                                setStep('STEP2');
                            }else{
                                setStep('STEP3');
                            }
                        }else{
                            setStep('STEP2');
                        }
                        
                    }
                }
            ).catch(function(error){
                handleShow();
                console.log('error');
            })
        });
    };
   

    useEffect( () => {
        //데이터 로딩
        // setLoading(true);

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

    
    if ( step === 'STEP1'){

        return (
            <>
            <div className='demo'>

                <div className="TNonMembersPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">
                                식권
                            </p>                    
                        </nav>

                        <div className="NonMemberBox Inner">
                            <div className="NonMemberBox-title text-center">

                                <img className="img-fluid tit-img" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/non_member_img.png" />

                                <p className="tit mb-2">
                                    본인 확인을 위해 <br />
                                    <span className="fw-bold">연락처 끝 번호 4자리</span>를 입력해주세요.
                                </p>
                                <p className="sub">
                                    예시) 010-****-<span className="color">0000</span>
                                </p>
                            </div>

                            <div className="NonMemberBox-Input mb-4">
                                <input type="number" pattern="\d*" className="InputType form-control mb-3 px-3"
                                    placeholder="연락처 끝 번호 4자리를 입력해주세요." id='Tel' />
                                <button type="button" className="btn btn-default" onClick={ ()=>{loadQRCode();} }>
                                    입력
                                </button>
                            </div>
                        </div>

                    </div>

                    {/* 비회원 식권 확인Modal */}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        keyboard={false}
                        size="sm"
                        centered
                        className="demoModal modal-prdAdd text-center"
                        >
                        <Modal.Header>
                            <Modal.Title>
                                연락처 끝 번호 4자리가 <br />
                                일치하지 않습니다.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            번호를 확인하신 후 다시 입력해주세요.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="modal-primary" className='btn-clear w-100' onClick={handleClose}>
                                확인
                            </Button>
                        </Modal.Footer>
                    </Modal>   

                </div>

            </div>
            </>
        )

    }else if(step === "STEP2"){
        return (
            <>
            <div className='demo'>

                <div className="TNonMemOrderPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">비회원 식권 주문</p>                   
                        </nav>

                        <div className="NonMemOrderBox px-4-5">

                            <div className="title_box text-center">
                                <img className="img-fluid mb-4"
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_smile.png" alt="" />
                            </div>

                            <div className="info_box">
                                <div className="info_p">
                                    주문 시 매장에서 바로 조리가 들어가므로 <br/>
                                    주문 취소가 불가능 합니다
                                </div>
                                <div className="info_p">
                                    메뉴가 준비되면 카카오 알림톡으로 안내 해드립니다
                                </div>
                                <div className="info_p">
                                    픽업 알림을 받으시면 , 메뉴는 다솜식당에서 픽업 해주세요
                                </div>

                                <button type="button" className="btn btn-default btn-order" onClick={() => (smartOrder())}>
                                    주문하기
                                </button>
                            </div>


                            <div className="my_order">
                                <div className="sub_tit">
                                    내 주문
                                </div>
                                <div className="order_list">
                                    <div className="order_item">
                                        <p className="prd_name">
                                            {qr && qr.productName} / 1개
                                        </p>
                                        <p className="store_name mb-0">
                                            {qr && qr.storeName}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>   

                </div>

            </div>
            </>
        )
    }else{
        return (
            <>
            <div className='demo'>

                <div className="TOrderStatusPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">비회원 식권 주문</p>                   
                        </nav>
                        <div className={qr.smartOrderStatus && qr.smartOrderStatus.code === 5?"OrderStatusBox px-4-5 d-none": "OrderStatusBox px-4-5"}>
                            <div className="title_box text-center">
                                <img className="img-fluid mb-4" src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_smile.png" alt="" />
                                <p className="tit">주문이 접수되었습니다</p>
                                <p className="sub">주문번호 : {qr && qr.orderNumber}</p>
                            </div>

                            <div className="status_box">
                                <p className="sub_tit">주문 현황</p>

                                {
                                    qr.smartOrderStatus && qr.smartOrderStatus.code === 1 ?
                                
                                        <div className="status_list state1">
                                            <div className="status_items">
                                                <div className="status_item step1 ">
                                                    <div className="status_circle"></div>
                                                    <div className="status_tit">수락 대기</div>
                                                    <div className="status_desc">주방에서 주문을 확인 중입니다</div>
                                                </div>
                                                <div className="status_item step2">
                                                    <div className="status_circle"></div>
                                                    <div className="status_tit">조리중</div>
                                                    <div className="status_desc">주방에서 주문을 조리 중입니다</div>
                                                </div>
                                                <div className="status_item step3">
                                                    <div className="status_circle"></div>
                                                    <div className="status_tit">픽업 요청</div>
                                                    <div className="status_desc">
                                                        메뉴 준비가 완료되었습니다 <br />
                                                        {qr && qr.diningTable} 배식구에서 메뉴를 찾아가세요
                                                    </div>
                                                </div>
                                                <div className="status_item step4">
                                                    <div className="status_circle"></div>
                                                    <div className="status_tit">픽업 완료</div>
                                                    <div className="status_desc">
                                                        {qr && qr.diningTable} 배식구에서 픽업을 완료하셨습니다 <br />
                                                        맛있게 드세요!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    :
                                        qr.smartOrderStatus && qr.smartOrderStatus.code === 2 ?
                                    
                                            <div className="status_list state2">
                                                <div className="status_items">
                                                    <div className="status_item step1 ">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">수락 대기</div>
                                                        <div className="status_desc">주방에서 주문을 확인 중입니다</div>
                                                    </div>
                                                    <div className="status_item step2">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">조리중</div>
                                                        <div className="status_desc">주방에서 주문을 조리 중입니다</div>
                                                    </div>
                                                    <div className="status_item step3">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 요청</div>
                                                        <div className="status_desc">
                                                            메뉴 준비가 완료되었습니다 <br />
                                                            {qr && qr.diningTable} 배식구에서 메뉴를 찾아가세요
                                                        </div>
                                                    </div>
                                                    <div className="status_item step4">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 완료</div>
                                                        <div className="status_desc">
                                                            {qr && qr.diningTable} 배식구에서 픽업을 완료하셨습니다 <br />
                                                            맛있게 드세요!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        :

                                        qr.smartOrderStatus && qr.smartOrderStatus.code === 3 ?
                                    
                                            <div className="status_list state3">
                                                <div className="status_items">
                                                    <div className="status_item step1 ">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">수락 대기</div>
                                                        <div className="status_desc">주방에서 주문을 확인 중입니다</div>
                                                    </div>
                                                    <div className="status_item step2">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">조리중</div>
                                                        <div className="status_desc">주방에서 주문을 조리 중입니다</div>
                                                    </div>
                                                    <div className="status_item step3">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 요청</div>
                                                        <div className="status_desc">
                                                            메뉴 준비가 완료되었습니다 <br />
                                                            {qr && qr.diningTable} 배식구에서 메뉴를 찾아가세요
                                                        </div>
                                                    </div>
                                                    <div className="status_item step4">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 완료</div>
                                                        <div className="status_desc">
                                                            {qr && qr.diningTable} 배식구에서 픽업을 완료하셨습니다 <br />
                                                            맛있게 드세요!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            :

                                            <div className="status_list state4">
                                                <div className="status_items">
                                                    <div className="status_item step1 ">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">수락 대기</div>
                                                        <div className="status_desc">주방에서 주문을 확인 중입니다</div>
                                                    </div>
                                                    <div className="status_item step2">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">조리중</div>
                                                        <div className="status_desc">주방에서 주문을 조리 중입니다</div>
                                                    </div>
                                                    <div className="status_item step3">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 요청</div>
                                                        <div className="status_desc">
                                                            메뉴 준비가 완료되었습니다 <br />
                                                            {qr && qr.diningTable} 배식구에서 메뉴를 찾아가세요
                                                        </div>
                                                    </div>
                                                    <div className="status_item step4">
                                                        <div className="status_circle"></div>
                                                        <div className="status_tit">픽업 완료</div>
                                                        <div className="status_desc">
                                                            {qr && qr.diningTable} 배식구에서 픽업을 완료하셨습니다 <br />
                                                            맛있게 드세요!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                }

                            </div>

                            <div className="my_order">
                                <div className="sub_tit">내 주문</div>
                                <div className="order_list">
                                    <div className="order_item">
                                        <p className="prd_name">{qr && qr.productName} / 1개</p>
                                        <p className="store_name mb-0">{qr && qr.storeName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={qr&& qr.smartOrderStatus && qr.smartOrderStatus.code === 5?"OrderStatusBox px-4-5": "OrderStatusBox px-4-5 d-none"}>
                            <div className="title_box text-center">
                                <img className="img-fluid mb-4"
                                     src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_sad.png" alt="" />
                                <p className="tit">
                                    주문이 취소되었습니다
                                </p>
                                <p className="sub">
                                    주문번호 : {qr.smartOrderStatus && qr.orderNumber}
                                </p>
                            </div>

                            <div className="status_box">
                                <p className="sub_tit">
                                    주문 현황
                                </p>
                                {/*
                                    상태별 class name
                                    cancel : 주문 취소 / 페이지 별도
                                */}
                                <div className="status_list cancel">
                                    <div className="status_items">
                                        <div className="status_item step1">
                                            <div className="status_circle"></div>
                                            <div className="status_tit">
                                                수락 대기
                                            </div>
                                            <div className="status_desc">
                                                주방에서 주문을 확인 중입니다
                                            </div>
                                        </div>
                                        <div className="status_item step5">
                                            <div className="status_circle"></div>
                                            <div className="status_tit">
                                                주문 취소
                                            </div>
                                            <div className="status_desc">
                                                주방에서 주문을 취소하였습니다 <br/>
                                                취소 사유 : {qr && qr.cancelReason}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="my_order">
                                <div className="sub_tit">
                                    내 주문
                                </div>
                                <div className="order_list">
                                    <div className="order_item">
                                        <p className="prd_name">
                                            {qr.smartOrderStatus && qr.productName} / 1개
                                        </p>
                                        <p className="store_name mb-0">
                                            {qr.smartOrderStatus && qr.storeName}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}
