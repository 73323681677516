import React from "react";

import { Col, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

import {
  AlertError,
  getApiUrl,
  getNomalDayz,
  getWalletTitle,
  getWeekDayz,
  PrintConsole,
  setCommas,
  sleep,
} from "../../function/common";
import { GetUserMe } from "../../api/ApiGetStoreUser";
import { GetAuthorize } from "../../api/ApiGetUser";
import { setAccessToken, setNav3 } from "../../api/ApiWebView";

import Header from "./components/Header";
import "./QRwallet.css";
import { getWeek } from "date-fns";

export default function QRwallet() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  if (localStorage.getItem("storeType") === "SMART_ORDER") {
    navigate("/twallet");
  }

  const [useProduct, setUseProduct] = useState();
  function getUseWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "USED",
      };

      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "USED",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }

      const response = await axios
        .get(
          getApiUrl("v1/coupons"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setUseProduct(response.data);
            // console.log(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  const [notUseProduct, setNotUseProduct] = useState();
  function getNotUseWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "NOT_USED",
      };

      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "NOT_USED",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }

      const response = await axios
        .get(
          getApiUrl("v1/coupons"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setNotUseProduct(response.data);
            // console.log(response.data);
          }
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  const [giftProduct, setGiftProduct] = useState();
  function getGiftWallet() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        couponStatus: "NOT_USED",
        isGift: "true",
      };
      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          couponStatus: "NOT_USED",
          isGift: "true",
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }
      const response = await axios
        .get(
          getApiUrl("v1/coupons"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setGiftProduct(response.data);
            // console.log(response.data);
          }
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  useEffect(() => {
    getNotUseWallet();
    getGiftWallet();
    getUseWallet();

    window.scrollTo(0, 0);
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TQRwalletPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">{getWalletTitle()}</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="PayCateTab">
              <div
                className="nav nav-pills justify-content-around mb-4-5"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link active PayCateBtn"
                    id="pills-content1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content1"
                    type="button"
                    role="tab"
                    aria-controls="pills-content1"
                    aria-selected="true"
                  >
                    <span>
                      사용 가능 QR({notUseProduct && notUseProduct.totalCount})
                    </span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link PayCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content2"
                    type="button"
                    role="tab"
                    aria-controls="pills-content2"
                    aria-selected="false"
                  >
                    <span>
                      사용 완료 QR(
                      {useProduct && useProduct.totalCount <= 99
                        ? useProduct.totalCount
                        : "99+"}
                      )
                    </span>
                  </button>
                </div>
              </div>
              <div className="tab-content pb-5" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-content1"
                  role="tabpanel"
                  aria-labelledby="pills-content1-tab"
                >
                  <div className="PayPrdList px-4-5">
                    {notUseProduct &&
                      notUseProduct.data.map((row) => (
                        <div className="PayListItem unuse">
                          <div className=" infobox d-flex justify-content-between">
                            <div className="InfoboxTxt">
                              <div className="txtbox">
                                <Row>
                                  <Col xs={8}>
                                    <p className="prd-name mb-0">
                                      {row.productName}
                                    </p>
                                    <p className="store-name">
                                      {row.storeName}
                                    </p>
                                  </Col>
                                  <Col className="text-end">
                                    <p className="store-name mt-2">
                                      {setCommas(row.facePrice) + "원"}
                                    </p>
                                  </Col>
                                </Row>
                                {row.buyerId !== row.ownerId ? (
                                  <img
                                    className="img-fluid prd-stamp"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/QR_ticket_stamp3.png"
                                  />
                                ) : (
                                  <></>
                                )}
                              </div>
                              {row.buyerId === row.ownerId ? (
                                <Link
                                  to={"/gift/" + row.qrcode}
                                  className="giftbox"
                                >
                                  <img
                                    className="img-fluid gift"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/gift_icon.png"
                                  />
                                  <span>선물하기</span>
                                  <img
                                    className="img-fluid arrow"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/more_info.png"
                                  />
                                </Link>
                              ) : (
                                <div className="ungiftbox"></div>
                              )}
                            </div>
                            <Link to={"/qr/" + row.qrcode} className="QRBtn">
                              <button
                                type="type"
                                className="btn btn-clear text-white"
                              >
                                <img
                                  className="img-fluid"
                                  alt=""
                                  src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png"
                                />
                                QR사용
                              </button>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="PayPrdList gift px-4-5">
                    {giftProduct &&
                      giftProduct.data.map((row) => (
                        <>
                          <div className="giftTxt">
                            <img
                              className="img-fluid img"
                              alt=""
                              src="https://www.flexdaycdn.net/public/images/ticket_ver2/light.png"
                            />
                            {row.buyerName} 님으로부터 받은 선물입니다.
                          </div>

                          <div className="PayListItem">
                            <div className=" infobox d-flex justify-content-between">
                              <div className="InfoboxTxt">
                                <div className="txtbox">
                                  <Row>
                                    <Col xs={8}>
                                      <p className="prd-name mb-0">
                                        {row.productName}
                                      </p>
                                      <p className="store-name">
                                        {row.storeName}
                                      </p>
                                    </Col>
                                    <Col className="text-end">
                                      <p className="store-name mt-2">
                                        {setCommas(row.facePrice) + "원"}
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="ungiftbox"></div>
                              </div>
                              <div className="QRBtn">
                                <Link to={"/qr/" + row.qrcode}>
                                  <button
                                    type="type"
                                    className="btn btn-clear text-white"
                                  >
                                    <img
                                      className="img-fluid"
                                      alt=""
                                      src="https://www.flexdaycdn.net/public/images/ticket/qr_icon.png"
                                    />
                                    QR사용
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>

                <div
                  className="tab-pane"
                  id="pills-content2"
                  role="tabpanel"
                  aria-labelledby="pills-content2-tab"
                >
                  <div className="PayPrdList px-4-5">
                    {useProduct &&
                      useProduct.data.map((row) =>
                        row.useServey === true ? (
                          <div className="PayListItem used">
                            <div className=" infobox d-flex justify-content-between">
                              <div className="InfoboxTxt">
                                <div className="txtbox">
                                  <p className="prd-date">
                                    사용일시 {row.usedAt}
                                  </p>
                                  <p className="prd-name mb-0">
                                    {row.productName}
                                  </p>
                                  <p className="store-name">{row.storeName}</p>
                                  <img
                                    className="img-fluid prd-stamp"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/QR_ticket_stamp2.png"
                                  />
                                </div>
                              </div>
                              <Link
                                to={"/survey/" + row.qrcode}
                                className="QRBtn"
                                disabled
                              >
                                <button
                                  type="type"
                                  className="btn btn-clear text-white"
                                >
                                  만족도 <br />
                                  조사
                                </button>
                              </Link>
                            </div>
                          </div>
                        ) : (
                          <div className="PayListItem used survey-done">
                            <div className=" infobox d-flex justify-content-between">
                              <div className="InfoboxTxt">
                                <div className="txtbox">
                                  <p className="prd-date">
                                    사용일시 {row.usedAt}
                                  </p>
                                  <p className="prd-name mb-0">
                                    {row.productName}
                                  </p>
                                  <p className="store-name">{row.storeName}</p>
                                  <img
                                    className="img-fluid prd-stamp"
                                    alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/QR_ticket_stamp2.png"
                                  />
                                </div>
                              </div>
                              <Link
                                to={"/survey/" + row.qrcode}
                                className="QRBtn"
                                disabled
                              >
                                <button
                                  type="type"
                                  className="btn btn-clear text-white"
                                >
                                  만족도 <br />
                                  조사
                                </button>
                              </Link>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
