import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import { Spinner, Button } from "react-bootstrap";

import Header from "./components/Header";
import "./Logininfo.css";
import { AlertError, getApiUrl } from "../../function/common";

import Modal from "react-bootstrap/Modal";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY;

export default function Logininfo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // 모달
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [radio0, setRadio0] = useState(false);
  const radioClick0 = (e) => {
    if (e.target.checked) {
      setRadio0(e.target.checked);
    } else {
      setRadio0("");
    }
  };
  const [radio1, setRadio1] = useState(false);
  const radioClick1 = (e) => {
    if (e.target.checked) {
      setRadio1(e.target.checked);
    } else {
      setRadio1("");
    }
  };
  const [radio2, setRadio2] = useState(false);
  const radioClick2 = (e) => {
    if (e.target.checked) {
      setRadio2(e.target.checked);
    } else {
      setRadio2("");
    }
  };

  const [user, setUser] = useState();
  function getUser() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/users/me"),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setUser(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          AlertError(error);
          localStorage.clear();
          navigate("/");
        });
    });
  }

  function userDelete() {
    // var retVal = window.confirm("회원탈퇴를 진행하시겠습니까?");

    // if( retVal === true ){
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .delete(
          getApiUrl("v1/users/me"),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            localStorage.clear();
            navigate("/");
          }
        })
        .catch(function (error) {
          console.log(error);
          // AlertError(error);
        });
    });
    // }
  }

  function setMaketing() {
    let a = document.getElementById("Check-05").checked;
    let b = document.getElementById("Check-06-01").checked;
    let c = document.getElementById("Check-06-02").checked;

    var variables = {
      marketingConsent: a,
      appPush: b,
      sms: c,
    };

    // console.log(variables);

    //서비스 호출
    axios
      .put(getApiUrl("v1/users/me/marketing-settings"), variables, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          // localStorage.setItem('defaultStore', store);
          window.location.reload();
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  }

  function AllCheckSub() {
    let CheckInput2 = document.querySelectorAll("input[name=Check2]");
    for (var i = 0; i < CheckInput2.length; i++) {
      CheckInput2[i].checked =
        document.querySelector("input.AllCheckSub").checked;
    }

    setRadio1(true);
    setRadio2(true);
  }

  function AllCheckSub_items() {
    let Total = document.querySelectorAll("input[name=Check2]").length;
    let Checked = document.querySelectorAll(
      "input[name=Check2]:checked"
    ).length;

    if (Total !== Checked) {
      document.querySelector("input.AllCheckSub").checked = false;
    } else {
      document.querySelector("input.AllCheckSub").checked = true;
    }
  }

  useEffect(() => {
    getUser();

    console.log(user && user.eventAlarmSettings[0].accept);
    if (user && user.eventAlarmSettings[0].accept === true) {
      setRadio1(user.eventAlarmSettings[0].accept);
    }
  }, []);

  useEffect(() => {
    if (user && user.eventAlarmSettings[0].accept === true) {
      setRadio1(user.eventAlarmSettings[0].accept);
    }
    if (user && user.eventAlarmSettings[1].accept === true) {
      setRadio2(user.eventAlarmSettings[1].accept);
    }
  }, [user]);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TLogininfoPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">내 정보</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="LoginInfoTab">
              <div
                className="nav nav-pills justify-content-around"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link active LoginInfoBtn"
                    id="pills-content1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content1"
                    type="button"
                    role="tab"
                  >
                    <span>로그인 정보</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link LoginInfoBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content2"
                    type="button"
                    role="tab"
                  >
                    <span>마케팅 동의 설정</span>
                  </button>
                </div>
              </div>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-content1"
                  role="tabpanel"
                >
                  <div className="LogininfoTxt">
                    <img
                      className="img-fluid icon"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/ticket_ver2/logininfo_icon.png"
                    />
                    <p className="txt mb-0">
                      회원님은 현재 '{user && user.oauthType}'로 간편 로그인
                      되어있습니다.
                    </p>
                  </div>
                  <div className="LogininfoBox px-4-5 my-4-5">
                    <div className="InputboxWrap">
                      <div className="info-txt">
                        {user && user.oauthType}
                        {user && user.oauthType === "NAVER" ? (
                          <div className="imgbox naver">
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png"
                              alt="naver"
                              className="img-fluid sns-icon"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {user && user.oauthType === "APPLE" ? (
                          <div className="imgbox apple">
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/apple_icon.png"
                              alt="apple"
                              className="img-fluid sns-icon"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {user && user.oauthType === "KAKAO" ? (
                          <div className="imgbox kakao">
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png"
                              alt="kakao"
                              className="img-fluid sns-icon"
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="Logininfo-Inputbox mb-4">
                        <p className="input-tit">이름</p>
                        <input
                          type="text"
                          className="form-control InputType"
                          readonly
                          value={user && user.name}
                        />
                      </div>
                      <div className="Logininfo-Inputbox mb-4">
                        <p className="input-tit">연락처</p>
                        <input
                          type="text"
                          className="form-control InputType"
                          readonly
                          value={user && user.phoneNumber}
                        />
                      </div>
                      <div className="Logininfo-Inputbox">
                        <p className="input-tit">이메일</p>
                        <input
                          type="text"
                          className="form-control InputType"
                          readonly
                          value={user && user.email}
                        />
                      </div>

                      <div className="LogininfoConfirm">
                        <button
                          onClick={() => {
                            handleShow2();
                          }}
                          className="btn btn-default"
                        >
                          회원탈퇴
                        </button>
                        <p className="txt text-center">
                          회원 탈퇴가 진행되면 기존에 정보는 복구가 불가합니다.
                        </p>
                        <p style={{ color: "#d3d3d3", textAlign: "center" }}>
                          {process.env.REACT_APP_VERSION}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-pane" id="pills-content2" role="tabpanel">
                  <div className="LoginMarketing">
                    <div className="marketing-check">
                      <div
                        className="form-check d-flex align-items-center p-0"
                        onClick={AllCheckSub}
                      >
                        <input
                          className="form-check-input AllCheckSub CheckInput2"
                          name="Check"
                          id="Check-05"
                          type="checkbox"
                          checked={radio0 === true}
                          onChange={radioClick0}
                        />
                        <label
                          className="form-check-label CheckLabel"
                          htmlFor="Check-05"
                        >
                          (선택) 마케팅 정보 수신 동의
                        </label>
                      </div>
                      <div className="SignUp-Infobox">
                        <p className="SignUp-checkbox-desc">
                          플렉스데이에서 제공하는 각종 혜택 및 이벤트,{" "}
                          <br className="Mob320" />
                          사은품에 대한 안내를 받을 수 있습니다.
                        </p>
                        <div className="form-check-group">
                          <div
                            className="form-check d-flex align-items-center p-0"
                            onClick={AllCheckSub_items}
                          >
                            <input
                              className="form-check-input CheckInput3"
                              id="Check-06-01"
                              type="checkbox"
                              value=""
                              name="Check2"
                              checked={radio1 === true}
                              onChange={radioClick1}
                            />
                            <label
                              className="form-check-label CheckLabel"
                              htmlFor="Check-06-01"
                            >
                              앱푸시
                            </label>
                          </div>
                          <div
                            className="form-check d-flex align-items-center p-0"
                            onClick={AllCheckSub_items}
                          >
                            <input
                              className="form-check-input CheckInput3"
                              id="Check-06-02"
                              type="checkbox"
                              value="true"
                              name="Check2"
                              checked={radio2 === true}
                              onChange={radioClick2}
                            />
                            <label
                              className="form-check-label CheckLabel"
                              htmlFor="Check-06-02"
                            >
                              문자 (MMS, 카카오 알림톡)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="LogininfoConfirm">
                      <button className="btn btn-default" onClick={handleShow3}>
                        저장
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
                    <div className="LogininfoBox px-4-5 my-4-5">

                        <div className="InputboxWrap">
                            <p className="tit">
                                회원님은 현재 '{user && user.oauthType}'로 간편 로그인 되어있습니다.
                            </p>
                            <p className="info-txt">
                                {user && user.oauthType} 제공 정보
                            </p>
                            
                            
                        </div>

                    </div> */}

        {/* </div>
            </div>

            </div> */}

        <Modal
          show={show2}
          onHide={handleClose2}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>회원탈퇴</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            회원탈퇴를 진행하시겠습니까? <br />
            회원탈퇴를 진행할시 데이터를 복구 할 수 없습니다.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="modal-secondary"
              className="btn-clear"
              onClick={() => {
                userDelete();
              }}
            >
              회원탈퇴
            </Button>
            <a
              href="javascript:void(0)"
              onClick={handleClose2}
              className="btn btn-clear btn-modal-primary"
            >
              닫기
            </a>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show3}
          onHide={handleClose3}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-loginInfo text-center"
        >
          <Modal.Header>
            <Modal.Title className="lh-sm">
              “ {year}년 {month}월 {day}일 ” <br />
              마케팅 정보 수신 동의 내용이 <br />
              변경되었습니다.
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="modal-primary"
              className="btn btn-clear w-100"
              onClick={() => setMaketing()}
            >
              확인
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
