
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Serviceterms from './components/ServiceTerms';
import Userterms from './components/Userterms';
import Userterms2 from './components/Userterms2';
import ConsignmentTerms from './components/ConsignmentTerms';


import './Register.css';


export default function Register(){
    const navigate = useNavigate();

    const [isCheckEssential, setisCheckEssential] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([
        {'id':'c1'}, {'id':'c2'},{'id':'c3'},{'id':'c4'},{'id':'c5'},{'id':'c5-1'}, {'id':'c5-2'}
    ]);


    const [isCheckAllSub, setIsCheckAllSub] = useState(false);
    const [isCheckSub, setIsCheckSub] = useState([]);
    const [listSub, setListSub] = useState([
        {'id':'c1'}, {'id':'c2'},{'id':'c3'},{'id':'c4'},{'id':'c5'},{'id':'c5-1'}, {'id':'c5-2'}
    ]);


    useEffect( () => {
        // localStorage.removeItem('accessToken');

        if( localStorage.getItem('accessToken') !== undefined && localStorage.getItem('accessToken') !== null ){
            //토큰 있을때 추가 작업할 일 있을 시 사용
            window.location.href='/storemain';
        }else{
            // navigate('/user/search');
        }

        // try{
        //     setNav3();
        // }catch(e){}
    })

    const handleSelectAllSub = e => {

        let Total = document.querySelectorAll("input[name=Check]").length;
        let Checked = document.querySelectorAll("input[name=Check]:checked").length;

        setIsCheckAllSub(!isCheckAllSub);       
        
        setIsCheckSub(listSub.map(li => li.id));
        if (isCheckAllSub) {
            setIsCheckSub([]);
            setIsCheckAll(false);
        } else if(!isCheckAllSub && Total == Checked ) {
            setIsCheckAll(true);
        } else{
            
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheckAllSub(!isCheckAllSub);
        
        setIsCheck(list.map(li => li.id));
        setIsCheckSub(listSub.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            setIsCheckSub([]);
            setIsCheckAllSub(false);
            setisCheckEssential(false);
        } else{
            setIsCheckAllSub(true);
            setisCheckEssential(true);
        }
    };

    const handleClickSub = e => {
        const { id, checked } = e.target;
        setIsCheckSub([...isCheckSub, id]);

        let c1 = document.getElementById('c1');
        // let c2 = document.getElementById('c2');
        let c3 = document.getElementById('c3');
        // let c4 = document.getElementById('c4');
        // let c5 = document.getElementById('c5');
        let c5_1 = document.getElementById('c5-1');
        let c5_2 = document.getElementById('c5-2');

        if (!checked) {
            setIsCheckSub(isCheckSub.filter(item => item !== id));
        }

        if (c1.checked && c3.checked && c5_1.checked && c5_2.checked){
            setIsCheckAll(true);
            setIsCheckAllSub(true);
        } else if (c5_1.checked && c5_2.checked){
            setIsCheckAllSub(true);
        } else {           
            setIsCheckAll(false);
            setIsCheckAllSub(false);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        let c1 = document.getElementById('c1');
        // let c2 = document.getElementById('c2');
        let c3 = document.getElementById('c3');
        // let c4 = document.getElementById('c4');
        // let c5 = document.getElementById('c5');
        let c5_1 = document.getElementById('c5-1');
        let c5_2 = document.getElementById('c5-2');

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }

        if (c1.checked && c3.checked && c5_1.checked && c5_2.checked){
            setisCheckEssential(true);
            setIsCheckAll(true);
        } else {           
            setisCheckEssential(false);
            setIsCheckAll(false);
        }
    };


    function RequiredCheck() {
        let Total = document.querySelectorAll("input:required").length;
        let Checked = document.querySelectorAll("input:required:checked").length;
        const element = document.querySelector('.AlertBox');

        if (Total != Checked) {
            document.querySelector(".AlertBox").classList.add("active");
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            document.querySelector(".AlertBox").classList.remove("active");
        }
    }




    
    return (
        <>
            <div className='demo'>
                <div className="TRegisterPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">회원가입</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => navigate(-1)}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                            </button>
                        </nav>

                        <div className="SignUpBox pb-4-5">
                            <div className="SignUp-title text-center">
                                <div className="Inner">
                                    <p className="tit mb-1 d-flex justify-content-center">
                                        반가워요
                                        <img className="img-fluid ms-1"
                                            src="https://www.flexdaycdn.net/public/images/ticket/emoji_smile.png" alt="" />
                                    </p>
                                    <p className="sub mb-0">
                                        약관에 동의해주시면 회원가입이 진행됩니다.
                                    </p>
                                </div>
                            </div>

                            <div className="SignUp-Contents Inner">
                                <div className="accordion ver2">
                                    <div className="accordion-item">
                                        <p className="accordion-headerd d-flex align-items-center">
                                            <input className="form-check-input CheckInput1 me-0 AllCheck" name="hobbyAll" id='all' type="checkbox" onChange={handleSelectAll} checked={isCheckAll}/>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                플렉스데이 이용약관 전체동의
                                            </button>
                                        </p>
                                        <div id="collapseOne" className="accordion-collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body Input-placeholderbox">
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c1"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c1')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c1">
                                                        (필수) 서비스 이용약관
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Serviceterms />
                                                    </a>
                                                </div>
                                                {/* <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c2"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c2')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c2">
                                                        (필수) 개인정보 수집 및 이용 동의서
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Userterms />
                                                    </a>
                                                </div> */}
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c3"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c3')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c3">
                                                        (필수) 개인정보 처리 방침
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Userterms2 />
                                                    </a>
                                                </div>
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input AllCheckSub CheckInput2" name="Check" id="c5"
                                                        type="checkbox"  onChange={handleSelectAllSub} checked={isCheckAllSub} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c5">
                                                        (선택) 마케팅 정보 수신 동의
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Infobox Inner">
                                <p className="SignUp-checkbox-desc">
                                    플렉스데이에서 제공하는 각종 혜택 및 이벤트, <br className="Mob320" />
                                    사은품에 대한 안내를 받을 수 있습니다.
                                </p>
                                <div className="form-check-group g-0 row flex-wrap">
                                    <div className="form-check col-4 d-flex align-items-center p-0">
                                        <input className="form-check-input CheckInput3" id="c5-1" type="checkbox" name="Check2" onChange={handleClickSub} checked={isCheckSub.includes('c5-1')}/>
                                        <label className="form-check-label CheckLabel" htmlFor="c5-1">
                                            앱푸시
                                        </label>
                                    </div>
                                    <div className="form-check col-4 d-flex align-items-center p-0">
                                        <input className="form-check-input CheckInput3" id="c5-2" type="checkbox" name="Check2" onChange={handleClickSub} checked={isCheckSub.includes('c5-2')}/>
                                        <label className="form-check-label CheckLabel" htmlFor="c5-2">
                                            문자
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Confirm Inner pt-4-5">
                                <button type="button" className="btn btn-default" onClick={RequiredCheck}>회원가입</button>
                            </div>
                            <div className="AlertBox Inner mt-3">
                                필수 약관에 동의해주세요.
                            </div>
                        </div>


                    </div>
                </div>                
            </div>
        </>

    )
}