import {useNavigate} from "react-router-dom";

export default function OrderStatusCard({smartOrderStatus}) {
    const navigate = useNavigate();

    function getCss(code){
        if(code==1){
            return 'state';
        }else if(code==2){
            return 'state on';
        } else if(code==3){
            return 'state pickup';
        } else if(code==4){
            return 'state done';
        } else if(code==5){
            return 'state cancel';
        }
    }
    return (
        <div className={getCss(smartOrderStatus.code)}>
            <div className="dot"></div>
            {smartOrderStatus.description}
        </div>
    )

}