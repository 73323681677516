import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function Header(props) {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const activeClass = (route) => {
    return window.location.pathname === route
      ? "active nav-link d-flex align-items-center pb-4"
      : "nav-link d-flex align-items-center pb-4";
  };

  return (
    <>
      <button
        className="Navbar-Toggler position-absolute top-50 translate-middle-y end-0 me-4"
        type="button"
        onClick={handleShow}
      >
        {/* <button className="Navbar-Toggler" type="button" onClick={handleShow}> */}
        <img
          className="img-fluid"
          alt="메뉴"
          src="https://www.flexdaycdn.net/public/images/ticket/menu3.png"
        />
      </button>

      <Offcanvas
        className="demo"
        id="offcanvasNavbar"
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <div className="offcanvas-btnbox pt-4 px-4 text-end">
          <button
            type="button"
            className="btn-close opacity-100 shadow-none"
            onClick={handleClose}
          >
            <img
              className="img-fluid close"
              alt=""
              src="https://www.flexdaycdn.net/public/images/ticket/close4.png"
            />
          </button>
        </div>
        <Offcanvas.Header className="p-4">
          <img
            className="img-fluid logo"
            alt=""
            src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
          />
          <Offcanvas.Title id="offcanvasNavbarLabel">
            <span className="small">반가워요 :) </span>
            {localStorage.getItem("userName") + " 님"}
            <Link to={"/logininfo"}>
              {/* kakao icon */}
              {localStorage.getItem("oauthType") === "KAKAO" ? (
                <div className="imgbox kakao">
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png"
                    alt="kakao"
                    className="img-fluid sns-icon"
                  />
                </div>
              ) : (
                <></>
              )}

              {/* naver icon */}
              {localStorage.getItem("oauthType") === "NAVER" ? (
                <div className="imgbox naver">
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png"
                    alt="naver"
                    className="img-fluid sns-icon"
                  />
                </div>
              ) : (
                <></>
              )}

              {/* apple icon */}
              {localStorage.getItem("oauthType") === "APPLE" ? (
                <div className="imgbox apple">
                  <img
                    src="https://www.flexdaycdn.net/public/images/ticket/apple_icon3.png"
                    alt="apple"
                    className="img-fluid sns-icon"
                  />
                </div>
              ) : (
                <></>
              )}

              {/* non-member icon */}
              {
                // localStorage.getItem('oauthType') === 'NONE' ?
                //   <div className="imgbox non-member">
                //     <img src="https://www.flexdaycdn.net/public/images/ticket/non-member-icon2.png" alt="non-member" className="img-fluid sns-icon" />
                //   </div>
                // :
                //   <></>
              }
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        {localStorage.getItem("defaultStore") !== "dasom" ? (
          <div className="offcanvas-weekmenu">
            <Link to={"/weekmenu"}>
              <img
                src="https://www.flexdaycdn.net/public/images/ticket_ver2/week_txt2.png"
                className="img-fluid txt"
                alt=""
              />
              <img
                src="https://www.flexdaycdn.net/public/images/ticket_ver2/week_arrow2.png"
                className="img-fluid arrow"
                alt=""
              />
            </Link>
          </div>
        ) : (
          <></>
        )}
        <Offcanvas.Body className="position-relative py-4-5">
          {localStorage.getItem("oauthType") === "NONE" ? (
            <div className="navbar-nav justify-content-end"></div>
          ) : (
            <>
              <div className="navbar-nav justify-content-end">
                <div className="nav-item">
                  <Link to={"/storemain"} className={activeClass("/storemain")}>
                    <span>메인</span>
                  </Link>
                </div>
                {localStorage.getItem("defaultStore") !== "dasom" ? (
                  <div className="nav-item">
                    <Link to={"/qrwallet"} className={activeClass("/qrwallet")}>
                      <span>QR 지갑</span>
                    </Link>
                  </div>
                ) : (
                  <div className="nav-item">
                    <Link to={"/qrwallet"} className={activeClass("/twallet")}>
                      <span>식권 지갑</span>
                    </Link>
                  </div>
                )}
                <div className="nav-item">
                  <Link
                    to={"/paymentlist"}
                    className={activeClass("/paymentlist")}
                  >
                    <span>결제 내역</span>
                  </Link>
                </div>
                <div className="nav-item">
                  <Link to={"/giftlist"} className={activeClass("/giftlist")}>
                    <span>선물 내역</span>
                  </Link>
                </div>
                <div className="nav-item">
                  <Link
                    to={"/stampreward"}
                    className={activeClass("/stampreward")}
                  >
                    <span>스탬프 리워드</span>
                  </Link>
                </div>
                {/* <div className="nav-item">
                  <Link to={"/event"} className={activeClass("/event")}>
                    <span>이벤트</span>
                  </Link>
                </div>                                        */}
              </div>
            </>
          )}

          <div className="btnbox">
            <a
              href="tel:0516210203"
              type="button"
              className="btn btn-light mb-2 shadow-none"
            >
              고객센터 051-621-0203
            </a>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => {
                localStorage.clear();
                navigate("/login");
              }}
            >
              로그아웃
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
