import { Image, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { useEffect, useRef } from "react";
import { useState } from "react";

import { loadTossPayments } from "@tosspayments/payment-sdk";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import { AlertError, getApiUrl, PrintConsole } from "../../function/common";
import axios from "axios";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./StampReward.css";
import RewordStamp from "./components/RewordStamp";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY;

export default function StampReward() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [stamp, setStamp] = useState();
  const event = useRef([]);

  var [ary, setAry] = useState([]);

  function getEvent() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl(
            "v1/stores/" + localStorage.getItem("defaultStore") + "/events/last"
          ),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setStamp(response.data);
            // console.log(response.data.eventStampRules.length);
            let TmpAry = [];
            for (
              let i = 0;
              i <
              response.data.eventStampRules[
                response.data.eventStampRules.length - 1
              ].stampCount;
              i++
            ) {
              TmpAry.push(
                "<div className='stamp-step'>" +
                  "<div className='before'>" +
                  (i + 1) +
                  "</div>" +
                  " <div className='after'>" +
                  "<img src='https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png' />" +
                  "</div>" +
                  "</div></div>"
              );
            }

            // console.log(TmpAry);
            setAry(TmpAry);
            // console.log(ary);
            setLoading(false);
          }
          //데이터 로딩 완료
        })
        .catch(function (error) {
          // AlertError(error);
          console.log(error);
          setLoading(false);
        });
    });
  }

  function getRewardTitle() {
    if (localStorage.getItem("defaultStore") === "inje") {
      return "A, B, C코너";
    } else if (localStorage.getItem("defaultStore") === "paik") {
      return "A코너";
    } else {
      return "메뉴 주문 후";
    }
  }

  // 모달
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getEvent();

    // console.log(ary.length);
    if (ary.length <= 0) {
      handleShow();
    }
  }, []);

  useEffect(() => {
    // console.log(ary.length);
  }, [ary]);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  if (ary.length <= 0) {
    return (
      <>
        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>현재 운영 중인 이벤트가 없습니다.</Modal.Title>
          </Modal.Header>
          <Modal.Body></Modal.Body>
          <Modal.Footer>
            <Button
              variant="modal-secondary"
              className="btn btn-clear btn-modal-primary w-100"
              onClick={() => {
                navigate("/storemain");
              }}
            >
              확인
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="demo">
        <div className="TStampRewardPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">스탬프 리워드</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="StampReward-Top text-center mb-4-5 px-4-5">
              <p className="CWDangamAsac stamp-badge">
                STAMP <br />
                REWORD
              </p>
              <p className="gMarket stamp-desc">
                <span class="gMarket bold">{getRewardTitle()}</span> 이용 후
                <br />
                만족도 조사 참여 시,
                <br className="mob-320" />{" "}
                <span className="gMarket bold">스탬프 1개</span> 적립
              </p>
              {/* <p className="gMarket bold stamp-tit">
                            나의 스탬프 리워드
                        </p> */}
              {/* <p className="gMarket stamp-desc">
                            <span className="gMarket bold">
                                {
                                    localStorage.getItem('defaultStore') === 'inje' ?
                                    "A, B, C코너" : "A코너"
                                }
                                
                            </span> 메뉴 한 개 이용 후
                            <br />
                            만족도 조사 참여 시, 
                            <br className="mob-320" /> <span className="gMarket bold">스탬프 1개
                            </span> 적립
                        </p> */}
              <p className="gMarket stamp-date">
                {stamp && stamp.startDate} - {stamp && stamp.endDate}
              </p>
              <div className="stamp-stateBox">
                {stamp &&
                  stamp.eventStampRules.map((row) => (
                    <div className="stamp-state d-flex">
                      <div className="gMarket tit">{row.reward}</div>
                      <div className="gMarket num">
                        <span className="color">
                          {stamp && stamp.countOfStamp <= row.stampCount
                            ? stamp && stamp.countOfStamp
                            : row.stampCount}
                        </span>{" "}
                        / {row.stampCount}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="StampReward-Bottom text-center mb-4-5 px-4-5">
              <p className="gMarket bold stamp-badge">스탬프 적립 현황</p>
              <div className="stamp-stepBox">
                <div className="stamp-stepRow row1 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 0 && idx <= 3 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {ary.length > 4 ? (
                  <div className="stamp-stepRow-Line line1">
                    <div className="stamp-line"></div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="stamp-stepRow row2 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 4 && idx <= 6 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {ary.length > 7 ? (
                  <div className="stamp-stepRow-Line line2">
                    <div className="stamp-line"></div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="stamp-stepRow row1 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 7 && idx <= 10 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {ary.length > 11 ? (
                  <div className="stamp-stepRow-Line line1">
                    <div className="stamp-line"></div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="stamp-stepRow row2 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 11 && idx <= 13 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {ary.length > 14 ? (
                  <div className="stamp-stepRow-Line line2">
                    <div className="stamp-line"></div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="stamp-stepRow row1 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 14 && idx <= 17 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {ary.length > 18 ? (
                  <div className="stamp-stepRow-Line line1">
                    <div className="stamp-line"></div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="stamp-stepRow row2 d-flex">
                  {ary &&
                    ary.map((row, idx) =>
                      idx >= 18 && idx <= 19 ? (
                        <RewordStamp
                          idx={idx}
                          countOfStamp={stamp && stamp.countOfStamp}
                          eventStampRules={stamp && stamp.eventStampRules}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  <div className="stamp-step-dump"></div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
