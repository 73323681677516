import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import './NonUserLogin.css';

import Serviceterms from './components/ServiceTerms';
import Userterms from './components/Userterms';
import Userterms2 from './components/Userterms2';
import ConsignmentTerms from './components/ConsignmentTerms';

import { AlertError, getApiUrl, getRedirctUrl } from '../../function/common';
import axios from 'axios';

export default function NonUserLogin(){

    const navigate = useNavigate();

    const [isCheckEssential, setisCheckEssential] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([
        {'id':'c1'}, {'id':'c2'},{'id':'c3'},{'id':'c4'},{'id':'c5'},{'id':'c6'},{'id':'c7'},{'id':'c8'},{'id':'c9'},{'id':'c10'}
    ]);
   
    const [account, setAccount] = useState({
        Id: "",
        Password: "",
        clientId: '10000'
    });

    const onChangeAccount = (e) => {
        //console.log(e.target.value);
        setAccount({
          ...account,
          [e.target.id]: e.target.value,
        });
    };

    // 나이스
    const [tokenVersionId, setTokenVersionId]    = useState();
    const [encData       , setEncData]           = useState();
    const [integrityValue, setIntegrityValue]    = useState();
    const [validation, setValidation] = useState({
        Nice             : false
    });

    const Nice = async (e) => {
        let obj = { }
        obj = { 
            'popupyn' : 'N',
            'redirectUrl' : getRedirctUrl('storemain'),
            'customData' : JSON.stringify(isCheck)
        }

        // obj = { 
        //     'popupyn' : 'N',
        //     'redirectUrl' : getRedirctUrl('storemain'),
        //     'customData' : "A"
        // }
        
        await axios.post(
            getApiUrl('v1/auth/self'),
            obj, 
            axios.defaults.headers.common['ContentType'] = "application/json;"
        ).then(
            function(response){
                // console.log(response);
                if(response.status === 200){
                    setTokenVersionId(response.data.token_version_id);
                    setEncData(response.data.enc_data);
                    setIntegrityValue(response.data.integrity_value);

                    // let obj = {
                    //     'token_version_id'    : response.data.token_version_id,
                    //     'enc_data'            : response.data.enc_data ,
                    //     'integrity_value'     : response.data.integrity_value
                    // };
                    // axios.post(
                    //     getApiUrl('v1/auth/self/result'),
                    //     obj, 
                    //     axios.defaults.headers.common['ContentType'] = "application/json;"
                    // ).then(
                    //     function(response){
                    //         if(response.status === 200){
                    //             localStorage.setItem('accessToken', response.data.loginToken.accessToken);
                    //         }
                    //     }
                    // ).catch(function(error){
                    //     // AlertError(error);
                    //     console.log(error);
                    // })
                    
                }else{
                    setValidation({...validation, Nice : false});
                }
            }
        ).catch(function(error){
            setValidation({...validation, Nice : false});
        })
    }
    useEffect( () => {
        if(integrityValue !== null && integrityValue !== '' && integrityValue !== undefined){
            document.getElementById('NiceID').submit();
        }
    },[integrityValue])

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            setisCheckEssential(false);
        }else{
            setisCheckEssential(true);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        let c1 = document.getElementById('c1');
        // let c2 = document.getElementById('c2');
        let c3 = document.getElementById('c3');
        // let c4 = document.getElementById('c4');

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }

        // if (c1.checked && c2.checked && c3.checked && c4.checked){
        if (c1.checked && c3.checked) {
            setisCheckEssential(true);
            setIsCheckAll(true);
        } else{
            setisCheckEssential(false);
            setIsCheckAll(false);
        }
    };

    function RequiredCheck() {
        let Total = document.querySelectorAll("input:required").length;
        let Checked = document.querySelectorAll("input:required:checked").length;
        const element = document.querySelector('.AlertBox');

        if (Total !== Checked) {
            document.querySelector(".AlertBox").classList.add("active");
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            document.querySelector(".AlertBox").classList.remove("active");
            Nice();
        }
    }


    return (
        <>
            <div className='demo'>
                <div className="TLogin2Page">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">비회원으로 시작하기</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => navigate(-1)}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                            </button>
                        </nav>

                        <div className="SignUpBox pb-4-5">
                            <div className="SignUp-title">
                                <div className="Inner">
                                    <p className="tit mb-1 d-flex">
                                        반가워요
                                        <img className="img-fluid ms-1"
                                            src="https://www.flexdaycdn.net/public/images/ticket/emoji_smile.png" alt="" />
                                    </p>
                                    <p className="sub mb-0">
                                        회원에게만 제공되는 다양한 기능을 <br />
                                        SNS 간편 로그인 한 번으로 편리하게 이용해보세요.
                                    </p>

                                    <div className="SignUp-Confirm py-4-5">
                                        <button type="button" className="btn btn-default" onClick={() => navigate("/login") }>간편 회원가입</button>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-title border-bottom-0">
                                <div className="Inner">
                                    <p className="sub mb-0">
                                        회원가입을 하지 않아도 서비스 이용이 가능합니다.
                                        <br/>
                                        아래 약관 동의 후 본인 인증을 해주세요.
                                    </p>
                                    <div className="sub-contents">
                                        <p className="sub mb-1">
                                            - 금융실명거래 및 비밀보장에 관한 법률에 따라
                                            본인인증을 받고 있습니다.
                                        </p>
                                        <p className="sub  mb-0">
                                            - 간편 회원가입을 통해 쉽고 빠른 이용이 가능합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Contents Inner">
                                <div className="accordion ver2">
                                    <div className="accordion-item">
                                        <p className="accordion-headerd d-flex align-items-center">
                                            <input className="form-check-input CheckInput1 me-0 AllCheck" name="hobbyAll" id='all' type="checkbox" onChange={handleSelectAll} checked={isCheckAll}/>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                플렉스데이 이용약관 전체동의
                                            </button>
                                        </p>
                                        <div id="collapseOne" className="accordion-collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body Input-placeholderbox">
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c1"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c1')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c1">
                                                        (필수) 서비스 이용약관 
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Serviceterms />
                                                    </a>
                                                </div>
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c3"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c3')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c3">
                                                        (필수) 개인정보 처리 방침
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Userterms2 />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Confirm Inner pt-4-5">
                                <button type="button" className="btn btn-default" onClick={ () => {RequiredCheck(); }}>비회원 본인인증</button>                           
                                <div className="AlertBox Inner mt-3">
                                    필수 약관에 동의해주세요.
                                </div>
                            </div>

                            <form action='https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb' id='NiceID' method="post">
                                <input type='hidden' name='m' value='checkplusSerivce'/>
                                <input type='hidden' id='token_version_id' name='token_version_id' value={tokenVersionId || ''} onChange={ (e) => (setTokenVersionId(e.target.value))}/>
                                <input type='hidden' id='enc_data' name='enc_data' value={encData || ''} onChange={ (e) => (setEncData(e.target.value))}/>
                                <input type='hidden' id='integrity_value' name='integrity_value' value={integrityValue || ''} onChange={ (e) => (setIntegrityValue(e.target.value))}/>
                            </form>

                        </div>


                    </div>
                </div>

                
            </div>
        </>

    )
}