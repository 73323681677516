import Modal from "react-bootstrap/Modal";
import { useState } from "react";


export default function Serviceterms() {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow() {
    setFullscreen("lg-down");
    setShow(true);
  }

  return (
    <>
    <span onClick={() => handleShow()}>보기</span>
      <Modal className="demo-modal" show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-start lh-sm">
              주식회사 플렉스데이
              <br />
              서비스 이용약관
              <br /><small>제정 2022.05.10.</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p className="title">제 1장 총칙</p>

            <div className="txtbox">
              <p className="sub-title">제1조 (목적)</p>
              <p className="contents">
                이 약관은 주식회사 플렉스데이(이하 “회사”라 함)가 제공하는 플렉스데이 서비스의 이용과 관련하여 회사와 회원과의 권리 의무 및 제반절차를 확정하고 이를 이행함으로써 상호 발전을 도모하는 것을 그 목적으로 합니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제2조 (정의)</p>
              <p className="contents">
                이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <br/><br/>
                1.	“플렉스데이 서비스”(이하 “서비스”라 함)라 함은 “회원”이 교환권을 구매하여 수신자에게 MMS, SMS, 카카오톡 등의 방법으로 전송하는 서비스를 말합니다. 
                <br/>
                2.	“회원”이라 함은 이 약관에 따라 회사와의 이용계약을 체결하여 정보를 제공하고 회사로부터 이용 승낙을 받은 자를 말합니다.
                <br/>
                3.	“판매자”라 함은 "회사가 제공하는 중개판매 서비스를 통하여 상품등을 판매할 목적으로 판매자 약관을 승인 하거나 회사와 서비스 이용계약을 체결한 회원을 말합니다.
                <br/>
                4.	“구매자"라 함은 판매자가 판매하는 상품 등을 구입할 의사를 회사가 온라인으로 제공하는 양식에 맞추어 밝힌 회원을 말합니다.
                <br/>
                5.	“직원”이라 함은 “회원”에게 서비스의 이용 권한 일부 또는 전부를 위임받아 서비스의 일부 또는 전부를 이용 또는 관리하는 자를 말합니다.
                <br/>
                6.	“교환권”이라 함은 교환권 내에 명시된 교환 조건에 따라 해당 상품으로 교환할 수 있는 쿠폰을 말합니다.
                <br/>
                7.	“이용자”라 함은 서비스에 접속하여 서비스를 이용하는 회원 및 비회원을 말합니다.
                <br/>
                8.	“수신자”는 회원으로부터 교환권 등을 선물을 받아 서비스를 이용하는자로써 모바일 교환권을 상품 등으로 교환할 수 있는 권리를 가집니다.
                <br/>
                9.	이 약관에 사용되는 용어 중 본 조에서 정하지 않은 부분은 관계법령 및 일반 관례에 따릅니다.
              </p>   
            </div>

            <div className="txtbox">
              <p className="sub-title">제3조 (약관의 효력 및 개정)</p>
              <p className="contents">
                1.	이 약관의 내용은 ‘서비스’를 이용하고자 하는 모든 고객에게 적용됩니다. 약관에 동의한 고객이 ‘회사’가 제공하는 ‘서비스’를 이용함으로써 효력이 발생합니다.
                <br/>
                2.	‘회사’는 이 약관의 내용과 상호 및 대표자 성명, 주소, 전화번호, 이메일, 사업자등록번호, 통신판매업신고번호, 개인정보처리방침 등을 회원이 쉽게 알 수 있도록 사이트의 초기 화면(전면) 하단부에 게시합니다. 다만, 이 약관과 개인정보처리방침의 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                <br/>
                3.	‘회사’는 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘약관의 규제에 관한 법률’, ‘전자거래기본법, ‘전자서명법’, 정보통신망 이용촉진 등에 관한 법률’, ‘소비자보호법’ 등의 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                <br/>
                4.	‘회사’가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 인터넷 사이트 초기화면에 그 적용일로부터 7일 이전에 사전 공지를 합니다. 다만, 회원에게 불리하게 약관을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 ‘회사’는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
                <br/>
                5.	‘회사’의 약관 개정 시 약관의 효력 발생일 이후 회원이 ‘서비스’를 이용하는 경우 회원이 약관에 동의한 것으로 보며 회원이 개정된 약관에 동의하지 않을 경우 ‘서비스’의 이용을 중단하고 이용계약을 해지할 수 있습니다.
                <br/>
                6.	‘회사’가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용됩니다. 단, ‘회사’가 지정하는 방식에 따라 회원이 동의한 경우 개정약관 조항이 적용됩니다.
                <br/>
                7.	이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘약관의 규제 등에 관한 법률’, 공정거래위원회가 정하는 ‘전자상거래 등에서의 소비자 보호지침’, 및 관계 법령 또는 상관례에 따릅니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제4조 (약관의 해석)</p>
              <p className="contents">
                1. ‘회사’는 추가적인 개별 ‘서비스’에 대하여 별도의 이용약관 및 정책을 둘 수 있습니다. 개별 ‘서비스’ 제공에 관하여는 해당 이용약관 및 정책이 우선하여 적용되며, 이 약관은 개별 이용약관 및 정책에 반하지 않는 범위 내에서 개별 ‘서비스’에 관하여도 적용됩니다.
                <br/>
                2.	개별 ‘서비스’에 관한 별도 이용약관 및 정책 또는 이 약관에 명시되지 않은 사항에 대해서는 대한민국 법률 및 정보통신망법, 개인정보보호법 등 관계 법령이 있는 경우 이에 따르고 그렇지 아니한 경우 상관례에 따릅니다.
              </p>              
            </div>

            <p className="title">제 2장 ‘서비스’ 이용 계약</p>

            <div className="txtbox">
              <p className="sub-title">제5조 (‘서비스’ 이용계약의 성립)</p>
              <p className="contents">
                1.	이용계약은 가입 희망자가 약관의 내용에 동의를 한 다음 ‘회사’가 정한 가입 양식에 회원 정보를 기입하고, 절차에 따라 가입 신청을 진행한 후 ‘회사’가 이러한 신청에 대하여 승낙함으로써 성립됩니다.
                <br/>
                2.	회사’는 가입 희망자의 신청에 대하여 ‘서비스’ 이용을 승낙함을 원칙으로 합니다. 다만, ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
                <br/>
                가)	가입 희망자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단, 자격 상실 이후 6개월 이상 경과한 자가 ‘회사’의 재가입 승낙을 얻은 경우에는 예외로 함
                <br/>
                나)	허위의 정보를 기재하거나 ‘회사’가 요청하는 회원 정보 등록 사항을 누락하거나 오기하여 신청하는 경우
                <br/>
                다)	타인의 명의를 이용하여 신청하는 경우
                <br/>
                라)	사회의 안녕질서 또는 미풍양속을 저해하거나 저해할 목적으로 신청한 경우
                <br/>
                마)	기술상 ‘서비스’ 제공이 불가능한 경우
                <br/>
                바)	가입 희망자의 귀책사유로 인하여 승인이 불가능한 경우
                <br/>
                사)	기타 규정한 제반 사항을 위반하거나 ‘회사’가 정한 이용 신청 요건이 만족되지 않았을 경우
                <br/>
                3.	본 조 제1항에 따른 신청에 있어 ‘회사’는 가입 희망자에게 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
                <br/>
                4.	회사’는 ‘서비스’ 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있어 정상적인 ‘서비스’ 제공이 어렵다고 판단되는 경우에는 승낙을 유보할 수 있으며, 가입 신청을 승낙하지 않거나 유보한 경우 ‘회사’는 원칙적으로 가입 희망자에게 이를 알리도록 노력합니다.
                <br/>
                5.	이용계약의 성립 시기는 ‘회사’가 가입 완료를 신청절차 상에서 표시하거나, 이 약관의 통지 방식에 따라 통지 결과가 회원에게 도달한 시점으로 합니다.
                <br/>
                6.	회사’는 회원에 대해 ‘회사’ 정책에 따라 등급별로 구분하여 ‘서비스’ 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제6조 (회원 정보의 변경)</p>
              <p className="contents">
                1.	회원은 정보관리 화면을 통해 언제든지 회원 정보를 열람할 수 있으며, 수정이 필요한 경우 ‘회사’가 정한 방법으로 정해진 양식 및 방법에 의하여 수정을 요청할 수 있습니다.
                <br/>
                2.	회원은 이용 신청 시 기재한 사항이 변경되었을 경우, ‘회사’가 정한 방법으로 정해진 양식 및 방법에 의하여 수정하여 최신의 상태를 유지해야 합니다.
                <br/>
                3.	회원 정보의 변경사항을 ‘회사’에 알리지 않아 발생한 불이익에 대하여 ‘회사’는 ‘회사’의 고의 또는 중대한 과실이 없는 이상 책임지지 않습니다.
              </p>              
            </div>

            <p className="title">제 3장 ‘서비스’ 이용</p>

            <div className="txtbox">
              <p className="sub-title">제7조 (‘서비스’ 이용 개시)</p>
              <p className="contents">
                1.	회사’는 회원의 이용 신청을 승낙한 때부터 ‘서비스’를 개시합니다. 단, 일부 ‘서비스’의 경우에는 지정된 일자부터 ‘서비스’를 개시합니다.
                <br/>
                2.	회사’의 업무상 또는 기술상의 장애로 인하여 ‘서비스’를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제8조 (‘서비스’의 제공 등)</p>
              <p className="contents">
                1.	서비스’는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
                <br/>
                2.	본 조 제1항에도 불구하고 ‘회사’는 ‘서비스’를 일정 범위로 분할하여 각 범위별로 이용가능시간을 별도 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.
                <br/>
                3.	회사’는 ‘회사’의 업무상이나 기술상(컴퓨터 등 정보통신설비의 보수 점검, 교체 및 고장, 장애, 통신두절 또는 운영상 상당히 필요한 경우)의 이유가 있는 경우 ‘서비스’의 제공을 일시적으로 중단할 수 있습니다. 이 경우 ‘회사’는 회원에게 정한 방법으로 통지합니다. 다만, ‘회사’가 사전에 통지할 수 없는 부득이한 사유가 있는 경우에는 사후 통지할 수 있습니다.
                <br/>
                4.	회사’는 ‘서비스’의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 ‘서비스’ 제공 화면에 공지한 바에 따릅니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제9조 (‘서비스’ 이용 요금 및 정산)</p>
              <p className="contents">
                1.	회사’는 시장의 상황, 업종의 특성, 품목의 특성, 거래방식, 내부사정 등을 고려하여 ‘회원’에게 서비스 제공에 대한 비용(이하 “서비스 이용료”라 함)의 항목을 정하고 신설 또는 변경할 수 있으며, ‘서비스 이용료’는 ‘사이트’를 통해 고지합니다.
                <br/>
                2.	회사’는 ‘서비스 이용료’ 이외, 추가적인 비용을 청구하지 않습니다.
                <br/>
                3.	회원’이 ‘서비스’를 이용한 대가로 ‘회사’에 지불해야 할 수수료의 정산기준 등은 ‘회사’가 별도 제공한 ‘사이트’ 에서 자세히 확인할 수 있습니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제10조 (‘서비스’의 변경 및 중지)</p>
              <p className="contents">
                1.	회사’는 안정적인 ‘서비스’ 제공을 위한 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 ‘서비스’를 변경할 수 있습니다.
                <br/>
                2.	회사’는 변경될 ‘서비스’의 내용 및 제공일자를 ‘회사’가 정한 방법으로 회원에게 통지하고, ‘서비스’를 변경하여 제공할 수 있습니다.
                <br/>
                3.	회사’는 다음 각 호에 해당하는 경우, ‘서비스’의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                <br/>

                1)	‘서비스’용 설비의 보수 등 공사로 인한 부득이한 경우<br/>
                2)	회원이 ‘회사’의 영업활동을 방해하는 경우<br/>
                3)	정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 ‘서비스’ 이용에 지장이 있는 경우<br/>
                4)	‘서비스’ 제공업자와의 계약 종료 등과 같은 ‘회사’의 제반 사정으로 ‘서비스’를 유지할 수 없는 경우<br/>
                5)	기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우<br/>

                4.	본 조 제3항에 의한 ‘서비스’ 중단이 발생한 경우, ‘회사’가 정한 방법으로 회원에게 통지합니다. 다만, ‘회사’가 통제할 수 없는 사유로
                인한 ‘서비스’의 중단(운영자의 고의, 과실이 없는 장애, 시스템다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
                <br/>
                5.	본 조 제3항에 의한 ‘서비스’ 중단으로 ‘회사’가 정한 방법으로 회원에게 통지한 후 회원의 동의를 얻고자 일정기간 동의 독려를 했음에도 불구하고 회원이 기간 내 통지에 대해 의사 표현이 없을 경우 ‘회사’는 ‘서비스’의 전부 또는 일부를 제한하거나 중지할 수 있습니다.
                <br/>
                6.	‘회사’는  ‘회사’의 고의 또는 중대한 과실이 없는 ‘서비스’의 변경, 중지로 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.
                <br/>
                7.	‘회사’는 무료로 제공되는 ‘서비스’의 일부 또는 전부를 ‘회사’의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제11조 (정보의 제공 및 광고의 게재)</p>
              <p className="contents">
                1.	‘회사’는 회원이 ‘서비스’ 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련 법에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
                <br/>
                2.	‘회사’는 마케팅 목적의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 회원의 사전 동의를 받은 후 전송해야 합니다. 다만, ‘서비스’와 관련한 거래관련 정보 및 고객문의 ‘서비스’ 운영에 대한 회신에 있어서는 제외됩니다.
                <br/>
                3.	회사’는 ‘서비스’ 운영과 관련하여 ‘서비스’ 화면, 홈페이지, 사이트, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 회원은 수신거부를 ‘회사’에 할 수 있습니다.
                <br/>
                4.	회원은 ‘회사’가 제공하는 ‘서비스’와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.
                <br/>
                5.	회원이 ‘서비스’ 상에 게재되어 있는 광고 또는 광고주의 판촉활동에 따라 광고주로부터 상품 등을 구매하는 경우 그러한 거래의 당사자는 회원과 광고주이며, ‘회사’는 거래의 당사자가 아닙니다. ‘회사’는 ‘서비스’ 상에 게재되어 있는 광고 또는 광고주의 판촉활동 내용이 적법하고 사실에 부합함을 보장하지 않습니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제12조 (게시물 또는 내용물의 관리)</p>
              <p className="contents">
                1.	회원은 ‘서비스’에 게시한 게시물이 타인의 저작권 등 권리를 침해하지 않도록 주의하여야 하고, 제3자가 게시물이 자신의 권리를 침해한다며 분쟁을 제기하는 경우 회원은 본인의 책임과 비용으로 해당 분쟁을 해결하며 ‘회사’를 면책하여야 합니다.
                <br/>
                2.	회원이 ‘서비스’에 게시하는 게시물은 ‘서비스’ 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, ‘회사’는 관련 법 규정을 준수하며, 회원은 언제든지 고객센터 또는 ‘서비스’ 내 관리 기능을 통해 해당 게시물에 대해 삭제, 비공개 등의 조치를 위할 수 있습니다.
                <br/>
                3.	회원의 게시물이 관련 법(“정보통신망법” 및 “저작권법” 등)에 위반되는 내용을 포함하는 경우, ‘회사’는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 요청이 반영되지 않을 경우 관련법에 따라 ‘회사’가 직접 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
                <br/>
                4.	‘회사’는 회원이 게시하거나 전달하는 ‘서비스’ 내의 모든 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전 통지 없이 삭제할 수 있으며, 이에 대해 ‘회사’는 어떠한 책임도 지지 않습니다.
                <br/>
                1)	‘회사’, ‘회원’ 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우
                <br/>
                2)	공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우, 범죄적 행위에 결부된다고 인정되는 내용인 경우
                <br/>
                3)	‘회사’의 저작권, 제3자의 저작권 등은 물론, 기타 권리를 침해하는 내용인 경우
                <br/>
                4)	‘회사’의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
                <br/>
                5)	‘회사’의 규정에 의한 게시기간을 초과한 경우
                <br/>
                6)	‘회사’에서 제공하는 ‘서비스’와 관련 없는 내용인 경우
                <br/>
                7)	불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
                <br/>
                8)	타인의 정보를 무단으로 도용하여 작성한 내용이거나, 타인이 입력한 정보를 무단으로 위변〮조한 내용인 경우
                <br/>

                5.	회원은 ‘회사’가 ‘서비스’를 통해 회원에게 제공하는 각종 자료 및 ‘서비스’ 등에 대한 일체의 내용을 제3자에게 제공하거나 외부에 유출할 수 없으며, 임의로 수정, 편집, 가공할 수 없습니다.
                <br/>
                6.	‘회사’는 게시물 또는 ‘서비스’ 내의 모든 내용물에 대한 관리 세부 절차는 관련 법이 규정한 범위 및 ‘회사’가 정한 ‘서비스’ 규정에 따라 각종 게시물을 등록하거나 삭제하여야 합니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제13조 (계약해지 및 이용제한)</p>
              <p className="contents">
                1.	회원은 ‘서비스’ 이용계약을 해지하고자 할 경우에 ‘회사’가 정한 별도의 이용방법으로 ‘회사’에 해지 및 정지 신청을 하여야 합니다.
                <br/>
                2.	회사’는 ‘회사’가 규정한 회원의 의무를 이행하지 않을 경우, 사전 통지 없이 즉시 이용계약을 해지하거나 또는 ‘서비스’ 이용을 중지할 수 있습니다.
                <br/>
                3.	회사’는 가입 후 ‘서비스’ 사용 이력이 1년 동안 없는 회원에 대해 ‘서비스’ 이용에 대한 의사가 없는 것으로 판단하고 ‘회사’가 정한 정책에 의해 해당 계정에 대한 별도 관리를 할 수 있습니다. 단, ‘회사’가 정한 정책에 따라 회원이 ‘서비스’ 이용 의사가 있을 경우 일시정지 상태로 이용에 제한을 둔 상태로 유지할 수 있습니다.
                <br/>
                4.	회원은 본 조 제2항, 제3항의 조치에 대하여 이의가 있을 경우, ‘회사’가 정한 절차에 따라 이의신청을 할 수 있습니다.
                <br/>
                5.	본 조 제4항의 이의가 정당하다고 ‘회사’가 인정하는 경우, ‘회사’는 회원이 ‘서비스’를 이용할 수 있도록 즉시 조치를 취하여야 합니다.
              </p>              
            </div>


            <p className="title">제 3장 교환권 구매 거래</p>

            <div className="txtbox">
              <p className="sub-title">제14조 (교환권 구매 거래의 성질과 목적)</p>
              <p className="contents">
                1.	회사는 판매자와 구매자 간 교환권 거래가 이루어질 수 있도록 온라인 거래 장소와 ‘서비스’를 제공합니다. 회사는 통신판매중개자로서 교환권 거래의 당사자가 아니며, 회사의 어떠한 행위도 판매자 또는 구매자를 대리하는 행위로 간주되지 않습니다.
                <br/>
                2.	판매자와 구매자 간의 거래 조건은 본 약관에서 별도로 정함이 없는 한 판매자가 정하여 구매자에 고지한 정책 또는 판매자와 구매자가 합의한 내용에 따르고, 회사는 거래의 당사자가 아니므로 이에 관여하지 않습니다.
                <br/>
                3.	해당 물품의 거래에 관하여 발생하는 분쟁, 법령상 규제 준수, 소비자 또는 구매자에게 발생하는 손해 등에 대해서는 달리 관련 법령에 정함이 없는 한 원칙적으로 해당 판매자가 책임을 부담하며 회사는 책임을 부담하지 않습니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제15조 (청약 철회)</p>
              <p className="contents">
                1.	교환권 구매자는 교환권을 구매한 날로부터 7일 이내에 청약을 철회할 수 있습니다. 다만, 판매자로부터 수령한 교환권이 계약의 내용과 일치하지 않을 경우에는 교환권을 구매한 날로부터 3개월 이내에, 일치하지 않는다는 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에 구매 청약을 철회할 수 있습니다.
                <br/>
                2.	다음의 경우에는 구매자의 본 조 제1항에 따른 청약의 철회가 제한됩니다.
                <br/>
                1)	교환권의 전부 또는 일부를 사용한 경우<br/>
                2)	기타 관련 법령에 따라 청약의 철회가 제한되는 경우<br/>
                3.	회사는 본조에 따라 판매자로부터 구매자가 청약을 철회한 사실을 통지 받은 후 3영업일 이내에 구매자가 결제한 교환권 대금을 구매자에게 환급하여야 합니다.
              </p>              
            </div>

            <div className="txtbox">
              <p className="sub-title">제16조(유효기간)</p>
              <p className="contents">
                1.	교환권의 유효기간은 원칙적으로 판매자가 정하는 바에 따르나, 판매자가 유효기간을 달리 정하지 아니한 경우에는 구매한 날부터 5년을 유효기간으로 봅니다.
                <br/>
                2.	판매자는 본 조 제1항의 유효기간을 상품권을 구매한 날 또는 충전일로부터 1년 이상으로 정할 수 있습니다. 다만, 다음 각 호의 물품 및 용역 제공형 상품권의 경우 유효기간을 상품권을 구매한 날로부터 3개월 이상으로 정할 수 있습니다.
                <br/>
                1)	가공 또는 건조되지 아니한 농·임·수·축산물로서 장기간 보관하는 경우 부패·변질 등으로 본래의 품질유지가 곤란한 물품을 대상으로 하는 상품권
                <br/>
                2)	기간을 정하여 일시적으로 생산·제공·판매되는 물품 또는 용역을 대상으로 하는 상품권
              </p>   
            </div>    

            <div className="txtbox">
              <p className="sub-title">제17조(환불)</p>
              <p className="contents">
                1.	구매자가 제15조에 따라 청약 철회를 하는 경우 교환권 대금 전액을 환불받을 수 있습니다.
                <br/>
                2.	금액형 쿠폰의 경우, 고객이 유효기간 경과 전에 교환권 금액의 60% (1만원 이하의 교환권은 80%) 이상에 해당하는 교환권상 물품 또는 용역(“물품 등”)을 제공 받고 잔액의 반환을 판매자에게 요구하는 경우, 판매자는 잔액(구매액을 기준으로 사용비율에 따라 계산하여 남은 비율의 금액)을 반환하여야 합니다.
                <br/>
                3.	유효기간 경과 전, 교환권 상의 물품 등의 제공이 불가능하거나 제공에 필요한 통상적인 기간보다 현저히 지체되는 경우, 판매자는 고객의 요구에 따라 해당 교환권과 동일한 금전적 가치의 교환권으로 즉시 교환하거나 구매액을 반환하여야 합니다.
                <br/>
                4.	유효기간 경과 후 구매자는 판매자에게 교환권의 미사용 부분에 대한 반환을 청구할 수 있으며 판매자는 잔액의 90%를 반환하여야 합니다.
                <br/>
                5.	본 조 제4항에도 불구하고 교환권을 구매한 날로부터 5년이 경과하면 상법상의 상사채권소멸시효가 완성되어 구매자는 판매자와 ‘회사’에게 물품 등의 제공, 환불 및 잔액반환을 요청할 수 없습니다. 판매자가 유효기간을 구매한 날로부터 5년 이상으로 정한 경우에는 구매자는 유효기간이 경과한 날로부터 판매자와 ‘회사’에게 물품 등의 제공, 환불 및 잔액반환을 요청할 수 없습니다. 이 때 잔액은 ‘회사’에 귀속됩니다.
              </p>   
            </div>    

            <p className="title">제 4장 계약당사자의 의무</p>

            <div className="txtbox">
              <p className="sub-title">제18조 (개인정보보호의 의무)</p>
              <p className="contents">
                1.	‘회사’는 관계법령(“정보통신망법” 및 “개인정보보호법” 등)이 정하는 바에 따라 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다.
                <br/>
                2.	회원의 개인정보보호에 관해서는 관계법령 및 ‘회사’가 정하는 “개인정보보호정책”에 정한 바에 의합니다.
              </p>   
            </div>     

            <div className="txtbox">
              <p className="sub-title">제19조 (회원에 대한 통지)</p>
              <p className="contents">
                1.	‘회사’가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원이 제공한 연락처, 이메일, ‘서비스’ 로그인 시 동의 및 팝업창 등의 수단으로 할 수 있습니다.
                <br/>
                2.	‘회사’는 회원 전체에 대한 통지의 경우 7일 이상 ‘서비스’ 내 게시판(공지사항 포함)에 게시함으로써 본 조 제1항의 통지에 갈음할 수 있습니다.
                <br/>
                3.	회원은 ‘회사’에 실제로 연락이 가능한 연락처, 이메일 등의 정보를 제공하고 해당 정보들을 최신으로 유지하여야 하며 ‘회사’의 통지를 확인하여야 합니다.
                <br/>
                4.	‘회사’는 불특정 다수 회원에 대한 통지의 경우 ‘서비스’ 내 게시판(공지사항 포함)에 게시함으로써 개별 통지에 갈음할 수 있습니다.
              </p>   
            </div> 

            <div className="txtbox">
              <p className="sub-title">제20조 (‘회사’의 의무)</p>
              <p className="contents">
                1.	‘회사’는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 ‘서비스’를 제공하기 위하여 최선을 다하여 노력합니다.
                <br/>
                2.	‘회사’는 회원이 안전하게 ‘서비스’를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
                <br/>
                3.	‘회사’는 ‘서비스’ 제공을 위해 습득한 회원의 정보를 동의없이 제3자에게 제공하지 않습니다. 단, 관계법령(“정보통신망법” 및 “개인정보보호법”등)에 의해 요청이 있을 경우에는 법률이 정한 바에 따라 적법한 절차에 의해 처리할 수 있습니다.
                <br/>
                4.	‘회사’는 관계법령(“정보통신망법” 및 “개인정보보호법” 등)을 준수하며, ‘서비스’를 운영 관리합니다.
                <br/>
                5.	‘회사’는 ‘서비스’ 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 본 약관 내 회원에게 정한 방법으로 회원에게 처리과정 및 결과를 전달할 수 있습니다.
              </p>   
            </div> 

            <div className="txtbox">
              <p className="sub-title">제21조 (회원의 의무)</p>
              <p className="contents">
                1.	회원은 ‘서비스’를 이용할 때 다음 행위를 해서는 안 됩니다.<br/>
                1)	이용신청 또는 변경 시 허위사실을 기재하거나 다른 회원의 정보를 도용, 부정하게 사용하는 행위
                <br/>
                2)	회원이 발행한 모바일상품권으로 고객이 사용(결제)을 요청했을 때, 정당한 사유없이 사용(결제)을 거부하는 행위
                <br/>
                3)	제3자의 상품 및 ‘서비스’를 회원의 상품 및 ‘서비스’로 위장하여 ‘서비스’ 이용 신청 및 상품 또는 ‘서비스’를 등록하는 행위
                <br/>
                4)	‘회사’의 ‘서비스’ 정보를 이용하여 얻은 정보를 ‘회사’의 사전 승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 행위
                <br/>
                5)	타인의 명예를 손상시키거나 불이익을 주는 행위
                <br/>
                6)	게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위
                <br/>
                7)	‘회사’의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위
                <br/>
                8)	‘서비스’ 운영을 고의로 방해하거나 ‘서비스’의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보를 전송하는 행위
                <br/>
                9)	‘서비스’와 관련된 설비의 오동작이나 정보 등 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위
                <br/>
                10)	공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성, 영상 등을 타인에게 유포하는 행위
                <br/>
                11)	타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위
                <br/>
                12)	다른 회원의 개인정보를 수집, 저장, 공개하는 행위
                <br/>
                13)	자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위 정보를 유통시키는 행위
                <br/>
                14)	재물을 걸고 도박하거나 사행행위를 하는 행위
                <br/>
                15)	수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상 등의 방법으로 계속하여 상대방에게 도달하게 함으로써
                <br/>상대방의 일상적 생활을 방해하는 행위
                16)	‘서비스’에 게시된 정보를 무단으로 변경하는 행위
                <br/>
                17)	관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터프로그램 포함)의 전송 또는 게시행위
                <br/>
                18)	‘회사’의 직원이나 운영자를 가장하거나 사칭 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위
                <br/>
                19)	컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 이메일로 발송하는 행위
                <br/>
                20)	스토킹 등 다른 회원을 괴롭히는 행위
                <br/>
                21)	기타 불법적이거나 부당한 행위
                <br/>

                2.	회원은 관계 법령, 본 약관의 규정, 이용안내 및 ‘서비스’ 상에 공지한 주의사항, ‘회사’가 통지하는 사항 등을 준수하여야 하며, 기타 ‘회사’의 업무에 방해가 되는 행위를 하여서는 안 됩니다.
                <br/>
                3.	회원은 ‘회사’에서 공식적으로 인정한 경우를 제외하고 ‘서비스’를 이용하여 상품을 판매하는 영업활동을 할 수 없으며, 특히 해킹, 광고를 통한 수익, 음란 사이트를 통한 상업행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치 등에 관해서는 ‘회사’가 책임 지지 않으며, 회원은 이와 같은 행위와 관련하여 ‘회사’에 대하여 손해배상의 의무를 집니다.
                <br/>
                4.	회원은 ‘서비스’ 이용을 위해 등록할 경우, 현재의 사실과 일치하는 완전한 정보를 제공해야 합니다.
                <br/>
                5.	회원은 등록정보에 변경사항이 발생할 경우, 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, ‘회사’의 본 약관에 의하여 회원의 ‘서비스’ 이용을 제한 또는 중지할 수 있습니다.
              </p>   
            </div> 

            <p className="title">제 5장 손해배상 등</p>

            <div className="txtbox">
              <p className="sub-title">제22조 (손해배상)</p>
              <p className="contents">
                1.	회원이 이 약관의 규정을 위반함으로 인하여 ‘회사’에 손해가 발생하게 되는 경우, 본 약관을 위반한 회원은 ‘회사’에 발생하는 모든 손해를 배상하여야 합니다.
                <br/>
                2.	회원이 ‘서비스’를 이용함에 있어 행한 불법행위나 본 약관 위반행위로 인하여 ‘회사’가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각 종 이의제기를 받는 경우 회원은 자신의 책임과 비용으로 ‘회사’를 면책시켜야 하며, ‘회사’가 면책되지 못한 경우 당 회원은 그로 인하여 ‘회사’에 발생한 모든 손해를 배상하여야 합니다.
                <br/>
                3.	‘회사’는 ‘서비스’와 관련한 회원의 불만사항이 접수되는 경우, 그러한 손해가 ‘회사’의 고의나 중과실에 기해 발생한 경우에 한하여 ‘회사’에 책임을 부담하며, 그 책임의 범위는 통상 손해에 한 합니다.
              </p>   
            </div> 

            <div className="txtbox">
              <p className="sub-title">제23조 (면책사항)</p>
              <p className="contents">
                1.	‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘서비스’를 제공할 수 없는 경우에는 ‘서비스’ 제공에 관한 책임이 면제됩니다.
                <br/>
                2.	‘회사’는 회원의 귀책사유로 인한 ‘서비스’ 이용 장애에 대하여는 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                <br/>
                3.	‘회사’는 회원이 ‘서비스’를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖에 ‘서비스’를 통하여 얻은 자료로 인한 손해 등에 대하여도 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                <br/>
                4.	‘회사’는 회원이 ‘서비스’와 관련하여 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 고의 또는 중과실이 없는 한 책임을 지지 않습니다.
                <br/>
                5.	‘회사’는 회원 상호간 또는 회원과 제3자 상호간 ‘서비스’를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임이 없습니다.
                <br/>
                6.	‘회사’는 무료로 제공되는 ‘서비스’ 이용과 관련하여 관련 법에 특별한 규정이 없는 한 책임을 지지 않습니다.
              </p>   
            </div> 

            <div className="txtbox">
              <p className="sub-title">제24조 (준거법 및 분쟁해결)</p>
              <p className="contents">
                1.	‘회사’와 회원간 제기된 소송은 대한민국법을 준거법으로 합니다.
                <br/>
                2.	‘서비스’ 이용과 관련하여 ‘회사’와 회원 사이에 분쟁이 발생한 경우, ‘회사’와 회원은 분쟁의 해결을 위하여 성실히 협의합니다.
                <br/>
                3.	본 조 제2항의 협의에서도 ‘회사’와 회원간 분쟁이 해결되지 않을 경우, 양 당사자는 민사소송법상 관할 법원에 소를 제기할 수 있습니다.
              </p>   
            </div> 
         
        </Modal.Body>
      </Modal>
    </>
  );
}
