
import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';


import "./Popup.css";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function Popup(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

   

    useEffect( () => {
        //데이터 로딩
        setLoading(true);

 

        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <></>

    
    return (
        <>
        <div className='demo'>

            <div className="TPopupPage">
                <div className="container-fluid g-0">
                    <div className="PopupContents">
                        <div className="PopupTitle text-center">
                            <div className="PopupBadge">식권 서비스 출시 이벤트</div>
                            <div className="PopupTit">
                                힘들게 기다리지 말고
                                <br />
                                <span className="Big">다인<span className="Badge">식권 APP</span></span>
                            </div>
                        </div>
                        <div className="PopupDate text-center">
                            <span className="bold">03.02</span> ~ <span className="bold">03.31</span>
                        </div>
                        <div className="PopupText text-center">
                            이제, 다인 식권 서비스로 간편하게!
                            <br />
                            <span className="bold">모바일로 식권을 구매</span> 해보세요.
                        </div>
                        <div className="PopupTxtBox text-center">
                            <div className="txt">
                                식권APP이나 모바일 웹에 회원가입 후,
                                <br />
                                <span className="bold">식권을 구매하고 사용한 분들 중 </span><br />
                                <span className="bold">추첨</span>을 통해 선물을 드립니다.
                            </div>
                            <div className="txt my-3">
                                당첨자 발표일 :
                                <span className="bold"> 매주 화요일 각 100명</span>
                                <br />
                                <span className="small">(발표일 : 3월14일 / 3월21일 / 3월28일 / 4월4일)</span>
                            </div>
                            <div className="PopupThumBox text-center">
                                <div className="PopupThum">
                                    <img src="https://www.flexdaycdn.net/public/images/ticket/popup_thum3-2.png"
                                        className="img-fluid" />
                                </div>
                                <p className="name mb-0">
                                    카페 블루포트<br />
                                    아이스 아메리카노
                                </p>
                            </div>
                            <div className="txt">
                                <span className="NanumPen Tip">TIP!</span>
                                <span className="small">
                                    식권 서비스를 많이 사용할수록 당첨 확률이 높으며, <br />
                                    중복 당첨도 가능합니다.
                                </span>
                            </div>
                        </div>
                        <div className="PopupNotice">
                            <p className="tit">
                                <img src="https://www.flexdaycdn.net/public/images/ticket/popup_icon.png"
                                    className="img-fluid" />
                                꼭 확인해주세요.
                            </p>
                            <p className="desc">
                                당첨자에게는 카카오 알림톡을 개별 발송해드립니다.
                            </p>
                            <p className="desc">
                                당첨자 알림톡 발송을 위해서 마케팅 수신동의는 필수입니다.
                            </p>
                            <p className="desc">
                                해당 교환권은 인제대학교 김해캠퍼스 내 블루포트에서만 사용이 가능합니다.
                            </p>
                        </div>
                    </div>
                    <div className="PopupBtn text-center">
                        <button type="button" className="btn bnt-clear w-100" onClick={()=> {navigate('/login')}}>닫기</button>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}