
import { Spinner } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';

import { useSearchParams, useNavigate, Link, useParams } from 'react-router-dom';
import { AlertError, getApiUrl, PrintConsole } from '../../function/common';
import axios from 'axios';

import "./GiftSuccess.css";
import { setPhone } from '../../function/common';

export default function GiftSuccess(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams]=useSearchParams();
    // const [Params] = useSearchParams();
    // console.log(searchParams.get('storeurl'));
    // console.log(searchParams.get('productid'));
    
    const [gift, setGift] = useState({
        recipientName           : "",
        recipientPhoneNumber    : "",
        orderPrice              : ""
    });

    const payMode = useRef();
    
    const onChangeGift = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setGift({
          ...gift,
          [e.target.id]: e.target.value,
        });
        PrintConsole(gift);
    };

    const [data, setData] = useState([]);

    const onChangeData = (e) => {
        //데이터셋 변경시 id값 읽어 대입
        setData({
          ...data,
          [e.target.id]: e.target.value,
        });
        PrintConsole(data);
    };

    // console.log(localStorage.getItem('storeDomain'));

    const { couponName } = useParams();
    const { expiredAt } = useParams();
    const { oauthType } = useParams();
    const { storeName } = useParams();
    const { toName } = useParams();
    const { toPhoneNumber } = useParams();

    useEffect(() => {
        //데이터 로딩
        // setLoading(true);

        //데이터 로딩 완료
        // setLoading(false);
    }, []);


    if (loading)
        return (
            <div className="container" style={{ position: "absolute", left: "50%", top: "50%", marginLeft: "-18px" }}>
                <Spinner animation="border" />
            </div>
        );

     
    return (
        <>
        <div className="demo">

            <div className="TGiftConfirmPage">
                <div className="container-fluid g-0">

                    <nav className="sub-navbar">
                        <p className="navbar-title">선물 완료</p>
                        <button type="button" className="btn-clear btn-close" onClick={()=>{navigate("/storemain")}}>
                            <img className="img-fluid" alt="뒤로가기"
                                src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                        </button>
                    </nav>

                    <div className="GiftBox px-4-5">
                        <div className="GiftBox-inner">
                            <div className="Gift-title text-center">
                                <img className="img-fluid mb-4"
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/gift_done.png" alt="" />
                                <p className="PageTit2 mb-2">
                                    선물이 성공적으로<br />
                                    전송되었습니다.
                                </p>
                            </div>
                            <div className="Gift-infobox">
                                <div className="info-item">
                                    <p className="title">
                                        받는 사람
                                    </p>
                                    <div className="info-contents">
                                        <div className="info-row">
                                            <div className="tit">이름</div>
                                            <div className="desc sns">
                                                {toName}
                                                
                                                {
                                                    oauthType === 'NAVER' ?
                                                <div className="imgbox naver">
                                                    <img src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png"
                                                        alt="naver" className="img-fluid sns-icon" />
                                                </div>
                                                :
                                                <></>
                                                }   
                                                
                                                {
                                                    oauthType === 'APPLE' ?
                                                <div className="imgbox apple">
                                                    <img src="https://www.flexdaycdn.net/public/images/ticket/apple_icon.png"
                                                        alt="apple" className="img-fluid sns-icon" />
                                                </div>
                                                :
                                                <></>
                                                }
                                                {
                                                    oauthType === 'KAKAO' ?
                                                <div className="imgbox kakao">
                                                    <img src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png"
                                                        alt="kakao" className="img-fluid sns-icon" />
                                                </div>
                                                :
                                                <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="tit">연락처</div>
                                            <div className="desc">
                                                {setPhone(toPhoneNumber)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-item">
                                    <p className="title">
                                        식권정보
                                    </p>
                                    <div className="info-contents">
                                        <div className="info-row">
                                            <div className="tit">식권</div>
                                            <div className="desc sns">
                                                {couponName}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="tit">매장</div>
                                            <div className="desc">
                                                {storeName}
                                            </div>
                                        </div>
                                        <div className="info-row">
                                            <div className="tit">유효기간</div>
                                            <div className="desc">
                                                ~{expiredAt}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="Gift-Confirm mb-4">
                                <button type="button" className="btn btn-default" onClick={()=> {navigate('/storemain')}}>
                                    홈으로
                                </button>
                                <button type="button" className="btn btn-light" style={{'color': '#000'}} onClick={()=> {navigate('/giftlist')}}>
                                    선물 내역
                                </button>
                            </div>

                            {/* 인제대학교 백병원 전용 bg  */}
                            <div className="GiftBox-bg">
                                <img className="img-fluid w-100"
                                    src="https://www.flexdaycdn.net/public/images/ticket_ver2/Gift_confirm_bg_ver2.png" alt=""/>
                            </div>

                            {/* 인제대학교 전용 bg
                            <div className="GiftBox-bg">
                            <img className="img-fluid w-100"
                                src="https://www.flexdaycdn.net/public/images/ticket_ver2/Gift_confirm_bg_ver1.png" alt="/>
                            </div> */}

                        </div>

                    </div>

                </div>
            </div>
        </div>
        </>
    )
}