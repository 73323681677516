import { Image, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import {
  AlertError,
  getApiUrl,
  getRedirctUrl,
  includeStr,
  setCommas,
  compareDatesWithTime,
} from "../../function/common";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./StoreMain.css";

import { getImgUrl, PrintConsole } from "../../function/common";

import { openQRRead, setAccessToken, setNav1 } from "../../api/ApiWebView";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Footer from "./components/Footer";
import Popup from "./Popup";
import ReactPullToRefresh from "react-pull-to-refresh/dist";
import PullToRefresh from "react-simple-pull-to-refresh";
import { getMobileOperatingSystem } from "../../modules/getMobileOperationSystem";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

export default function UserStore() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const os = getMobileOperatingSystem();
  // console.log(os);

  // 모달
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // 모달
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // 팝업 모달
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // 캠퍼스 팝업 모달
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // 모달
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const modal7Text = useRef();
  const modal7TextSub = useRef();

  // 광고 모달
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const { storeDomain } = useParams();

  const [Params] = useSearchParams();
  var Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push({ [entry[0]]: entry[1] });
  }

  if (
    localStorage.getItem("defaultStore") === "none" ||
    localStorage.getItem("defaultStore") === undefined ||
    localStorage.getItem("defaultStore") === null ||
    localStorage.getItem("defaultStore") === "null"
  ) {
    navigate("/intro");
  }

  // console.log(Parameters);

  const NonUserLogin = async (e) => {
    let obj = {
      token_version_id: Parameters[0]["token_version_id"],
      enc_data: Parameters[1]["enc_data"],
      integrity_value: Parameters[2]["integrity_value"],
    };
    await axios
      .post(
        getApiUrl("v1/auth/self/result"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(
            "accessToken",
            response.data.loginToken.accessToken
          );
          // console.log(localStorage.getItem('accessToken'));

          getPopup();
          getAdPopup();
          getUser().then(() => {
            getStore();
            getProducts();
          });
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  };

  const [products, setProducts] = useState();
  function getProducts() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl(
            "v1/stores/" + localStorage.getItem("defaultStore") + "/products"
          )
        )
        .then(function (response) {
          if (response.status === 200) {
            // console.log(response.data.data);
            setProducts(response.data);
          }
          setLoading(false);
          resolve();
        })
        .catch(function (error) {
          // AlertError(error);
          // navigate('/404');
          // console.log('error');
        });
    });
  }

  const [userInfo, setUserInfo] = useState();
  function getUser() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/users/me"),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setUserInfo(response.data);

            if (response.data.name === undefined) {
              localStorage.setItem("userName", "이름없음");
              localStorage.setItem("oauthType", response.data.oauthType);
              // localStorage.setItem('defaultStore', response.data.defaultDomain);
            } else if (response.data.name === null) {
              localStorage.setItem("userName", "이름없음");
              localStorage.setItem("oauthType", response.data.oauthType);
              // localStorage.setItem('defaultStore', response.data.defaultDomain);
            } else {
              localStorage.setItem("userName", response.data.name);
              localStorage.setItem("oauthType", response.data.oauthType);
              // localStorage.setItem('defaultStore', response.data.defaultDomain);
            }
            resolve();
          }
        })
        .catch(function (error) {
          AlertError(error);
          localStorage.clear();
          navigate("/");
        });
    });
  }

  const [store, setStore] = useState();
  function getStore() {
    return new Promise(async function (resolve, reject) {
      // console.log(localStorage.getItem('defaultStore'))
      const response = await axios
        .get(
          getApiUrl("v1/stores/" + localStorage.getItem("defaultStore")),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setStore(response.data);
            localStorage.setItem("storeType", response.data.storeType);
          }
        })
        .catch(function (error) {
          // AlertError(error);
          if (
            localStorage.getItem("defaultStore") === "none" ||
            localStorage.getItem("defaultStore") === undefined ||
            localStorage.getItem("defaultStore") === null ||
            localStorage.getItem("defaultStore") === "null"
          ) {
            navigate("/intro");
          }
        });
    });
  }

  const [popup, setPopup] = useState();
  function getPopup() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl(
            "v1/stores/" + localStorage.getItem("defaultStore") + "/popups"
          ),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setPopup(response.data);

            if (response.data.length > 0) {
              if (localStorage.getItem("TodayPopup") !== "false") {
                handleShow5();
              }
            }
          }
        })
        .catch(function (error) {
          AlertError(error);
        });
    });
  }

  const [adPopup, setAdPopup] = useState();
  function getAdPopup() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/advertisements"),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            setAdPopup(response.data.data);

            if (response.data.data.length > 0) {
              handleShow8();
            }
          }
        })
        .catch(function (error) {
          AlertError(error);
        });
    });
  }

  function mEA(idx) {
    if (cart[idx].EA <= 1) {
    } else {
      --cart[idx].EA;
    }

    setCart([...cart]);
  }
  function checkCanBuy(rule, count) {
    if (rule.useQuantityLimit === false) {
      //제한 조건 없을 시 기본 10개 까지만 구매 가능하도록
      if (count > 9) {
        handleShow();
        return;
      }
    } else {
      //제한 조건 없을 시 기본 10개 까지만 구매 가능하도록
      if (count > 9) {
        handleShow();
        return;
      }

      if (rule.daySellLimit === true) {
        if (rule.countOfMaxSold - rule.countOfSold - count < 1) {
          handleShow2();
          return;
        }
      }

      if (rule.userDayBuyLimit === true) {
        if (count + 1 > rule.countOfUserDayBuy) {
          handleShow();
          return;
        }
      }

      if (rule.dayPeriodLimit === true) {
        const today = new Date().setHours(0, 0, 0, 0);
        const start = new Date(rule.startDateOfQuantityLimit).setHours(
          0,
          0,
          0,
          0
        );
        const end = new Date(rule.endDateOfQuantityLimit).setHours(0, 0, 0, 0);
        if (!(today >= start && today <= end)) {
          modal7Text.current = "구매 가능 기간이 아닙니다.";
          modal7TextSub.current =
            "가능 기간: " +
            rule.startDateOfQuantityLimit +
            " ~ " +
            rule.endDateOfQuantityLimit;
          handleShow7();
          return;
        }
      }

      if (rule.useTimeLimit === true) {
        const now = new Date();

        if (
          !(
            compareDatesWithTime(now, rule.startTimeOfQuantityLimit) >= 0 &&
            compareDatesWithTime(now, rule.endTimeOfQuantityLimit) <= 0
          )
        ) {
          modal7Text.current = "구매 가능 시간이 아닙니다.";
          modal7TextSub.current =
            "가능 시간: " +
            rule.startTimeOfQuantityLimit?.substring(0, 5) +
            " ~ " +
            rule.endTimeOfQuantityLimit?.substring(0, 5);
          handleShow7();
          return;
        }
      }
    }
    return true;
  }

  function pEA(idx) {
    console.log("pEA");
    // console.log(cart[idx]);
    // console.log(((cart[idx].row.quantityLimit.countOfMaxSold - cart[idx].row.quantityLimit.countOfSold) - cart[idx].EA));

    // console.log(cart[idx].row.quantityLimit.countOfUserDayBuy + " / " + (cart[idx].EA + 1))
    // if(cart[idx].row.quantityLimit.countOfUserDayBuy)
    const rule = cart[idx].row.quantityLimit;
    if (!checkCanBuy(rule, cart[idx].EA)) {
      return;
    }
    // if(rule.useQuantityLimit === false){
    //     //제한 조건 없을 시 기본 10개 까지만 구매 가능하도록
    //     if(cart[idx].EA > 9){
    //         handleShow();
    //         return;
    //     }
    // }else{
    //     //제한 조건 없을 시 기본 10개 까지만 구매 가능하도록
    //     if(cart[idx].EA > 9){
    //         handleShow();
    //         return;
    //     }
    //
    //     if(rule.daySellLimit === true){
    //         if( ((rule.countOfMaxSold - rule.countOfSold) - cart[idx].EA) < 1 ){
    //             handleShow2();
    //             return;
    //         }
    //     }
    //
    //     if(rule.userDayBuyLimit === true){
    //         if((cart[idx].EA + 1) > rule.countOfUserDayBuy){
    //             handleShow();
    //             return;
    //         }
    //     }
    //
    //
    // }
    ++cart[idx].EA;
    setCart([...cart]);
    return;
    // if(cart[idx].row.quantityLimit.userDayBuyLimit === true && cart[idx].row.quantityLimit.useQuantityLimit == true){
    //     if((cart[idx].EA + 1) > cart[idx].row.quantityLimit.countOfUserDayBuy){
    //         handleShow();
    //     }else{
    //         if( cart[idx].row.quantityLimit.useQuantityLimit === true && cart[idx].row.quantityLimit.userDayBuyLimit === true &&
    //             ((cart[idx].row.quantityLimit.countOfMaxSold - cart[idx].row.quantityLimit.countOfSold) - cart[idx].EA) < 1 ){
    //             handleShow2();
    //         }else{
    //             ++cart[idx].EA;
    //             setCart([...cart]);
    //         }
    //     }
    // }else{
    //     if(cart[idx].EA > 9){
    //         handleShow();
    //     }else{
    //         if( cart[idx].row.quantityLimit.useQuantityLimit === true && cart[idx].row.quantityLimit.userDayBuyLimit === true&&
    //             ((cart[idx].row.quantityLimit.countOfMaxSold - cart[idx].row.quantityLimit.countOfSold) - cart[idx].EA) < 1 ){
    //             handleShow2();
    //         }else{
    //             ++cart[idx].EA;
    //             setCart([...cart]);
    //         }
    //     }
    // }
  }

  function DelProduct(idx) {
    let TmpCart = [...cart];
    TmpCart.splice(idx, 1);
    setCart(TmpCart);

    // console.log(cart);
    // console.log(cart.length);
    if (cart.length <= 1) {
      let bsCollapse = new bootstrap.Collapse("#PayItem", {
        toggle: false,
      });
      bsCollapse.show();
      document.getElementById("StorePay").style.display = "none";
    }
  }

  const [cart, setCart] = useState([]);
  const [ea, setEA] = useState([]);
  function addCart(row) {
    console.log("addCart");
    // console.log(row);

    const rule = row.quantityLimit;
    if (!checkCanBuy(rule, 0)) {
      return;
    }
    // if(rule.useQuantityLimit === true){
    //     if(rule.daySellLimit === true){
    //         if( ((rule.countOfMaxSold - rule.countOfSold)) < 1 ){
    //             handleShow2();
    //             return;
    //         }
    //     }
    //
    //     if(rule.userDayBuyLimit === true){
    //         if(1 > rule.countOfUserDayBuy){
    //             handleShow();
    //             return;
    //         }
    //     }
    //
    //
    // }

    if (cart.length === 0) {
      setCart([
        ...cart,
        {
          EA: 1,
          row,
        },
      ]);
    } else {
      let TmpCheck = false;
      cart.map((eq) => {
        if (eq.row.productId === row.productId) {
          TmpCheck = true;
        }
      });
      // console.log(TmpCheck);
      if (TmpCheck === false) {
        setCart([
          ...cart,
          {
            EA: 1,
            row,
          },
        ]);
      }
    }

    let bsCollapse = new bootstrap.Collapse("#PayItem", {
      toggle: false,
    });
    bsCollapse.show();
    document.getElementById("StorePay").style.display = "";
  }

  function SubmitPrice() {
    // console.log(cart.length);
    if (cart.length !== 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
      // console.log(localStorage.getItem('cart'));
      navigate("/payment");
    } else {
      alert("선택된 메뉴가 없습니다.");
    }
  }

  function ChangeStore(Store) {
    localStorage.setItem("defaultStore", Store);
    window.location.reload();
  }

  function ChangeDefaultStore(store) {
    var variables = {
      domain: store,
    };

    //서비스 호출
    axios
      .put(getApiUrl("v1/users/me/default-store"), variables, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("defaultStore", store);
          window.location.reload();
        }
      })
      .catch(function (error) {
        AlertError(error);
      });
  }

  useEffect(() => {
    if (loading === false) {
      // console.log(cart);
      let TmpTotal = 0;
      cart.map((row) => (TmpTotal = TmpTotal + row.EA * row.row.price));
      // console.log(TmpTotal);
      document.getElementById("PriceBtn").innerHTML =
        setCommas(TmpTotal) + " 결제하기";
    }
  }, [cart]);

  useEffect(() => {
    // if(localStorage.getItem('StoreURL') === undefined || localStorage.getItem('StoreURL') === null){
    //     //기본 스토어 URL
    //     localStorage.setItem('StoreURL', 'inje');
    // }

    localStorage.removeItem("cart");

    // 비회원 로그인
    // console.log(Parameters);
    if (Parameters.length > 0) {
      NonUserLogin();
    } else {
      if (
        localStorage.getItem("accessToken") === undefined ||
        localStorage.getItem("accessToken") === null
      ) {
        //토큰 없으면 메인으로
        // alert("올바르지 않은 접근입니다.");
        window.location.replace("/");
      } else {
        if (
          localStorage.getItem("defaultStore") === "none" ||
          localStorage.getItem("defaultStore") === undefined ||
          localStorage.getItem("defaultStore") === null ||
          localStorage.getItem("defaultStore") === "null" ||
          localStorage.getItem("defaultStore") === ""
        ) {
          navigate("/intro");
        } else {
          getUser().then(() => {
            getStore();
            getPopup();
            getProducts();
            getAdPopup();
            // if (localStorage.getItem("thisWeekend")) {
            //   const date = localStorage.getItem("thisWeekend");
            //   const checkedDate = dayjs(date);
            //   const canShow =
            //     checkedDate.endOf("day").diff(dayjs(), "day") <= 0;
            //   if (canShow) {
            //     getAdPopup();
            //     localStorage.removeItem("thisWeekend");
            //   }
            // } else {
            //   getAdPopup();
            // }
          });
        }
      }
    }
  }, []);

  function today() {
    localStorage.setItem("TodayPopup", "false");
    // handleClose();
  }

  const thisWeekAdFalse = () => {
    localStorage.setItem(
      "thisWeekend",
      dayjs().weekday(7).format("YYYY-MM-DD")
    );
  };

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TicketPage user">
          <div className="container-fluid pt-70px g-0">
            <nav className="navbar bg-none fixed-top">
              <div className="container-fluid">
                <a className="navbar-brand" href="#">
                  {localStorage.getItem("defaultStore") === "inje" ? (
                    <>
                      <img
                        className="img-fluid logo"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                      />
                      <span className="brand-txt" onClick={handleShow6}>
                        인제대학교 늘빛관(다인)
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {localStorage.getItem("defaultStore") === "paik" ? (
                    <>
                      <img
                        className="img-fluid logo"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                      />
                      <span className="brand-txt" onClick={handleShow6}>
                        인제대학교 의과대학(다인)
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {localStorage.getItem("defaultStore") === "dasom" ? (
                    <>
                      <img
                        className="img-fluid logo"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                      />
                      <span className="brand-txt" onClick={handleShow6}>
                        인제대학교 다솜식당
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </a>
                <Header />
              </div>
            </nav>
            <PullToRefresh
              onRefresh={() => {
                window.location.reload();
              }}
              refreshingContent={<div className="loader"></div>}
              pullingContent={<div className="loader"></div>}
            >
              <div className="TicketPhoto">
                <div className="TicketPhoto-Slider">
                  <Swiper
                    loop={true}
                    pagination={{ el: ".TS-pag", type: "fraction" }}
                    modules={[Pagination, Navigation]}
                    className="mySwiper d-flex"
                  >
                    {store &&
                      store.files.map((row, index) => (
                        <SwiperSlide key={index}>
                          <Image
                            fluid
                            className="w-100 StoreBg"
                            alt=""
                            src={row.url}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>

                <div className="TicketPhoto-Content d-flex">
                  <button
                    type="button"
                    className="btn btn-clear btn-camera opacity-0 pe-none"
                  >
                    <img
                      className="img-fluid"
                      alt=""
                      src="https://www.flexdaycdn.net/public/images/static/store-main/store_camera.png"
                    />
                  </button>

                  <div className="PhotoCount swiper-pagination TS-pag"></div>
                </div>
              </div>

              <div className="StoreDetail pt-4-5">
                <div className="StoreTitle align-items-center d-flex px-4-5">
                  <img
                    className="img-fluid logo"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/ticket/logo.png"
                  />
                  <p className="mb-0 tit">{store && store.storeName}</p>
                </div>
                <div className="StoreInfo  px-4-5">
                  <div className="g-0 row mb-2">
                    <div className="col-2 tit">운영시간</div>
                    <div
                      className="col-10 desc"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {store && store.status.code === 1
                        ? store && store.businessHours
                        : "현재 운영 기간이 아닙니다."}
                    </div>
                  </div>

                  <div className="g-0 row">
                    <div className="col-2 tit">위치안내</div>
                    <div className="col-10 desc">{store && store.location}</div>
                  </div>
                </div>

                {/* 메인 운영 */}
                {store && store.useNotice === true ? (
                  <div className="StoreAppInfo">
                    <div className="title">
                      <img
                        className="img-fluid speaker"
                        alt=""
                        src="https://www.flexdaycdn.net/public/images/ticket_ver2/speaker2.png"
                      />
                      <span>공지사항</span>
                    </div>

                    <div className="txtbox" style={{ whiteSpace: "pre-line" }}>
                      {store && store.notice}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="StoreTab-wrap b-top">
                  <div
                    className="StoreTab-content tab-content px-4-5"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-01"
                      role="tabpanel"
                      aria-labelledby="nav-01-tab"
                      tabIndex="0"
                    >
                      <div className="ProductList">
                        {store && store.status.code === 1 ? (
                          <>
                            {products &&
                              products.map((row) => (
                                <div
                                  className="prd-item"
                                  key={row.productId}
                                  onClick={() => {
                                    addCart(row);
                                  }}
                                >
                                  <p className="prd-tit">{row.productName}</p>
                                  <div
                                    className="prd-sub"
                                    style={{ whiteSpace: "pre-line" }}
                                  >
                                    {row.descriptionType.code === 1
                                      ? row.productDescription
                                      : row.toDayDescription}
                                  </div>
                                  <div className="prd-time">
                                    {row.additionalDescription !== "" &&
                                    row.additionalDescription !== null ? (
                                      <p className="operating">
                                        &nbsp; {row.additionalDescription}
                                      </p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>

                                  <div className="prd-bottom">
                                    <p className="prd-price">
                                      <span className="price">
                                        {setCommas(row.price)}
                                      </span>
                                      원
                                    </p>
                                    {store &&
                                    store.storeType === "SMART_ORDER" ? (
                                      row.quantityLimit.canSmartOrder ===
                                        true && row.kitchenAlive === true ? (
                                        <div className="prd-order on">
                                          <div className="dot"></div>
                                          주문가능
                                        </div>
                                      ) : (
                                        <div className="prd-order off">
                                          <div className="dot"></div>
                                          주문불가
                                        </div>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  {row.quantityLimit.useQuantityLimit ===
                                    true &&
                                  row.quantityLimit.daySellLimit === true ? (
                                    <div className="quantity-txt">
                                      <img
                                        className="img-fluid icon"
                                        alt=""
                                        src="https://www.flexdaycdn.net/public/images/ticket/cart-icon.png"
                                      />
                                      {setCommas(
                                        row.quantityLimit.countOfMaxSold -
                                          row.quantityLimit.countOfSold
                                      )}{" "}
                                      개 남음
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ))}
                          </>
                        ) : (
                          <>
                            <div className="no-item">
                              <img
                                className="img-fluid no_itemImg"
                                alt=""
                                src="https://www.flexdaycdn.net/public/images/ticket_ver2/no_item.png"
                              />
                              <p className="no_txt mb-0">
                                지금은 매장 미운영 기간입니다. <br />
                                구매 가능한 메뉴가 없습니다.
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PullToRefresh>
          </div>
          <div id="StorePay" className="StorePay" style={{ display: "none" }}>
            <div className="AddPrdBox px-4-5 accordion" id="PayItemBox">
              <div className="accordion-item border-0">
                <div
                  className="mx-auto prd-slidedown py-2 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#PayItem"
                >
                  <img
                    className="img-fluid logo"
                    alt=""
                    src="https://www.flexdaycdn.net/public/images/ticket/prd-down.png"
                  />
                </div>
              </div>
              <div
                id="PayItem"
                className="accordion-collapse collapse "
                data-bs-parent="#PayItemBox"
              >
                {cart &&
                  cart.map((row, idx) => (
                    <div className="prd-item" key={idx}>
                      <div className="prd-tit">{row.row.productName}</div>
                      <div
                        className="prd-sub"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {row.row.descriptionType.code === 1
                          ? row.row.productDescription
                          : row.row.toDayDescription}
                      </div>
                      <div className="align-items-center d-flex justify-content-between prd-option">
                        <div className="inputbox d-flex">
                          <button
                            type="button"
                            className="btn btn-clear minus"
                            onClick={() => {
                              mEA(idx);
                            }}
                          >
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/prd-minus.png"
                              alt=""
                              className="img-fluid"
                            />
                          </button>
                          <input
                            type="number"
                            className="form-control InputType"
                            readOnly
                            value={row.EA}
                          />
                          <button
                            type="button"
                            className="btn btn-clear plus"
                            onClick={() => {
                              pEA(idx);
                            }}
                          >
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/prd-plus.png"
                              alt=""
                              className="img-fluid"
                            />
                          </button>
                        </div>
                        <p className="prd-price">
                          <span className="price">
                            {" "}
                            {setCommas(row.EA * row.row.price)} 원
                          </span>
                          <button
                            type="button"
                            className="btn btn-clear closeBtn"
                            onClick={() => {
                              DelProduct(idx);
                            }}
                          >
                            <img
                              src="https://www.flexdaycdn.net/public/images/ticket/prd-close.png"
                              alt=""
                              className="img-fluid"
                            />
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <button
              id="PriceBtn"
              type="button"
              className="align-items-center btn btn-primary d-flex h-auto justify-content-center py-4 shadow-none"
              onClick={() => {
                SubmitPrice();
              }}
            >
              {/* onClick={handleShow} */}
              {/* <span>5,800</span>원 결제하기 */}
              결제하기
            </button>
          </div>
          {/* 대량구매 Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>
                대량 구매를 원하시는 분은 <br />
                관리자에게 문의 바랍니다.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="modal-title" style={{ color: "#666666" }}>
                {localStorage.getItem("defaultStore") === "inje"
                  ? "055-320-3054"
                  : "051-890-6626"}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={handleClose}
              >
                닫기
              </Button>
              {localStorage.getItem("defaultStore") === "inje" ? (
                <a
                  href="tel:0553203054"
                  className="btn btn-clear btn-modal-primary"
                >
                  담당자 연결
                </a>
              ) : (
                <a
                  href="tel:0518906626"
                  className="btn btn-clear btn-modal-primary"
                >
                  담당자 연결
                </a>
              )}
            </Modal.Footer>
          </Modal>
          <Modal
            show={show2}
            onHide={handleClose2}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>
                선택한 수량이 <br />
                잔여 수량보다 많습니다.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <p className="modal-title" style={{"color":"#666666"}}>
                            055-320-3054
                        </p> */}
              구매 가능한 수량을 확인해주세요.
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="modal-secondary" className='btn-clear' onClick={handleClose2}>
                            닫기
                        </Button>
                        <a  href="tel:0516210203" className='btn btn-clear btn-modal-primary'>
                            담당자 연결
                        </a> */}
              <button
                type="button"
                className="btn btn-clear btn-modal-primary w-100"
                data-bs-dismiss="modal"
                onClick={handleClose2}
              >
                확인
              </button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={show7}
            onHide={handleClose7}
            keyboard={false}
            size="sm"
            centered
            className="demoModal modal-prdAdd text-center"
          >
            <Modal.Header>
              <Modal.Title>{modal7Text.current}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modal7TextSub.current}</Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-clear btn-modal-primary w-100"
                data-bs-dismiss="modal"
                onClick={handleClose7}
              >
                확인
              </button>
            </Modal.Footer>
          </Modal>
          {/* 재고없음 Modal */}
          {/* <div className="demoModal modal fade modal-prdAdd text-center" id="Modal-02" tabIndex="-1">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <p className="modal-title">
                                    재고 소진
                                </p>
                            </div>
                            <div v="modal-body">
                                준비된 재료가 소진되어 <br />
                                해당 메뉴는 구매하실 수 없습니다
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-clear btn-modal-primary w-100" data-bs-dismiss="modal">c</button>
                            </div>
                        </div>
                    </div>
                </div>       */}
          {/* v2 이벤트 팝업 Modal */}
          <Modal
            show={show5}
            onHide={handleClose5}
            keyboard={false}
            size="sm"
            className="demoModal user"
            dialogClassName="modal-popup"
          >
            <Modal.Body>
              <div className="swiper-pagination Popup-pag"></div>
              <div className="Popup-Slider">
                <Swiper
                  loop={popup && popup.length <= 0 ? true : false}
                  pagination={{ el: ".Popup-pag", type: "fraction" }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper d-flex"
                >
                  {popup && popup.length > 0 ? (
                    popup.map((row) => (
                      <>
                        {row.popupImageType.code === 1 ? (
                          <SwiperSlide className="pop-custom typeA">
                            <a href="#">
                              <div className="Tit">
                                <div
                                  style={{ whiteSpace: "pre-line" }}
                                  dangerouslySetInnerHTML={{
                                    __html: row.title,
                                  }}
                                ></div>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.subTitle,
                                }}
                                className="Sub"
                                style={{ whiteSpace: "pre-line" }}
                              ></div>
                              {row.buttonLink !== "" ? (
                                <button
                                  type="button"
                                  className="btn bnt-clear Button"
                                  onClick={() => {
                                    window.open(row.buttonLink, "_blank");
                                  }}
                                >
                                  {row.buttonText}
                                </button>
                              ) : (
                                <></>
                              )}
                            </a>
                          </SwiperSlide>
                        ) : row.popupImageType.code === 2 ? (
                          <SwiperSlide className="pop-custom typeB">
                            <a href="#">
                              <div className="Tit">
                                <div
                                  style={{ whiteSpace: "pre-line" }}
                                  dangerouslySetInnerHTML={{
                                    __html: row.title,
                                  }}
                                ></div>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: row.subTitle,
                                }}
                                className="Sub"
                                style={{ whiteSpace: "pre-line" }}
                              ></div>
                              {row.buttonLink !== "" ? (
                                <button
                                  type="button"
                                  className="btn bnt-clear Button"
                                  onClick={() => {
                                    window.open(row.buttonLink, "_blank");
                                  }}
                                >
                                  {row.buttonText}
                                </button>
                              ) : (
                                <></>
                              )}
                            </a>
                          </SwiperSlide>
                        ) : (
                          <SwiperSlide className="pop-custom typeC">
                            <a href="#">
                              <img
                                src={row.backgroundImageUrl}
                                class="img-fluid backgroundImg"
                                alt=""
                              />
                              <div className="Tit">
                                <div
                                  style={{ whiteSpace: "pre-line" }}
                                  dangerouslySetInnerHTML={{
                                    __html: row.title,
                                  }}
                                ></div>
                              </div>
                              <div className="Sub">
                                <div
                                  style={{ whiteSpace: "pre-line" }}
                                  dangerouslySetInnerHTML={{
                                    __html: row.subTitle,
                                  }}
                                ></div>
                              </div>
                              {row.buttonLink !== "" ? (
                                <button
                                  type="button"
                                  className="btn bnt-clear Button"
                                  onClick={() => {
                                    window.open(row.buttonLink, "_blank");
                                  }}
                                >
                                  {row.buttonText}
                                </button>
                              ) : (
                                <></>
                              )}
                            </a>
                          </SwiperSlide>
                        )}
                      </>
                    ))
                  ) : (
                    <></>
                  )}
                </Swiper>
                {/* <SwiperSlide className="pop-custom typeB">
                                        <Link to={""}>
                                        <div className="Tit">
                                                <span className="color">강조하실 문구</span>를
                                                <br />
                                                작성해주세요!
                                            </div>
                                            <div className="Sub">
                                                상세 설명을 작성해주세요.
                                                <br />
                                                날짜 기입도 가능합니다.
                                                <br />
                                                팝업 문구는 최대 3줄까지 노출됩니다.
                                            </div>
                                            <button type="button" className="btn bnt-clear Button">
                                                버튼명
                                            </button>
                                        </Link>
                                    </SwiperSlide> */}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={() => {
                  today();
                  handleClose5();
                }}
              >
                오늘 그만 보기
              </Button>
              <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={handleClose5}
              >
                닫기
              </Button>
            </Modal.Footer>
          </Modal>
          {/* v2 캠퍼스 팝업 Modal */}
          <Modal
            show={show6}
            onHide={handleClose6}
            keyboard={false}
            size="sm"
            className="demoModal"
            dialogClassName="modal-brand"
          >
            <div className="mx-auto arrowDown">
              <img
                className="img-fluid"
                alt=""
                src="https://www.flexdaycdn.net/public/images/ticket/prd-down.png"
              />
            </div>
            <Modal.Body>
              <div className="selectInputbox">
                {localStorage.getItem("defaultStore") === "paik" ? (
                  <input
                    className="form-check-input"
                    id="SelectBrand1"
                    type="radio"
                    name="SelectBrand"
                    defaultChecked
                  />
                ) : (
                  <input
                    className="form-check-input"
                    id="SelectBrand1"
                    type="radio"
                    name="SelectBrand"
                  />
                )}

                <label
                  className="RadioLabel"
                  htmlFor="SelectBrand1"
                  onClick={() => {
                    ChangeStore("paik");
                  }}
                >
                  인제대학교 의과대학(다인)
                </label>
                {userInfo && userInfo.defaultDomain === "paik" ? (
                  <button
                    type="button"
                    className="btn btn-default btnBasic check"
                  >
                    기본
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-default btnBasic "
                    onClick={() => {
                      ChangeDefaultStore("paik");
                    }}
                  >
                    기본
                  </button>
                )}
              </div>
              <div className="selectInputbox">
                {localStorage.getItem("defaultStore") === "inje" ? (
                  <input
                    className="form-check-input"
                    id="SelectBrand2"
                    type="radio"
                    name="SelectBrand"
                    defaultChecked
                  />
                ) : (
                  <input
                    className="form-check-input"
                    id="SelectBrand2"
                    type="radio"
                    name="SelectBrand"
                  />
                )}
                <label
                  className="RadioLabel"
                  htmlFor="SelectBrand2"
                  onClick={() => {
                    ChangeStore("inje");
                  }}
                >
                  인제대학교 늘빛관(다인)
                </label>

                {userInfo && userInfo.defaultDomain === "inje" ? (
                  <button
                    type="button"
                    className="btn btn-default btnBasic check"
                    onClick={() => {
                      ChangeDefaultStore("inje");
                    }}
                  >
                    기본
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-default btnBasic "
                    onClick={() => {
                      ChangeDefaultStore("inje");
                    }}
                  >
                    기본
                  </button>
                )}
              </div>
              <div className="selectInputbox">
                {localStorage.getItem("defaultStore") === "dasom" ? (
                  <input
                    className="form-check-input"
                    id="SelectBrand2"
                    type="radio"
                    name="SelectBrand"
                    defaultChecked
                  />
                ) : (
                  <input
                    className="form-check-input"
                    id="SelectBrand2"
                    type="radio"
                    name="SelectBrand"
                  />
                )}
                <label
                  className="RadioLabel"
                  htmlFor="SelectBrand2"
                  onClick={() => {
                    ChangeStore("dasom");
                  }}
                >
                  인제대학교 다솜식당
                </label>

                {userInfo && userInfo.defaultDomain === "dasom" ? (
                  <button
                    type="button"
                    className="btn btn-default btnBasic check"
                    onClick={() => {
                      ChangeDefaultStore("dasom");
                    }}
                  >
                    기본
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-default btnBasic "
                    onClick={() => {
                      ChangeDefaultStore("dasom");
                    }}
                  >
                    기본
                  </button>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>

        {/* v2 광고 팝업 Modal */}
        {Boolean(adPopup) && (
          <Modal
            show={show8}
            onHide={handleClose8}
            keyboard={false}
            size="sm"
            className="demoModal user adPopup"
            dialogClassName="modal-popup"
          >
            <Modal.Body className="adPopup">
              {adPopup && adPopup.length > 1 && (
                <div className="swiper-pagination Popup-pag"></div>
              )}
              <div className="Popup-Slider">
                <Swiper
                  loop={adPopup && adPopup.length <= 0 ? true : false}
                  pagination={{
                    el: ".Popup-pag",
                    type: "fraction",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper d-flex"
                >
                  {adPopup && adPopup.slice(0, 1).length > 0 ? (
                    adPopup.map((row) => {
                      return (
                        <SwiperSlide
                          className="pop-custom typeA"
                          key={row.advertisementId}
                        >
                          <button
                            style={{
                              border: "none",
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={() => {
                              if (row.link) {
                                window.open(row.link);
                              }
                            }}
                          >
                            <img
                              src={row.titleBannerUrl}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                objectPosition: "top center",
                                position: "absolute",
                                left: 0,
                                top: 0,
                              }}
                              alt="adBanner"
                            />
                            <div
                              className="Tit"
                              style={{ position: "relative" }}
                            >
                              {/* <div
                                style={{ whiteSpace: "pre-line" }}
                                dangerouslySetInnerHTML={{
                                  __html: row.title,
                                }}
                              ></div> */}
                            </div>
                            {/* {Boolean(row.advertisementId) && (
                              <button
                                type="button"
                                className="btn bnt-clear Button"
                                onClick={() => {
                                  window.open(row.detailBannerUrl, "_blank");
                                }}
                                style={{ position: "relative" }}
                              >
                                자세히보기
                              </button>
                            )} */}
                          </button>
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Swiper>
              </div>
            </Modal.Body>

            <Modal.Footer>
              {/* <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={() => {
                  thisWeekAdFalse();
                  handleClose8();
                }}
              >
                이번 주 보지 않기
              </Button> */}
              <Button
                variant="modal-secondary"
                className="btn-clear"
                onClick={handleClose8}
                style={{ width: "100%" }}
              >
                닫기
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <Footer />
      </div>
    </>
  );
}
