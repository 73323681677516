import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import "./Intro.css";
import { Spinner } from "react-bootstrap";
import { AlertError, getApiUrl } from "../../function/common";
import axios from "axios";

export default function Intro() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  function setDefaultStore(store) {
    var variables = {
      domain: store,
    };

    //서비스 호출
    axios
      .put(getApiUrl("v1/users/me/default-store"), variables, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("defaultStore", store);
          navigate("/storemain");
        }
      })
      .catch(function (error) {
        AlertError(error);
        localStorage.clear();
        navigate("/");
      });
  }

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TIntroPage">
          <div className="container-fluid g-0">
            <div className="IntroBox px-4-5">
              <img
                className="img-fluid man"
                src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_img.png"
                alt=""
              />

              <div className="IntroContent">
                <div className="Intro-tit">
                  반가워요 {localStorage.getItem("userName")} 님!
                </div>
                <div className="Intro-sub">어떤 식당을 방문하시나요?</div>

                {/* ver3 레이아웃 및 내용 수정 */}
                <div className="Intro-Selectbox">
                  <div className="sel_tit d-flex">
                    <img
                      className="img-fluid logo"
                      src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_logo1.png"
                      alt=""
                    />
                    <p>인제대학교 김해캠퍼스</p>
                  </div>

                  <div className="Intro-Select">
                    <a
                      onClick={() => {
                        setDefaultStore("inje");
                      }}
                      className="select-item"
                    >
                      <div className="imgbox">
                        <img
                          className="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/cam1_1.png"
                          alt=""
                        />
                        <div className="imgtxt">늘빛관 다인</div>
                      </div>
                      <p className="tit">
                        <img
                          className="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/map_icon.png"
                          alt=""
                        />
                        늘빛관 1층
                      </p>
                    </a>

                    <a
                      onClick={() => {
                        setDefaultStore("dasom");
                      }}
                      className="select-item new"
                    >
                      <div className="imgbox">
                        <img
                          className="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/cam1_2.png"
                          alt=""
                        />
                        <div className="imgtxt">다솜식당</div>
                      </div>
                      <p className="tit">
                        <img
                          className="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/map_icon.png"
                          alt=""
                        />
                        하연관(A동) 2층
                      </p>
                    </a>
                  </div>
                </div>
                <div class="Intro-Selectbox last">
                  <div class="sel_tit d-flex">
                    <img
                      class="img-fluid logo"
                      src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_logo1.png"
                      alt=""
                    />
                    <p>인제대학교 부산캠퍼스</p>
                  </div>

                  <div class="Intro-Select">
                    <a
                      onClick={() => {
                        setDefaultStore("paik");
                      }}
                      className="select-item"
                    >
                      <div class="imgbox">
                        <img
                          class="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/cam1_3.png"
                          alt=""
                        />
                        <div class="imgtxt">의과대학 다인</div>
                      </div>
                      <p class="tit">
                        <img
                          class="img-fluid logo"
                          src="https://www.flexdaycdn.net/public/images/ticket_ver3/map_icon.png"
                          alt=""
                        />
                        의과대학 12층
                      </p>
                    </a>
                  </div>
                </div>

                {/* <div className="Intro-Select">
                                    <a onClick={() => {setDefaultStore('inje')}} className="select-item">
                                        <img className="img-fluid logo" src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_logo1.png" alt="" />
                                        <p className="txt mb-0">인제대 김해캠퍼스</p>
                                    </a>
                                    <a onClick={() => {setDefaultStore('dasom')}} className="select-item">
                                        <img className="img-fluid logo" src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_logo2.png" alt="" />
                                        <p className="txt mb-0">
                                            다솜 식당
                                        </p>
                                    </a>
                                    <a onClick={() => {setDefaultStore('paik')}} className="select-item">
                                        <img className="img-fluid logo" src="https://www.flexdaycdn.net/public/images/ticket_ver2/intro_logo2.png" alt="" />
                                        <p className="txt mb-0">
                                            인제대 부산캠퍼스 <br />
                                            <span className="small">(의과대학)</span>
                                        </p>
                                    </a>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
