import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import "./Login_v1.css";
import axios from "axios";
import { getApiUrl } from "../../function/common";

import { PrintConsole, sleep } from "../../function/common";
import { GetUserMe } from "../../api/ApiGetStoreUser";
import { GetAuthorize } from "../../api/ApiGetUser";
import AppleLogin from "react-apple-login";

import Modal from "react-bootstrap/Modal";
import { Image, Button } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function Login() {
  const navigate = useNavigate();

  // 모달
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [account, setAccount] = useState({
    Id: "",
    Password: "",
    clientId: "10000",
  });

  const onChangeAccount = (e) => {
    //console.log(e.target.value);
    setAccount({
      ...account,
      [e.target.id]: e.target.value,
    });
  };

  function today() {
    localStorage.setItem("TodayPopup", "false");
    // handleClose();
  }

  const [searchParams, setSearchParams] = useSearchParams(
    window.location.href.split("/login#")[1]
  );
  const NaverLogin = () => {
    const { naver } = window;

    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_ID,
      callbackUrl: process.env.REACT_APP_NAVER_CALLBACK,
      // for local test
      // TODO : 돌려놓기
      // callbackUrl: "http://192.168.50.107:3000/sns/naver",
      isPopup: false /* 팝업을 통한 연동처리 여부, true 면 팝업 */,
      loginButton: {
        color: "green",
        type: 1,
        height: 47,
      } /* 로그인 버튼의 타입을 지정 */,
      callbackHandle: true,
    });

    naverLogin.init();

    naverLogin.getLoginStatus(async function (status) {
      if (status) {
        const id = naverLogin.user.getId();
        const name = naverLogin.user.getName();
        const mobile = naverLogin.user.getMobile();

        console.log(searchParams.get("access_token"));

        let obj = {
          oauthAccessToken: searchParams.get("access_token"),
          oauthType: "NAVER",
        };
        const response = await axios
          .post(
            getApiUrl("v1/oauth/login"),
            obj,
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
            (axios.defaults.headers.common["ContentType"] = "application/json;")
          )
          .then(function (response) {
            console.log("----------------------------------");
            console.log(response);
            if (response.status === 200) {
              console.log(response);
            }
          })
          .catch(function (error) {
            console.log("error");
            // AlertError(error);
            // document.getElementById('submitBtn').removeAttribute("disabled");
          });
      }
    });
  };
  const APPLE_CALL_BACK = process.env.REACT_APP_APPLE_CALLBACK;

  const KakaoLogin = () => {
    const REST_API_KEY = "55c49dcd60e7316f131543bf9696059f";
    // const REDIRECT_URI = 'https://ivoucher.kr/sns/kakao';
    const REDIRECT_URI = process.env.REACT_APP_KAKAO_CALLBACK;
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;
    window.location.href = KAKAO_AUTH_URL;
  };

  function getUser() {
    return new Promise(async function (resolve, reject) {
      const response = await axios
        .get(
          getApiUrl("v1/users/me"),
          (axios.defaults.headers.common["ContentType"] = "application/json;"),
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken"))
        )
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.name === undefined) {
              localStorage.setItem("userName", "이름없음");
              localStorage.setItem("oauthType", response.data.oauthType);
              localStorage.setItem("defaultStore", response.data.defaultDomain);
            } else if (response.data.name === null) {
              localStorage.setItem("userName", "이름없음");
              localStorage.setItem("oauthType", response.data.oauthType);
              localStorage.setItem("defaultStore", response.data.defaultDomain);
            } else {
              localStorage.setItem("userName", response.data.name);
              localStorage.setItem("oauthType", response.data.oauthType);
              localStorage.setItem("defaultStore", response.data.defaultDomain);
            }
          }
        })
        .catch(function (error) {
          PrintConsole(error);
          localStorage.clear();
          navigate("/");
        });
    });
  }

  useEffect(() => {
    // console.log(localStorage.getItem('accessToken'));
    if (
      localStorage.getItem("accessToken") !== undefined &&
      localStorage.getItem("accessToken") !== null
    ) {
      getUser();

      // console.log(localStorage.getItem('oauthType'));
      if (
        localStorage.getItem("oauthType") !== undefined &&
        localStorage.getItem("oauthType") !== null
      ) {
        if (localStorage.getItem("oauthType") !== "NONE") {
          navigate("/storemain");
        }
      }
    }

    if (localStorage.getItem("TodayPopup") !== "false") {
      // handleShow();
    }
    // localStorage.clear('TodayPopup');
    NaverLogin();
  }, []);

  return (
    <>
      <div className="demo">
        <div className="TLoginPage">
          <div className="container position-absolute start-50 top-50 translate-middle w-100">
            <div className="Inner m-auto p-2">
              <div className="LogoImg text-center">
                <img
                  src="https://www.flexdaycdn.net/public/images/ticket/inje_logo2.png"
                  alt="FLEX"
                />
              </div>

              <div className="LoginBox">
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-kakao"
                    onClick={() => {
                      KakaoLogin();
                    }}
                  >
                    <img
                      src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png"
                      alt="kakao"
                      className="img-fluid"
                    />
                    카카오로 간편시작
                  </button>
                </div>
                <div className="row">
                  <div style={{ display: "none" }}>
                    <div id="naverIdLogin"></div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-naver"
                    onClick={() => {
                      document
                        .getElementById("naverIdLogin_loginButton")
                        .click();
                    }}
                  >
                    <img
                      src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png"
                      alt="naver"
                      className="img-fluid"
                    />
                    네이버로 간편시작
                  </button>
                </div>
                <div className="row">
                  <div style={{ display: "none" }}>
                    <AppleLogin
                      clientId={"com.flexday.loginservice"}
                      redirectURI={APPLE_CALL_BACK}
                      scope={"email name"}
                      responseType={"code id_token"}
                      responseMode={"form_post"}
                      usePopup={false}
                      className="btn btn-default"
                      designProp={{
                        height: 30,
                        width: 140,
                        color: "black",
                        border: false,
                        type: "sign-in",
                        border_radius: 15,
                        scale: 1,
                        locale: "en_US",
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      document.getElementById("appleid-signin").click();
                    }}
                  >
                    <img
                      src="https://www.flexdaycdn.net/public/images/ticket/apple_icon3.png"
                      alt="apple"
                      className="img-fluid"
                    />
                    Sign in with Apple
                  </button>
                </div>
                <div className="row">
                  <button
                    onClick={() => {
                      navigate("/nonuserlogin");
                    }}
                    type="button"
                    className="btn btn-light"
                  >
                    비회원으로 시작하기
                  </button>
                </div>
              </div>
            </div>
            <div style={{ color: "#d3d3d3", textAlign: "center" }}>
              {process.env.REACT_APP_VERSION}
            </div>
          </div>
        </div>

        {/* 이벤트 팝업 Modal */}
        {/* <Modal
                    show={show}
                    onHide={handleClose}
                    keyboard={false}
                    size="sm"
                    className="demoModal"
                    dialogClassName="modal-popup"
                    >
                    <Modal.Body>
                        <div className="swiper-pagination Popup-pag"></div>
                        <div className="Popup-Slider">
                            <Swiper loop={false} pagination={{ el: ".Popup-pag", type: "fraction" }} modules={[Pagination, Navigation]} className="mySwiper d-flex">
                                    <SwiperSlide className="pop-slide2">
                                        <a href="https://apps.apple.com/kr/app/%EC%9D%B8%EC%A0%9C-%ED%8E%98%EC%9D%B4/id1671936601" target="_blank"> 
                                            <div className="Tit">
                                                인제페이 <br />
                                                <div className="span">
                                                    iOS APP
                                                    <Image src="https://www.flexdaycdn.net/public/images/ticket/pop_apple.png"
                                                    className="img-fluid apple" />
                                                    출시!
                                                </div>
                                            </div>
                                            <div className="Desc">
                                                이제 애플 스토어에서도 인제페이 앱을 
                                                <br />다운받으실 수 있습니다.
                                            </div>
                                            <div className="Badge">
                                                <span className="bold">애플 스토어</span> 바로가기
                                                <Image src="https://www.flexdaycdn.net/public/images/ticket/modal_arrow.png" className="img-fluid arrow" />
                                            </div>
                                            <Image src="https://www.flexdaycdn.net/public/images/ticket/modal_mockup2.png" className="img-fluid MockupImg" />
                                            </a>
                                    </SwiperSlide>
                                    {/* <SwiperSlide className="pop-slide1">
                                        <Link to={"/popup"}>
                                            <div className="Desc">
                                                기다리는 시간 줄어들고, 편리함은 커졌어요
                                            </div>
                                            <div className="Tit">
                                                모바일 식권 서비스 <br />
                                                오픈 EVENT
                                            </div>
                                            <div className="Badge">
                                                <span className="bold">오픈 이벤트</span> 자세히 보기
                                            </div>
                                            <Image src="https://www.flexdaycdn.net/public/images/ticket/modal_mockup.png" className="img-fluid MockupImg" />
                                        </Link>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="modal-secondary" className='btn-clear' onClick={ () => {today();}}>
                        오늘 그만 보기
                        </Button>
                        <Button variant="modal-secondary" className='btn-clear' onClick={handleClose}>
                            닫기
                        </Button>
                    </Modal.Footer>
                </Modal>  */}
      </div>
    </>
  );
}
