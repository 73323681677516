import {
  Container,
  Row,
  Col,
  Form,
  Image,
  ButtonGroup,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import Btn from "./../../components/common/Btn";
import { useEffect, useRef } from "react";
import { useState } from "react";

import { loadTossPayments } from "@tosspayments/payment-sdk";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
  useParams,
} from "react-router-dom";
import { AlertError, getApiUrl, PrintConsole } from "../../function/common";
import axios from "axios";
import Footer from "../../components/common/Footer";
// import QRFooter from './QRFooter';
import { setCommas, getTextLength } from "./../../function/common";
import Serviceterms from "./../../components/common/ServiceTerms";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./Gift.css";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY;

export default function Gift() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // 모달
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // 회원 확인 모달
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow(false);
    setShow3(false);
    setShow2(true);
  };

  // 등록 회원 오류 모달
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => {
    setShow(false);
    setShow2(false);
    setShow3(true);
  };

  const { number } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [qr, setQR] = useState();

  const [name, setName] = useState();
  const nameChange = (event) => {
    setName(event.target.value);
  };

  const TmpLoginType = useRef();
  const payMode = useRef();

  function LoginType(Type) {
    TmpLoginType.current = Type;
    console.log(TmpLoginType.current);
    console.log(Type);
  }

  function getQRCode() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        isUsed: true,
      };
      const response = await axios
        .get(
          getApiUrl("v1/coupons/" + number),

          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setQR(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          console.log("error");
        });
    });
  }

  const [giftUser, setGiftUser] = useState([]);
  const getGiftQuestion = async (e) => {
    let obj = {
      name: document.getElementById("recipientName").value,
      phoneNumber: document.getElementById("recipientPhoneNumber").value,
    };
    await axios
      .post(
        getApiUrl("v1/coupons/" + number + "/gift-can"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          SendQRCode();
        }
      })
      .catch(function (error) {
        if (error.response.data.errorDetails.length === 0) {
          //없음
          handleShow3();
        } else {
          //여러개
          setGiftUser(error.response.data.errorDetails);
          handleShow2();
        }
      });
  };

  const SendQRCode = async () => {
    let obj = {
      name: document.getElementById("recipientName").value,
      phoneNumber: document.getElementById("recipientPhoneNumber").value,
    };
    if (TmpLoginType.current !== null) {
      obj = {
        name: document.getElementById("recipientName").value,
        phoneNumber: document.getElementById("recipientPhoneNumber").value,
        oauthType: TmpLoginType.current,
      };
    }

    const response = await axios
      .post(
        getApiUrl("v1/coupons/" + number + "/gift"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          console.log(response);
          navigate(
            "/giftsuccess/" +
              response.data.couponName +
              "/" +
              response.data.expiredAt +
              "/" +
              response.data.oauthType +
              "/" +
              response.data.storeName +
              "/" +
              response.data.toName +
              "/" +
              response.data.toPhoneNumber
          );
        }
      })
      .catch(function (error) {
        AlertError(error);
        // console.log('error');
      });
  };

  useEffect(() => {
    getQRCode();
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TGiftPage">
          <div className="container-fluid g-0">
            {/* <nav className="sub-navbar"> */}
            <nav className="sub-navbar">
              <p className="navbar-title">선물하기</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="GiftBox my-4-5">
              <div className="GiftItem pb-4-5 px-4-5">
                <p className="PageTit2 mb-2">
                  식권 정보
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/static/flexdya-cms/emoji_hand2.png"
                    alt=""
                  />
                </p>
                <div className="justify-content-around g-0 row">
                  <div className="col">
                    <p className="prd-info">식권/수량</p>
                  </div>
                  <div className="col-7">
                    <p className="prd-sub">{qr && qr.productName} / 1개</p>
                  </div>
                </div>
                <div className="justify-content-around g-0 row">
                  <div className="col">
                    <p className="prd-info">매장</p>
                  </div>
                  <div className="col-7">
                    <p className="prd-sub">{qr && qr.storeName}</p>
                  </div>
                </div>
                <div className="justify-content-around g-0 row">
                  <div className="col">
                    <p className="prd-info">유효기간</p>
                  </div>
                  <div className="col-7">
                    <p className="prd-sub">~{qr && qr.expireDate}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="GiftBox bottom px-4-5 my-4-5">
              <div className="infobox">
                <p className="tit">
                  <img
                    className="img-fluid"
                    src="https://www.flexdaycdn.net/public/images/ticket/info-icon.png"
                    alt=""
                  />
                  안내사항
                </p>
                <p className="info-txt">
                  • 선물을 받으시는 분의 휴대폰에 해당 어플이 설치되어 있어야
                  합니다.
                </p>
                <p className="info-txt mb-0">
                  • 선물을 받으시는 분이 회원가입되어 있어야 합니다.
                </p>
              </div>

              <div className="recipient">
                <p className="tit">선물 받으시는 분 정보를 입력해주세요.</p>
                <div className="Gift-Inputbox mb-2">
                  <input
                    type="text"
                    id="recipientName"
                    className="form-control InputType"
                    placeholder="이름(실명을 입력해주세요.)"
                    onChange={nameChange}
                  />
                </div>
                <div className="Gift-Inputbox">
                  <input
                    type="number"
                    id="recipientPhoneNumber"
                    pattern="\d*"
                    className="form-control InputType"
                    placeholder="연락처(’-’를 제외하고 입력해주세요.)"
                  />
                </div>
              </div>

              <div className="Gift-Confirm pb-4">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleShow}
                >
                  선물보내기
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 선물하기 Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-prdAdd text-center"
        >
          <Modal.Header>
            <Modal.Title>
              ‘{name}’님에게 <br />
              식권을 선물하시겠습니까?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>본인 QR지갑에서 해당 식권이 삭제됩니다.</Modal.Body>
          <Modal.Footer>
            <Button
              variant="modal-secondary"
              className="btn-clear"
              onClick={handleClose}
            >
              취소
            </Button>
            <Button
              variant="modal-primary"
              className="btn-clear"
              onClick={() => {
                getGiftQuestion();
              }}
            >
              확인
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show2}
          onHide={handleClose2}
          keyboard={false}
          size="sm"
          centered
          className="demoModal modal-gift text-center"
        >
          <Modal.Header>
            <Modal.Title>
              입력하신 정보로
              <br />
              {giftUser && giftUser.length}개의 계정이 확인됩니다.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>선물보내실 1개의 계정을 선택해주세요.</Modal.Body>
          <div className="gift-member">
            {giftUser &&
              giftUser.map((row, idx) => (
                <div className="member-item">
                  <input
                    className="form-check-input"
                    id={"giftMember" + idx}
                    type="radio"
                    name="giftMember"
                  />
                  <label
                    className="RadioLabel"
                    htmlFor={"giftMember" + idx}
                    labeltype={row.reason}
                    onClick={() => {
                      LoginType(row.reason);
                    }}
                  >
                    {name}
                    {row.reason === "KAKAO" ? (
                      <div className="imgbox kakao">
                        <img
                          src="https://www.flexdaycdn.net/public/images/ticket/kakao_icon.png"
                          alt="kakao"
                          className="img-fluid sns-icon"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {row.reason === "NAVER" ? (
                      <div className="imgbox naver">
                        <img
                          src="https://www.flexdaycdn.net/public/images/ticket/naver_icon.png"
                          alt="naver"
                          className="img-fluid sns-icon"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {row.reason === "APPLE" ? (
                      <div className="imgbox apple">
                        <img
                          src="https://www.flexdaycdn.net/public/images/ticket/apple_icon.png"
                          alt="apple"
                          className="img-fluid sns-icon"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
              ))}
          </div>
          <Modal.Footer>
            <Button
              variant=""
              className="btn-clear btn-giftBtn w-100"
              onClick={() => {
                SendQRCode();
              }}
            >
              선물 보내기
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show3}
          onHide={handleClose3}
          keyboard={false}
          size="sm"
          centered
          className="demoModal text-center"
        >
          <Modal.Header>
            <Modal.Title>등록된 회원이 아닙니다.</Modal.Title>
          </Modal.Header>
          <Modal.Body>입력하신 이름과 연락처를 다시 확인해주세요.</Modal.Body>
          <Modal.Footer>
            <Button
              variant="modal-secondary"
              className="btn-clear"
              onClick={handleClose3}
            >
              취소
            </Button>
            <Button
              variant="modal-primary"
              className="btn-clear"
              onClick={handleClose3}
            >
              확인
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
