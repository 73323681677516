import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import Header from "./components/Header";
import "./OrderStatus.css";
import axios from "axios";
import { AlertError, getApiUrl } from "../../function/common";

export default function OrderUnuse() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { number } = useParams();

  const [orderStatus, setOrderStatus] = useState();
  function getOrderStatus() {
    return new Promise(async function (resolve, reject) {
      var obj = {
        smartOrderId: number,
      };

      const response = await axios
        .get(
          getApiUrl("v1/coupons-sorder/" + number),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            setOrderStatus(response.data);
            // console.log(response.data);
          }
          setLoading(false);
        })
        .catch(function (error) {
          // console.log('error');
          AlertError("error");
          // setLoading(false);
        });
    });
  }

  useEffect(() => {
    getOrderStatus();
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TOrderStatusPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">주문 현황</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="OrderStatusBox px-4-5">
              <div className="title_box text-center">
                <img
                  className="img-fluid mb-4"
                  src="https://www.flexdaycdn.net/public/images/ticket_ver3/emoji_angry.png"
                  alt=""
                />
                <p className="tit">사용 불가 안내</p>
                <p className="desc">
                  메뉴 가격이 인상됨에 따라 <br />
                  해당 식권은 사용이 불가능합니다
                </p>
                <p className="desc">
                  사용하지못하신 구매 식권은 <br />
                  <span className="bold">일괄적으로</span> 취소 처리 됩니다
                </p>
              </div>

              <div className="status_box">
                <div className="unuse_box">
                  <p className="txt1">
                    추가 문의사항은 고객센터를 이용 바랍니다
                  </p>
                  <p className="txt2">
                    고객센터 : 051-621-0203 <br />
                    (평일 10:00 18:00, 점심시간 12:30 13:30)
                  </p>
                </div>
              </div>

              <div className="my_order">
                <div className="sub_tit">사용 불가 식권</div>
                <div className="order_list">
                  <div className="order_item">
                    <p className="prd_name">
                      {orderStatus && orderStatus.productName} / 1개
                    </p>
                    <p className="store_name mb-0">
                      {orderStatus && orderStatus.storeName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
