import {
  Container,
  Row,
  Col,
  Form,
  Image,
  ButtonGroup,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useEffect, useRef } from "react";
import { useState } from "react";

import {
  useSearchParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import axios from "axios";

import AdminHeader from "./components/AdminHeader";
import "./StampReward.css";
import Header from "./components/Header";

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY;

export default function SalePrd() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //데이터 로딩
    setLoading(true);

    //데이터 로딩 완료
    setLoading(false);
  }, []);

  if (loading) return <></>;

  return (
    <>
      <div className="demo">
        <div className="TStampRewardPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">스탬프 리워드</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="StampReward-Top text-center mb-4-5 px-4-5">
              <p className="gMarket bold stamp-badge">STAMP REWARD</p>
              <p className="gMarket bold stamp-tit">나의 스탬프 리워드</p>
              <p className="gMarket stamp-desc">
                <span className="gMarket bold">A, B, C코너</span> 메뉴 한 개
                이용 후
                <br />
                만족도 조사 참여 시,
                <br className="mob-320" />{" "}
                <span className="gMarket bold">스탬프 1개</span> 적립
              </p>
              <p className="gMarket stamp-date">2023.02.23 - 2023.03.23</p>
              <div className="stamp-stateBox">
                <div className="stamp-state d-flex">
                  <div className="gMarket tit">탄산음료 (지급권)</div>
                  <div className="gMarket num">
                    <span className="color">1</span> / 10
                  </div>
                </div>
                <div className="stamp-state d-flex">
                  <div className="gMarket tit">아이패드 (응모권)</div>
                  <div className="gMarket num">
                    <span className="color">1</span> / 15
                  </div>
                </div>
              </div>
            </div>
            <div className="StampReward-Bottom text-center mb-4-5 px-4-5">
              <p className="gMarket bold stamp-badge">스탬프 적립 현황</p>
              <div className="stamp-stepBox">
                <div className="stamp-stepRow row1 d-flex">
                  <div className="stamp-step on">
                    <div className="before">1</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">2</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">3</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">4</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="stamp-stepRow-Line line1">
                  <div className="stamp-line"></div>
                </div>

                <div className="stamp-stepRow row2 d-flex">
                  <div className="stamp-step">
                    <div className="before">5</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">6</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">7</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="stamp-stepRow-Line line2">
                  <div className="stamp-line"></div>
                </div>

                <div className="stamp-stepRow row1 d-flex">
                  <div className="stamp-step">
                    <div className="before">8</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">9</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                    {/*  문구 추가 */}
                    <div className="step-txt gMarket">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/stamp-check2.png"
                        alt=""
                      />
                      1개 더 구매 시 <br />
                      리워드 증정!
                    </div>
                  </div>
                  <div className="stamp-step on">
                    <div className="before">10</div>
                    <div className="after get">
                      {/* 
                                            className="get" 추가
                                            GET 아이콘 추가 
                                        */}
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">11</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="stamp-stepRow-Line line1">
                  <div className="stamp-line"></div>
                </div>

                <div className="stamp-stepRow row2 d-flex">
                  <div className="stamp-step">
                    <div className="before">12</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">13</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="stamp-step">
                    <div className="before">14</div>
                    <div className="after">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp.png"
                        alt=""
                      />
                    </div>
                    {/*  문구 추가 */}
                    <div className="step-txt gMarket">
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/stamp-check2.png"
                        alt=""
                      />
                      1개 더 구매 시 <br />
                      리워드 증정!
                    </div>
                  </div>
                </div>

                <div className="stamp-stepRow-Line line2">
                  <div className="stamp-line"></div>
                </div>

                <div className="stamp-stepRow row1 d-flex">
                  <div className="stamp-step finish on">
                    <div className="before">15</div>
                    <div className="after finish-get">
                      {/* 
                                            마지막 스탬프 이미지 다름 
                                            마지막 스탬프 className="finish-get" 추가
                                            => GET 아이콘 추가
                                        */}
                      <img
                        src="https://www.flexdaycdn.net/public/images/ticket/inje-stamp-finish.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
