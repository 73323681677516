import React from "react";
import { Spinner } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { getApiUrl } from "../../function/common";

import Serviceterms from "../UserMain/components/ServiceTerms";
import Userterms from "../UserMain/components/Userterms";
import Userterms2 from "../UserMain/components/Userterms2";
import ConsignmentTerms from "../UserMain/components/ConsignmentTerms";

import "../UserMain/AppleRegister.css";

export default function Apple() {
  const { name } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState("STEP1");

  const tmpAT = useRef("");
  const tmpName = useRef("");
  const tmpAuthToken = useRef("");

  const tmpMarketingConsent = useRef(false);
  const tmpAppPush = useRef(false);
  const tmpSms = useRef(false);

  const [isCheckEssential, setisCheckEssential] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([
    { id: "c1" },
    { id: "c2" },
    { id: "c3" },
    { id: "c4" },
    { id: "c5" },
    { id: "c5-1" },
    { id: "c5-2" },
  ]);

  const [isCheckAllSub, setIsCheckAllSub] = useState(false);
  const [isCheckSub, setIsCheckSub] = useState([]);
  const [listSub, setListSub] = useState([
    { id: "c1" },
    { id: "c2" },
    { id: "c3" },
    { id: "c4" },
    { id: "c5" },
    { id: "c5-1" },
    { id: "c5-2" },
  ]);

  const handleSelectAllSub = (e) => {
    let Total = document.querySelectorAll("input[name=Check]").length;
    let Checked = document.querySelectorAll("input[name=Check]:checked").length;

    setIsCheckAllSub(!isCheckAllSub);

    setIsCheckSub(listSub.map((li) => li.id));
    if (isCheckAllSub) {
      setIsCheckSub([]);
      setIsCheckAll(false);
    } else if (!isCheckAllSub && Total == Checked) {
      setIsCheckAll(true);
    } else {
    }
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheckAllSub(!isCheckAllSub);

    setIsCheck(list.map((li) => li.id));
    setIsCheckSub(listSub.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setIsCheckSub([]);
      setIsCheckAllSub(false);
      setisCheckEssential(false);
    } else {
      setIsCheckAllSub(true);
      setisCheckEssential(true);
    }
  };

  const handleClickSub = (e) => {
    const { id, checked } = e.target;
    setIsCheckSub([...isCheckSub, id]);

    let c1 = document.getElementById("c1");
    let c2 = document.getElementById("c2");
    let c3 = document.getElementById("c3");
    let c4 = document.getElementById("c4");
    // let c5 = document.getElementById('c5');
    let c5_1 = document.getElementById("c5-1");
    let c5_2 = document.getElementById("c5-2");

    if (!checked) {
      setIsCheckSub(isCheckSub.filter((item) => item !== id));
    }

    if (
      c1.checked &&
      c2.checked &&
      c3.checked &&
      c4.checked &&
      c5_1.checked &&
      c5_2.checked
    ) {
      setIsCheckAll(true);
      setIsCheckAllSub(true);
    } else if (c5_1.checked && c5_2.checked) {
      setIsCheckAllSub(true);
    } else {
      setIsCheckAll(false);
      setIsCheckAllSub(false);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);

    let c1 = document.getElementById("c1");
    let c2 = document.getElementById("c2");
    let c3 = document.getElementById("c3");
    let c4 = document.getElementById("c4");
    // let c5 = document.getElementById('c5');
    let c5_1 = document.getElementById("c5-1");
    let c5_2 = document.getElementById("c5-2");

    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }

    if (
      c1.checked &&
      c2.checked &&
      c3.checked &&
      c4.checked &&
      c5_1.checked &&
      c5_2.checked
    ) {
      setisCheckEssential(true);
      setIsCheckAll(true);
    } else {
      setisCheckEssential(false);
      setIsCheckAll(false);
    }
  };

  function RequiredCheck() {
    let Total = document.querySelectorAll("input:required").length;
    let Checked = document.querySelectorAll("input:required:checked").length;

    if (Total !== Checked) {
      document.querySelector(".AlertBox").classList.add("active");
    } else {
      document.querySelector(".AlertBox").classList.remove("active");

      if (document.getElementById("c5").checked) {
        tmpMarketingConsent.current = true;
      } else {
        tmpMarketingConsent.current = false;
      }
      if (document.getElementById("c5-1").checked) {
        tmpAppPush.current = true;
      }
      if (document.getElementById("c5-2").checked) {
        tmpSms.current = true;
      }
      setStage("STEP2");
    }
  }

  const signup = async () => {
    let eventAlarmTypes = [];
    let marketingConsent = tmpMarketingConsent.current;

    if (tmpAppPush.current === true) {
      eventAlarmTypes.push("APP_PUSH");
    }
    if (tmpSms.current === true) {
      eventAlarmTypes.push("SMS");
    }

    let obj = {
      eventAlarmTypes: eventAlarmTypes,
      marketingConsent: marketingConsent,
      oauthAccessToken: tmpAT.current,
      oauthType: "APPLE",
      appleSignUp: {
        name: document.getElementById("name").value,
        phoneNumber: document.getElementById("phoneNumber").value,
        authNumber: document.getElementById("authNumber").value,
        authToken: tmpAuthToken.current,
      },
    };
    // console.log(obj);
    const response = await axios
      .post(
        getApiUrl("v1/oauth/signup"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          // console.log(response.data);
          localStorage.setItem(
            "accessToken",
            response.data.loginToken.accessToken
          );
          localStorage.setItem("defaultStore", response.data.defaultDomain);
          localStorage.setItem("userName", response.data.name);
          navigate("/storemain");
        }
      })
      .catch(function (error) {
        console.log("error");
      });
  };

  const sms = async () => {
    let obj = {
      name: document.getElementById("name").value,
      phoneNumber: document.getElementById("phoneNumber").value,
    };
    // console.log(obj);
    const response = await axios
      .post(
        getApiUrl("v1/auth/sms"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        if (response.status === 200) {
          // console.log(response.data);
          tmpAuthToken.current = response.data.authToken;

          document.getElementById("Submit").classList.remove("done");
          //   document.getElementById("SubmitBtn").addEventListener("click", () => {
          //     signup();
          //   });
          // document.getElementById('ReSend').addEventListener('click', () => {sms();})

          // localStorage.setItem('accessToken',response.data.loginToken.accessToken);
          // navigate('/storemain');
        }
      })
      .catch(function (error) {
        console.log("error");
      });
  };

  const AppleLogin = async () => {
    let obj = {
      oauthAccessToken: window.location.href.split("?id_token=")[1],
      oauthType: "APPLE",
    };
    // console.log(obj);
    const response = await axios
      .post(
        getApiUrl("v1/oauth/login"),
        obj,
        (axios.defaults.headers.common["ContentType"] = "application/json;")
      )
      .then(function (response) {
        // console.log('----------------------------------');
        // console.log(response);
        if (response.status === 200) {
          if (response.data.signedUp === true) {
            localStorage.setItem(
              "accessToken",
              response.data.loginToken.accessToken
            );
            localStorage.setItem("defaultStore", response.data.defaultDomain);
            // console.log(localStorage.getItem('accessToken'));
            navigate("/storemain");
          } else {
            localStorage.setItem(
              "TmpAccessToken",
              response.data.oauthAccessToken
            );
            tmpAT.current = response.data.oauthAccessToken;
            tmpName.current = response.data.name;
            // console.log(tmpAT.current);
          }
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log("error");
      });
  };

  useEffect(() => {
    AppleLogin();
  }, []);

  // signup();

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  if (stage === "STEP1") {
    return (
      <>
        <div className="demo">
          <div className="TRegisterPage">
            <div className="container-fluid g-0">
              <nav className="sub-navbar">
                <p className="navbar-title">회원가입</p>
                <button
                  type="button"
                  className="btn-clear btn-close"
                  onClick={() => navigate(-1)}
                >
                  <img
                    className="img-fluid"
                    alt="뒤로가기"
                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png"
                  />
                </button>
              </nav>

              <div className="SignUpBox pb-4-5">
                <div className="SignUp-title text-center">
                  <div className="Inner">
                    <p className="tit mb-1 d-flex justify-content-center">
                      반가워요
                      <img
                        className="img-fluid ms-1"
                        src="https://www.flexdaycdn.net/public/images/ticket/emoji_smile.png"
                        alt=""
                      />
                    </p>
                    <p className="sub mb-0">
                      약관에 동의해주시면 회원가입이 진행됩니다.
                    </p>
                  </div>
                </div>

                <div className="SignUp-Contents Inner">
                  <div className="accordion ver2">
                    <div className="accordion-item">
                      <p className="accordion-headerd d-flex align-items-center">
                        <input
                          className="form-check-input CheckInput1 me-0 AllCheck"
                          name="hobbyAll"
                          id="all"
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={isCheckAll}
                        />
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          플렉스데이 이용약관 전체동의
                        </button>
                      </p>
                      <div
                        id="collapseOne"
                        className="accordion-collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body Input-placeholderbox">
                          <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                            <input
                              className="form-check-input CheckInput2"
                              name="Check"
                              id="c1"
                              type="checkbox"
                              required
                              onChange={handleClick}
                              checked={isCheck.includes("c1")}
                            />
                            <label
                              className="form-check-label CheckLabel"
                              htmlFor="c1"
                            >
                              (필수) 서비스 이용약관
                            </label>
                            <a className="CheckInfoBtn" href="#">
                              <Serviceterms />
                            </a>
                          </div>
                          {/* <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                        <input className="form-check-input CheckInput2" name="Check" id="c2"
                                                            type="checkbox" required onChange={handleClick} checked={isCheck.includes('c2')} />
                                                        <label className="form-check-label CheckLabel" htmlFor="c2">
                                                            (필수) 개인정보 수집 및 이용 동의서
                                                        </label>
                                                        <a className="CheckInfoBtn" href="#">
                                                            <Userterms />
                                                        </a>
                                                    </div> */}
                          <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                            <input
                              className="form-check-input CheckInput2"
                              name="Check"
                              id="c3"
                              type="checkbox"
                              required
                              onChange={handleClick}
                              checked={isCheck.includes("c3")}
                            />
                            <label
                              className="form-check-label CheckLabel"
                              htmlFor="c3"
                            >
                              (필수) 개인정보 처리 방침
                            </label>
                            <a className="CheckInfoBtn" href="#">
                              <Userterms2 />
                            </a>
                          </div>
                          <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                            <input
                              className="form-check-input AllCheckSub CheckInput2"
                              name="Check"
                              id="c5"
                              type="checkbox"
                              onChange={handleSelectAllSub}
                              checked={isCheckAllSub}
                            />
                            <label
                              className="form-check-label CheckLabel"
                              htmlFor="c5"
                            >
                              (선택) 마케팅 정보 수신 동의
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="SignUp-Infobox Inner">
                  <p className="SignUp-checkbox-desc">
                    플렉스데이에서 제공하는 각종 혜택 및 이벤트,{" "}
                    <br className="Mob320" />
                    사은품에 대한 안내를 받을 수 있습니다.
                  </p>
                  <div className="form-check-group g-0 row flex-wrap">
                    <div className="form-check col-4 d-flex align-items-center p-0">
                      <input
                        className="form-check-input CheckInput3"
                        id="c5-1"
                        type="checkbox"
                        name="Check2"
                        onChange={handleClickSub}
                        checked={isCheckSub.includes("c5-1")}
                      />
                      <label
                        className="form-check-label CheckLabel"
                        htmlFor="c5-1"
                      >
                        앱푸시
                      </label>
                    </div>
                    <div className="form-check col-4 d-flex align-items-center p-0">
                      <input
                        className="form-check-input CheckInput3"
                        id="c5-2"
                        type="checkbox"
                        name="Check2"
                        onChange={handleClickSub}
                        checked={isCheckSub.includes("c5-2")}
                      />
                      <label
                        className="form-check-label CheckLabel"
                        htmlFor="c5-2"
                      >
                        문자
                      </label>
                    </div>
                  </div>
                </div>

                <div className="SignUp-Confirm Inner pt-4-5">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={RequiredCheck}
                  >
                    회원가입
                  </button>
                </div>
                <div className="AlertBox Inner mt-3">
                  필수 약관에 동의해주세요.
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (stage === "STEP2") {
    return (
      <>
        <div className="demo">
          <div className="TAppleRegisterPage">
            <div className="container-fluid g-0">
              <nav className="sub-navbar">
                <p className="navbar-title">회원가입</p>
                <button
                  type="button"
                  className="btn-clear btn-close"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img
                    className="img-fluid"
                    alt="뒤로가기"
                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png"
                  />
                </button>
              </nav>

              <div className="AppleRegisterBox bottom px-4-5 my-4-5">
                {/* 인증번호 발송되면 class "done" 추가 */}
                <div className="InputboxWrap">
                  <p className="tit">가입하시는 분의 정보를 입력해 주세요.</p>
                  <div className="AppleRegister-Inputbox mb-2">
                    <input
                      type="text"
                      className="form-control InputType"
                      placeholder="이름(실명을 입력해주세요.)"
                      id="name"
                      value={tmpName.current}
                    />
                  </div>
                  <div className="AppleRegister-Inputbox">
                    <input
                      type="number"
                      pattern="\d*"
                      className="form-control InputType"
                      id="phoneNumber"
                      placeholder="연락처(’-’를 제외하고 입력해주세요.)"
                    />
                  </div>

                  <div className="AppleRegister-Confirm pt-4">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        sms();
                      }}
                    >
                      인증번호 발송
                    </button>
                  </div>
                </div>

                {/* 인증번호 발송되면 노출 + class "done" 삭제 */}
                <div id="Submit" className="InputboxWrap done">
                  <div className="AppleRegister-Inputbox d-flex">
                    <input
                      type="number"
                      pattern="\d*"
                      className="form-control InputType"
                      placeholder="인증번호를 입력해주세요."
                      id="authNumber"
                    />
                    <button
                      type="button"
                      id="ReSend"
                      className="btn btn-default"
                      onClick={() => {
                        sms();
                      }}
                    >
                      재발송
                    </button>
                  </div>
                  <div className="AppleRegister-Confirm pt-4">
                    <button
                      type="button"
                      id="SubmitBtn"
                      className="btn btn-primary"
                      onClick={() => signup()}
                    >
                      회원가입 완료
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
