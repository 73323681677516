import React from "react";
import { Spinner } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { getApiUrl } from "../../function/common";

import Serviceterms from '../UserMain/components/ServiceTerms';
import Userterms from '../UserMain/components/Userterms';
import Userterms2 from '../UserMain/components/Userterms2';
import ConsignmentTerms from '../UserMain/components/ConsignmentTerms';

import '../UserMain/Register.css';

export default function Kakao(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const tmpAT = useRef('');

    const tmpName = useRef('');
    const tmpEmail = useRef('');
    const tmpTel = useRef('');


    const [isCheckEssential, setisCheckEssential] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([
        {'id':'c1'}, {'id':'c2'},{'id':'c3'},{'id':'c4'},{'id':'c5'},{'id':'c5-1'}, {'id':'c5-2'}
    ]);


    const [isCheckAllSub, setIsCheckAllSub] = useState(false);
    const [isCheckSub, setIsCheckSub] = useState([]);
    const [listSub, setListSub] = useState([
        {'id':'c1'}, {'id':'c2'},{'id':'c3'},{'id':'c4'},{'id':'c5'},{'id':'c5-1'}, {'id':'c5-2'}
    ]);

    const handleSelectAllSub = e => {

        let Total = document.querySelectorAll("input[name=Check]").length;
        let Checked = document.querySelectorAll("input[name=Check]:checked").length;

        setIsCheckAllSub(!isCheckAllSub);       
        
        setIsCheckSub(listSub.map(li => li.id));
        if (isCheckAllSub) {
            setIsCheckSub([]);
            setIsCheckAll(false);
        } else if(!isCheckAllSub && Total == Checked ) {
            setIsCheckAll(true);
        } else{
            
        }
    };

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheckAllSub(!isCheckAllSub);
        
        setIsCheck(list.map(li => li.id));
        setIsCheckSub(listSub.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
            setIsCheckSub([]);
            setIsCheckAllSub(false);
            setisCheckEssential(false);
        } else{
            setIsCheckAllSub(true);
            setisCheckEssential(true);
        }
    };

    const handleClickSub = e => {
        const { id, checked } = e.target;
        setIsCheckSub([...isCheckSub, id]);

        let c1 = document.getElementById('c1');
        let c2 = document.getElementById('c2');
        let c3 = document.getElementById('c3');
        let c4 = document.getElementById('c4');
        // let c5 = document.getElementById('c5');
        let c5_1 = document.getElementById('c5-1');
        let c5_2 = document.getElementById('c5-2');

        if (!checked) {
            setIsCheckSub(isCheckSub.filter(item => item !== id));
        }

        if (c1.checked && c2.checked && c3.checked && c4.checked && c5_1.checked && c5_2.checked){
            setIsCheckAll(true);
            setIsCheckAllSub(true);
        } else if (c5_1.checked && c5_2.checked){
            setIsCheckAllSub(true);
        } else {           
            setIsCheckAll(false);
            setIsCheckAllSub(false);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);

        let c1 = document.getElementById('c1');
        let c2 = document.getElementById('c2');
        let c3 = document.getElementById('c3');
        let c4 = document.getElementById('c4');
        // let c5 = document.getElementById('c5');
        let c5_1 = document.getElementById('c5-1');
        let c5_2 = document.getElementById('c5-2');

        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }

        if (c1.checked && c2.checked && c3.checked && c4.checked && c5_1.checked && c5_2.checked){
            setisCheckEssential(true);
            setIsCheckAll(true);
        } else {           
            setisCheckEssential(false);
            setIsCheckAll(false);
        }
    };

    function RequiredCheck() {
        let Total = document.querySelectorAll("input:required").length;
        let Checked = document.querySelectorAll("input:required:checked").length;

        if (Total !== Checked) {
            document.querySelector(".AlertBox").classList.add("active");

        } else {
            document.querySelector(".AlertBox").classList.remove("active");
            signup();
        }
    }

    const signup = async () => {
        let eventAlarmTypes = [];
        let marketingConsent = false;

        if(document.getElementById("c5").checked){
            marketingConsent = true;
        }else{
            marketingConsent = false;
        }
        if(document.getElementById("c5-1").checked){
            eventAlarmTypes.push('APP_PUSH');
        }
        if(document.getElementById("c5-2").checked){
            eventAlarmTypes.push('SMS');
        }

        let obj = {
            "eventAlarmTypes": eventAlarmTypes,
            "marketingConsent": marketingConsent,
            "oauthAccessToken": tmpAT.current,
            "oauthType": "KAKAO"
        }
        // console.log(obj);
        const response = await axios.post(
            getApiUrl('v1/oauth/signup' ),
            obj,
            axios.defaults.headers.common['ContentType'] = "application/json;",
        ).then(
            function(response){
                if(response.status === 200){
                    // console.log(response.data);
                    localStorage.setItem('accessToken',response.data.loginToken.accessToken);
                    localStorage.setItem('defaultStore',response.data.defaultDomain);
                    localStorage.setItem('userName',response.data.name);
                    navigate('/storemain');
                }
            }
        ).catch(function(error){
            console.log('error');
        })
    }

    const [user, setUser] = useState();
    const tmpErr = useRef('');

    const KakaoLogin = async () => {
        let obj = {
            "oauthAccessToken": window.location.href.split('?code=')[1],
            "oauthType": "KAKAO"
        }
        // console.log(obj);
        const response = await axios.post(
            getApiUrl('v1/oauth/login' ),
            obj,
            axios.defaults.headers.common['ContentType'] = "application/json;",
        ).then(
            function(response){
                // console.log('----------------------------------');
                // console.log(response);
                if(response.status === 200){
                    // console.log(response)
                    if(response.data.signedUp === true){
                        localStorage.setItem('accessToken',response.data.loginToken.accessToken);
                        localStorage.setItem('defaultStore',response.data.defaultDomain);
                        // console.log(localStorage.getItem('accessToken'));
                        navigate("/storemain");
                    }else{

                        tmpAT.current=response.data.oauthAccessToken;

                        setUser(response.data.info);
                        // console.log(tmpAT.current);
                    }
                    setLoading(false);
                }else{
                    setLoading(false);
                }
            }
        ).catch(function(error){
            console.log(error);
            // alert('fff');
            setLoading(false);
        })
    }

    useEffect (() => {
        KakaoLogin();
    }, []);


    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

    return (
        <>
            <div className='demo'>
                <div className="TRegisterPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">회원가입</p>
                            <button type="button" className="btn-clear btn-close" onClick={() => navigate(-1)}>
                                <img className="img-fluid" alt="뒤로가기"
                                    src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                            </button>
                        </nav>

                        <div className="SignUpBox pb-4-5">
                            <div className="SignUp-title text-center">
                                <div className="Inner">
                                    <p className="tit mb-1 d-flex justify-content-center">
                                        반가워요
                                        <img className="img-fluid ms-1"
                                            src="https://www.flexdaycdn.net/public/images/ticket/emoji_smile.png" alt="" />
                                    </p>
                                    <p className="sub mb-0">
                                        약관에 동의해주시면 회원가입이 진행됩니다.
                                        {tmpErr.current}
                                    </p>
                                </div>
                            </div>

                            <div className="SignUp-Contents Inner">
                                <div className="accordion ver2">
                                    <div className="accordion-item">
                                        <p className="accordion-headerd d-flex align-items-center">
                                            <input className="form-check-input CheckInput1 me-0 AllCheck" name="hobbyAll" id='all' type="checkbox" onChange={handleSelectAll} checked={isCheckAll}/>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                플렉스데이 이용약관 전체동의
                                            </button>
                                        </p>
                                        <div id="collapseOne" className="accordion-collapse show" aria-labelledby="headingOne"
                                            data-bs-parent="#accordionExample">
                                            <div className="accordion-body Input-placeholderbox">
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c1"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c1')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c1">
                                                        (필수) 서비스 이용약관
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Serviceterms />
                                                    </a>
                                                </div>
                                                {/* <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c2"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c2')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c2">
                                                        (필수) 개인정보 수집 및 이용 동의서
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Userterms />
                                                    </a>
                                                </div> */}
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input CheckInput2" name="Check" id="c3"
                                                        type="checkbox" required onChange={handleClick} checked={isCheck.includes('c3')} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c3">
                                                        (필수) 개인정보 처리 방침
                                                    </label>
                                                    <a className="CheckInfoBtn" href="#">
                                                        <Userterms2 />
                                                    </a>
                                                </div>
                                                <div className="form-check d-flex align-items-center p-0 mb-2 Mob320">
                                                    <input className="form-check-input AllCheckSub CheckInput2" name="Check" id="c5"
                                                        type="checkbox"  onChange={handleSelectAllSub} checked={isCheckAllSub} />
                                                    <label className="form-check-label CheckLabel" htmlFor="c5">
                                                        (선택) 마케팅 정보 수신 동의
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Infobox Inner">
                                <p className="SignUp-checkbox-desc">
                                    플렉스데이에서 제공하는 각종 혜택 및 이벤트, <br className="Mob320" />
                                    사은품에 대한 안내를 받을 수 있습니다.
                                </p>
                                <div className="form-check-group g-0 row flex-wrap">
                                    <div className="form-check col-4 d-flex align-items-center p-0">
                                        <input className="form-check-input CheckInput3" id="c5-1" type="checkbox" name="Check2" onChange={handleClickSub} checked={isCheckSub.includes('c5-1')}/>
                                        <label className="form-check-label CheckLabel" htmlFor="c5-1">
                                            앱푸시
                                        </label>
                                    </div>
                                    <div className="form-check col-4 d-flex align-items-center p-0">
                                        <input className="form-check-input CheckInput3" id="c5-2" type="checkbox" name="Check2" onChange={handleClickSub} checked={isCheckSub.includes('c5-2')}/>
                                        <label className="form-check-label CheckLabel" htmlFor="c5-2">
                                            문자
                                        </label>
                                    </div>
                                </div>
                                <br/>
                                <div className="AppleRegisterBox bottom g-0">
                                    <div className="InputboxWrap">
                                        <p className="tit">
                                            가입하시는 분의 정보를 확인해 주세요.
                                        </p>
                                        <p className="tit">
                                            (*이름, 연락처, 이메일은 필수 정보 입니다.)
                                        </p>
                                        <div className="AppleRegister-Inputbox mb-2">
                                            <input type="text" className="form-control InputType" placeholder="이름(실명을 입력해주세요.)" value={user && user.name} />
                                        </div>
                                        <div className="AppleRegister-Inputbox mb-2">
                                            <input type="text" className="form-control InputType" placeholder="연락처(’-’를 제외하고 입력해주세요.)" value={user && user.phoneNumber}/>
                                        </div>
                                        <div className="AppleRegister-Inputbox mb-2">
                                            <input type="text" className="form-control InputType" placeholder="이메일" value={user && user.email} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="SignUp-Confirm Inner pt-4-5">
                                <button type="button" className="btn btn-default" onClick={RequiredCheck}>회원가입</button>
                            </div>
                            <div className="AlertBox Inner mt-3">
                                필수 약관에 동의해주세요.
                            </div>
                        </div>


                    </div>
                </div>                
            </div>
        </>
    )
}