import { Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';

import "./NonUserTicket.css";
import { getApiUrl } from '../../function/common';

import { useSearchParams } from 'react-router-dom';

// const clientKey = 'live_ck_LBa5PzR0ArnR70eLN0krvmYnNeDM'
const clientKey = process.env.REACT_APP_STORE_KEY


export default function NonUserTicket(){

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    // 모달
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [step , setStep] = useState('STEP1');

    const { number } = useParams();
    const [qr, setQR] = useState();

    function getQRCode() {
        return new Promise( async function(resolve, reject) {
            let tel = document.getElementById('Tel').value;
            
            var obj = {
                phoneNumber : tel
            }
            
            const response = await axios.post( 
                getApiUrl('v1/coupons/'+ number + '/non-user'),
                obj,
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken'),
                axios.defaults.headers.common['ContentType'] = "application/json; charset=utf-8"
            ).then(
                function(response){
                    if(response.status === 200){
                        setQR(response.data);
                        if(show === true){
                            handleClose();
                        }
                        setStep('STEP2');
                    }
                }
            ).catch(function(error){
                handleShow();
                console.log('error');
            })
        });
    };
   

    useEffect( () => {
        //데이터 로딩 완료
        setLoading(false);

    }, []);

    if (loading) return <div className='container' style={{position:'absolute',left:'50%',top:'50%', marginLeft:'-18px'}}><Spinner animation="border" /></div>;

    if ( step === 'STEP1'){
    
        return (
            <>
            <div className='demo'>

                <div className="TNonMembersPage">
                    <div className="container-fluid g-0">

                        <nav className="sub-navbar">
                            <p className="navbar-title">
                                식권
                            </p>                    
                        </nav>

                        <div className="NonMemberBox Inner">
                            <div className="NonMemberBox-title text-center">

                                <img className="img-fluid tit-img" alt=""
                                    src="https://www.flexdaycdn.net/public/images/ticket/non_member_img.png" />

                                <p className="tit mb-2">
                                    본인 확인을 위해 <br />
                                    <span className="fw-bold">연락처 끝 번호 4자리</span>를 입력해주세요.
                                </p>
                                <p className="sub">
                                    예시) 010-****-<span className="color">0000</span>
                                </p>
                            </div>

                            <div className="NonMemberBox-Input mb-4">
                                <input type="number" pattern="\d*" className="InputType form-control mb-3 px-3"
                                    placeholder="연락처 끝 번호 4자리를 입력해주세요." id='Tel' />
                                <button type="button" className="btn btn-default" onClick={ ()=>{getQRCode()} }>
                                    입력
                                </button>
                            </div>
                        </div>

                    </div>

                    {/* 비회원 식권 확인Modal */}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        keyboard={false}
                        size="sm"
                        centered
                        className="demoModal modal-prdAdd text-center"
                        >
                        <Modal.Header>
                            <Modal.Title>
                                연락처 끝 번호 4자리가 <br />
                                일치하지 않습니다.
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            번호를 확인하신 후 다시 입력해주세요.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="modal-primary" className='btn-clear w-100' onClick={handleClose}>
                                확인
                            </Button>
                        </Modal.Footer>
                    </Modal>   

                </div>

            </div>
            </>
        )

    }else if(step === "STEP2"){
        return (
            <>
                <div className='demo'>
        
                    <div className="TUseQRPage">
                        <div className="container-fluid g-0">
        
                            <nav className="sub-navbar">
                                <p className="navbar-title">QR 사용하기</p>
                                <button type="button" className="btn-clear btn-close" onClick={() => {navigate('/')}}>
                                    <img className="img-fluid" alt="뒤로가기"
                                        src="https://www.flexdaycdn.net/public/images/ticket/close5.png" />
                                        
                                </button>
                            </nav>
        
                            <div className="QRBox px-4-5 pb-4">
                                <div className="QRBox-inner">
                                    <div className="infobox px-4-5">
                                        <div className="infoitem-date text-end">
                                            유효기간 {qr && qr.expireDate}
                                        </div>
                                        <div className="infoitem">
                                            <div className="tit">
                                                상품
                                            </div>
                                            <div className="desc">
                                                {qr && qr.productName}
                                            </div>
                                        </div>
                                        <div className="infoitem">
                                            <div className="tit">
                                                매장
                                            </div>
                                            <div className="desc">
                                                {qr && qr.storeName}
                                            </div>
                                        </div>
                                    </div>
        
                                    <div className="imgbox text-center">
                                        <img className="img-fluid m-auto qr-img"
                                            src={process.env.REACT_APP_API+"common/qrcode?data="+(qr && qr.qrcode)+"&size=380"} />
                                    </div>
        
                                    <div className="QRInfotxt text-center">
                                        구매한 날짜에 사용하지 못하셨다면 <br />
                                        유효기간 내 같은 금액의 식단으로 <br className="mob" />
                                        사용이 가능합니다.
                                    </div>  
                                    
                                    <div className="QRBox-bg">
                                        <img className="img-fluid w-100"
                                            src="https://www.flexdaycdn.net/public/images/ticket/QR_use_bg4.png" />
                                    </div>
        
                                </div>                    
                            </div>
                        </div>
                    </div>
        
                </div>
        
            </>
        )
    }
}