import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import {
  AlertError,
  getApiUrl,
  getWeekDay,
  setPhone,
} from "../../function/common";

import Modal from "react-bootstrap/Modal";
import Header from "./components/Header";
import "./GiftList.css";
import { Spinner } from "react-bootstrap";

export default function GiftList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // 모달
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const tmpWhere = useRef("3");
  function SetWhere(val) {
    tmpWhere.current = val;
    if (val !== "ALL") {
      document.getElementById("FilterTxt").textContent = "최근 " + val + "개월";
      document.getElementById("InfoTxt").textContent =
        "최근 " + val + "개월간 선물 받으신 내역입니다.";

      document.getElementById("FilterTxt2").textContent =
        "최근 " + val + "개월";
      document.getElementById("InfoTxt2").textContent =
        "최근 " + val + "개월간 선물 받으신 내역입니다.";
    } else {
      document.getElementById("FilterTxt").textContent = "전체";
      document.getElementById("InfoTxt").textContent =
        "선물 받으신 전체 내역입니다.";

      document.getElementById("FilterTxt2").textContent = "전체";
      document.getElementById("InfoTxt2").textContent =
        "선물 받으신 전체 내역입니다.";
    }
    getGiftList("SEND");
    getGiftList("RECEIVE");
  }

  const [sendList, setSendList] = useState();
  const [reciveList, setReciveList] = useState();
  function getGiftList(mode) {
    return new Promise(async function (resolve, reject) {
      // console.log(tmpWhere.current);
      let dateRange = "LAST_3MONTH";
      if (tmpWhere.current === "3") {
        dateRange = "LAST_3MONTH";
      } else if (tmpWhere.current === "6") {
        dateRange = "LAST_6MONTH";
      } else if (tmpWhere.current === "9") {
        dateRange = "LAST_9MONTH";
      } else if (tmpWhere.current === "ALL") {
        dateRange = "ALL";
      }
      var obj = {
        dateRange: dateRange,
        giftType: mode,
      };

      if (
        localStorage.getItem("defaultStore") !== "none" &&
        localStorage.getItem("defaultStore") !== undefined &&
        localStorage.getItem("defaultStore") !== null &&
        localStorage.getItem("defaultStore") !== "null" &&
        localStorage.getItem("defaultStore") !== ""
      ) {
        obj = {
          dateRange: dateRange,
          giftType: mode,
          storeDomain: localStorage.getItem("defaultStore"),
        };
      }

      const response = await axios
        .get(
          getApiUrl("v1/gift"),
          { params: obj },
          (axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken")),
          (axios.defaults.headers.common["ContentType"] =
            "application/json; charset=utf-8")
        )
        .then(function (response) {
          if (response.status === 200) {
            if (mode === "SEND") {
              setSendList(response.data);
            } else {
              setReciveList(response.data);
            }
          }
          setLoading(false);
        })
        .catch(function (error) {
          AlertError(error);
        });
    });
  }

  useEffect(() => {
    getGiftList("SEND");
    getGiftList("RECEIVE");

    // setLoading(false);
  }, []);

  if (loading)
    return (
      <div
        className="container"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          marginLeft: "-18px",
        }}
      >
        <Spinner animation="border" />
      </div>
    );

  return (
    <>
      <div className="demo">
        <div className="TGiftListPage">
          <div className="container-fluid g-0">
            <nav className="sub-navbar">
              <p className="navbar-title">선물 내역</p>
              <button
                type="button"
                className="btn-clear btn-back"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  className="img-fluid"
                  alt="뒤로가기"
                  src="https://www.flexdaycdn.net/public/images/ticket/back-b.png"
                />
              </button>
              <Header />
            </nav>

            <div className="PayCateTab">
              <div
                className="nav nav-pills justify-content-around"
                id="pills-tab"
                role="tablist"
              >
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link active PayCateBtn"
                    id="pills-content1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content1"
                    type="button"
                    role="tab"
                  >
                    <span>보낸 선물</span>
                  </button>
                </div>
                <div className="nav-item" role="presentation">
                  <button
                    className="nav-link PayCateBtn"
                    id="pills-content2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-content2"
                    type="button"
                    role="tab"
                  >
                    <span>받은 선물</span>
                  </button>
                </div>
              </div>
              <div className="tab-content pb-4" id="pills-tabContent">
                <div
                  className="tab-pane show active"
                  id="pills-content1"
                  role="tabpanel"
                >
                  <div className="GiftFilter px-4-5">
                    <div className="info-txt" id="InfoTxt">
                      최근 3개월간 선물하신 내역입니다.
                    </div>
                    <div className="sort" onClick={handleShow}>
                      <span className="filter-txt" id="FilterTxt">
                        최근 3개월
                      </span>
                      <img
                        className="img-fluid arrow"
                        src="https://www.flexdaycdn.net/public/images/ticket/arrow-down.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="GiftList send px-4-5">
                    {sendList &&
                      sendList.data.map((row) => (
                        <>
                          <div className="GiftItem">
                            <div className="date">
                              <span className="txt">보낸 일시</span>
                              {getWeekDay(row.createdAt)}
                            </div>
                            <div className="box">
                              <div className="infobox">
                                <div className="to">
                                  <span className="txt">To.</span>
                                  {row.toName} ({setPhone(row.toPhoneNumber)})
                                </div>
                                <div className="prd-name">
                                  {row.productName}
                                </div>
                                {/* ver3 수정 */}
                                <div class="store-name">{row.storeName}</div>
                              </div>
                              <div className="state">
                                {row.couponStatus.description}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
                <div className="tab-pane" id="pills-content2" role="tabpanel">
                  <div className="GiftFilter px-4-5">
                    <div className="info-txt" id="InfoTxt2">
                      최근 3개월간 선물 받으신 내역입니다.
                    </div>
                    <div className="sort" onClick={handleShow}>
                      <span className="filter-txt" id="FilterTxt2">
                        최근 3개월
                      </span>
                      <img
                        className="img-fluid arrow"
                        src="https://www.flexdaycdn.net/public/images/ticket/arrow-down.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="GiftList receive px-4-5">
                    {reciveList &&
                      reciveList.data.map((row) => (
                        <>
                          <div className="GiftItem">
                            <div className="date">
                              <span className="txt">받은 일시</span>
                              {getWeekDay(row.createdAt)}
                            </div>
                            <div className="box">
                              <div className="infobox">
                                <div className="to">
                                  <span className="txt">From.</span>
                                  {row.fromName}
                                </div>
                                <div className="prd-name">
                                  {row.productName}
                                </div>
                                {/* ver3 수정 */}
                                <div class="store-name">{row.storeName}</div>
                              </div>
                              {/* ver3 수정 */}
                              {row.couponStatus.code === 1 ? (
                                row.storeType === "SMART_ORDER" ? (
                                  <div class="btnbox">
                                    <Link to={"/twallet#gift"}>
                                      <button
                                        type="type"
                                        class="btn btn-default text-white"
                                      >
                                        주문하기
                                      </button>
                                    </Link>
                                  </div>
                                ) : (
                                  <div class="btnbox">
                                    <Link to={"/qr/" + row.couponQrcode}>
                                      <button
                                        type="type"
                                        class="btn btn-default text-white"
                                      >
                                        사용하기
                                      </button>
                                    </Link>
                                  </div>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 결제내역 Modal */}
          <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            size="sm"
            className="demoModal TGiftList-modal"
            dialogClassName="modal-prdfilter"
          >
            <div className="modal-bar py-3">
              <div className="bar"></div>
            </div>
            <Modal.Header>
              <Modal.Title className="fw-bold">조회기간 선택</Modal.Title>
              <button
                type="button"
                className="btn btn-clear"
                onClick={handleClose}
              >
                <img
                  className="img-fluid"
                  alt=""
                  src="https://www.flexdaycdn.net/public/images/ticket/close5.png"
                />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="prd-sortbox">
                <div className="form-sort">
                  {tmpWhere.current === "3" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-1"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("3");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-1"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("3");
                      }}
                    />
                  )}
                  <label
                    className="form-radio-label"
                    htmlFor="survey-radio-1"
                    onClick={handleClose}
                  >
                    최근 3개월
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "6" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-2"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("6");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-2"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("6");
                      }}
                    />
                  )}
                  <label
                    className="form-radio-label"
                    htmlFor="survey-radio-2"
                    onClick={handleClose}
                  >
                    최근 6개월
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "9" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-3"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("9");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-3"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("9");
                      }}
                    />
                  )}
                  <label
                    className="form-radio-label"
                    htmlFor="survey-radio-3"
                    onClick={handleClose}
                  >
                    최근 9개월
                  </label>
                </div>
                <div className="form-sort">
                  {tmpWhere.current === "ALL" ? (
                    <input
                      className="form-radio-input"
                      id="survey-radio-4"
                      type="radio"
                      name="flexRadioDefault"
                      defaultChecked
                      onClick={() => {
                        SetWhere("ALL");
                      }}
                    />
                  ) : (
                    <input
                      className="form-radio-input"
                      id="survey-radio-4"
                      type="radio"
                      name="flexRadioDefault"
                      onClick={() => {
                        SetWhere("ALL");
                      }}
                    />
                  )}
                  <label
                    className="form-radio-label"
                    htmlFor="survey-radio-4"
                    onClick={handleClose}
                  >
                    전체
                  </label>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}
